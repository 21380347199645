import React from 'react'
import "../../views/Dashboard/Dashboard.css"
import Service from '../../Service/Service'
import moment from 'moment';
import axios from 'axios';
import { NOTIFICATION } from '../../actions';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as IconYes } from '../../Assets/check_green.svg';
import { ReactComponent as IconNo } from '../../Assets/cross_red.svg';


class ReasonPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            categoryType: '',
            externamProps: {},
            eventName: {
                data: '',
                required: true,
                errMsg: 'Required'
            },
            reject_reason: {
                data: '',
                required: true,
                errMsg: 'Required'
            },
            description: {
                data: '',
                required: true,
                errMsg: 'Required'
            },
            formError: {},
            active_listing_data: [],
            //event_count:this.props.needarg.acount,
            processor_status: 0,
            project_id: 0,
            reason_popup: false,
            toggle_question: false,
            reason_text: ''
        }
    }



    handleChange1 = name => e => {
        //console.log("handle change menthod ------@add-form compne");
        let { name, value } = e.target
        //console.log(name,value)
        let { formError } = this.state;
        if (formError[name])
            delete formError[name]
        if (formError['error'])
            delete formError['error']
        let prestate = this.state[name]
        prestate.data = value
        //    if(name==='category_type'){
        //        switch(value.toLowerCase()){
        //            case 'beef':
        //              prestate.data = 1;
        //              break;
        //            case 'goat':
        //              prestate.data = 2;
        //              break;
        //            case 'lamb':
        //              prestate.data = 3;
        //              break;
        //            case 'pork':
        //              prestate.data = 4;
        //              break;
        //            default:
        //              prestate.data = 5;
        //          }
        //    }
        this.setState({
            [name]: prestate
        }, () => console.log("this.statethis.state"))
    }

    handleValidation = () => {
        let { formError } = this.state
        Object.keys(this.state).map(key => {
            if (this.state[key] && this.state[key].required && this.state[key].data === '')
                formError[key] = this.state[key].errMsg && this.state[key].errMsg !== '' ? this.state[key].errMsg : 'Required'
        })
        this.setState({
            formError: formError
        })
        return formError
    }

    reject_reason = () => e => {
        console.log("easeon ");
        this.setState({
            reason_text: true
        })

    }

    handleSubmit = (e) => {
        e.preventDefault();
        //self = this;

        console.log("handle subnmit called", this.props);
        this.props.closeModal(false);
        this.props.closeM(this.props.current_popup_data, this.state.reject_reason.data);
        console.log("after close modal", this.props.event_cid);
        //this.props.updateStatus(2,this.props.event_cid);
        console.log("done");
        this.setState({
            reason_text: this.state.reject_reason.data
        })


    }






    handleChange = e => {
        let { name, value } = e.target;
        console.log(name, value)
        let { extendedProps, handleEventClick } = this.props;
        let eprop = {
            event: {
                extendedProps: { ...extendedProps.event.extendedProps }
            }
        }
        eprop.event.extendedProps.category = value
        this.setState({
            [name]: value
        }, () => handleEventClick(eprop))
    }

    clr_state = () => {
        console.log("working ..");
        let refresh_status = false
        if (this.state.project_id != 0) {
            refresh_status = true
        }
        this.setState({
            project_id: 0
        })
        console.log("this state processor data ========", this.state.project_id);
        this.props.assign_refetchEvents(refresh_status);
        //this.props.assign_events();
        this.props.close(false);
    }


    componentDidMount() {
        //let self = this;
        console.log("componetn deis mount");
        console.log("token @ submit button in componen ---", this.props);
        //console.log("props befor set state -----",this.props.needarg.acount);


    }
    render() {
        let {
            title,
            close,
            isDateClicked,
            role,
            availablecategory,
            eventClickedCAtegory
        } = this.props;

        let { formError } = this.state
        console.log("render prop s on reason popou --", this.props)
        return (
            <div className="G-zr">
                <div className="Addmodal zindex" id="myModal">
                    <div className="modal-dialog" style={{ width: "100%", "max-width": "360px" }}>
                        <div className="modal-content trck-lfpop">

                            <div>
                                {this.state.toggle_question ?
                                    <form onSubmit={this.handleSubmit} style={{ padding: "20px 20px 0" }}>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label htmlFor="EventName">Please give some reason for Reject this order:</label><br></br>
                                                    <textarea className="form-control" cols={60} rows={5} name="reject_reason" value={this.state.reject_reason.data} onChange={this.handleChange1("reject_reason")} /><br></br>
                                                    {/* <input type="text" name = "eventName1" value={cdata1} onChange={this.handleChange1} className="form-control" id="EventName1"  placeholder="Enter Event Name1"></input> */}

                                                    <span style={{ color: "red" }}>{formError['eventName']}</span>
                                                    <button type="submit" className="btn btn-primary" >Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    :
                                    <div className="popup-warning-container">
                                        <div className=''>
                                            <img src={process.env.REACT_APP_URL + `images/warning.svg`} width="33" height="33" />
                                        </div>
                                        <div className='pl-3' style={{ width: '100%' }}>
                                            <h1 className="popup-title-warning">Are You Sure</h1>
                                            <label className="popup-subtitle-warning">Want to reject this order ?</label>
                                            <br></br>
                                            <div className='popup-btn-container pt-3'>
                                                <IconYes style={{ cursor: 'pointer' }} className="mr-2" onClick={e => this.setState({ toggle_question: true })} />
                                                <IconNo style={{ cursor: 'pointer' }} onClick={this.props.closeModal(false)} />
                                                {/* <button  type="submit" className="btn btn-green" onClick={e=>this.setState({toggle_question:true})}>yes</button>&nbsp;&nbsp;
                                        <button  type="submit" className="btn btn-danger" onClick={this.props.closeModal(false)}>No</button> */}
                                            </div>

                                        </div>
                                    </div>



                                }



                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const { login: { loggedIn }, login: { user } } = state;
    return { loggedIn, user };
}
export default connect(mapStateToProps)(ReasonPopup);