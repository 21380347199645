import React from 'react';

function producerAgreement() {
  return (
    <>
      <h3>GRAZR and Producer Agreement</h3>
      <div className='modalBody'>
        <p>
          This agreement is between online sales facilitator GRAZR, and its
          farmer member, <br />
          <br />
        </p>
        <p>Recitals and definitions</p>
        <ol>
          <li>
            The parties recognize the increased consumer demand for local farm
            products, and challenges of local producers in locating and meeting
            consumer demand.
          </li>
          <li>
            The parties wish to retain their distinct roles, GRAZR as an
            administrative facilitator, and the farm producer as producer and
            seller of beef cattle.
          </li>
          <li>
            Farm producers mean the signatory to this agreement who raises beef
            cattle and wishes to utilize GRAZR&rsquo;s online platform, website
            and marketing to sell its live animal directly to live consumers.
          </li>
          <li>
            GRAZR is an online facilitator of sales. It does not at any time
            take ownership of all or part of the live animals listed on its
            website for sale by the farm producer to an end consumer.
          </li>
        </ol>
        <p>Agreement</p>
        <p>The parties agree as follows:</p>
        <ol>
          <li>
            The farm producer will send identifying information of live beef
            cattle to GRAZR for publication on its website for purchase by
            consumers. The producer shall include a photograph of the animal,
            name and address of the farm, truthful information regarding the
            methods of raising the animal used, a name if applicable, breed, and
            animal Tag ID number.
          </li>
          <li>
            GRAZR shall facilitate sale of entire live animals or shares in a
            live animal through listing on its website, and online
            marketing.&nbsp;
          </li>
          <li>
            GRAZR is merely the facilitator of the sale between the farm
            producer. It shall add a 7.5% service fee to the farm
            producer&rsquo;s price of the animal or animal share for its service
            of listing animals for sale, arranging for custom processors, and
            interfacing with the end consumers. GRAZR will never take physical
            or legal possession or ownership of the farm producer&rsquo;s live
            animals or animal share for sale.&nbsp;
          </li>
          <li>
            The farm producer agrees and understands that if it agrees to sell
            shares of a live animal (for example, sell a live animal to four
            separate owners), that it will list the live animal for sale
            exclusively with GRAZR. If the farm producer lists an entire whole
            animal for sale rather than in shares, it may list outside of the
            GRAZR platform.
          </li>
          <li>
            The parties agree and understand that all sales are direct between
            the farm producer and the end consumer for consumer soley the end
            consumer and their immediate family. GRAZR shall provide the
            platform for payment, and monies for the price of the sold animal or
            animal share shall be forwarded directly to the farm producer. GRAZR
            shall charge the end consumer 7.5% of the farm producer&rsquo;s
            price as an administrative fee.
          </li>
          <li>
            An end consumer shall use the GRAZR website / platform to order
            purchase of an animal or share in an animal. GRAZR will facilitate
            the farm producer&rsquo;s production and delivery of a Bill of Sale
            to the end consumer.&nbsp;
          </li>
          <li>
            The farm producer is responsible for transportation of live
            animal(s) to the custom processor engaged by GRAZR for slaughter and
            processing of the live animal on behalf of the end consumer.
          </li>
          <li>
            GRAZR shall facilitate communication with a licensed custom
            processor. The parties understand that the end consumer shall pick
            up the processed meat directly from the custom processor.&nbsp;
          </li>
          <li>
            The farm producer shall feed cattle for sale a predominately
            pasture, hay or forage-based diet depending on climate conditions
            and availability of needs. The farm producer agrees to use humane
            animal practices, and any antibiotic use shall be for treatment of
            illness only.
          </li>
          <li>
            GRAZR shall ensure that end consumers agree and understand that
            purchase is for their use and that of their immediate household,
            meat obtained through the sale of live animals shall not be
            available for resale.
          </li>
          <li>
            GRAZR is not liable for the quality of meat slaughtered and process
            from the farm producer&rsquo;s animals listed on the GRAZR platform.
            Farm producer agrees to indemnify and hold harmless GRAZR for any
            liability, loss, damage, expense or penalties that are incurred
            because of the farm producer&rsquo;s production of an adulterated or
            misbranded animal or otherwise responsible for illness or poor meat
            quality. This includes any and all demands, claims, suite, actions,
            causes of of action or agency investigation or findings.
          </li>
          <li>
            All disputes claims and questions between the parties regarding the
            rights and obligations of the parties under this Agreement are
            subject to arbitration. Either party may serve on the other a
            written demand for arbitration within one hundred eighty (180) days
            after the dispute first arises, in accordance with the rules and
            using the services of the American Arbitration Association. Each
            party shall pay its own costs in connection with the arbitration,
            and costs of the arbitrators shall be paid in equal amounts by the
            parties.
          </li>
          <li>
            Farm producers shall join and maintain a good membership standing
            with the Farm-to-Consumer Legal Defense Fund as farmer-members,
            information found at{' '}
            <a href='https://www.farmtoconsumer.org/' target='_blank'>
              Farm-to-Consumer Legal Defense Fund - Protecting, defending, and
              broadening the rights and viability of independent farmers,
              artisanal food producers, and their consumers.
            </a>
          </li>
        </ol>
        <p>
          <br />
          Miscellaneous.
        </p>
        <ol>
          <li>
            Effective Date: This Agreement shall be effective upon execution and
            shall continue in effect until terminated by either GRAZR or farm
            producer upon 30 days&rsquo; written notice.
          </li>
          <li>
            Construction. The masculine of any word used in this Agreement shall
            include the feminine and neuter gender; the singular, the plural;
            and vice versa.
          </li>
          <li>
            Governing Law. This Agreement is being executed and delivered in the
            State of Tennessee and shall be construed in accordance with and
            governed by the laws of such state.
          </li>
          <li>
            Severability. If, for any reason, any provision or provisions of
            this Agreement are determined to be invalid and contrary to any
            existing or future law, such invalidity shall not impair the
            operation or affect those portions of this Agreement which are
            valid.
          </li>
          <li>
            Unforeseeable Circumstances. Notwithstanding anything to the
            contrary in this Agreement, neither Agister nor Owner shall be
            responsible for any delay or failure of its performance under this
            Agreement if the delay or failure is caused by any matter beyond the
            control of either party, including, but not limited to, government
            regulations, public emergency or necessity including public health
            necessity; legal restrictions; labor disputes and actions related
            thereto; riot, war, or insurrection; and windstorms, rainstorms,
            snowstorms, floods or other acts of God.
          </li>
          <li>
            Entire Agreement. This Agreement constitutes the entire contract
            between the parties and may not be modified or amended except in
            writing signed by both parties.
          </li>
          <li>
            Assignment. This Agreement and each of its provisions shall inure to
            the benefit of and be binding upon the parties, their successors and
            permitted assigns.
          </li>
        </ol>
        <p>
          The parties agree to the above terms and have executed this Agreement
          on the dates shown below to be effective on the date or dates
          described above.
        </p>
      </div>
    </>
  );
}

export default producerAgreement;
