import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class PushNotification extends Component {
    snackBarClose = () => {
        this.props.snackBarClose()
    } 
    render(){
        let {msgColor,msgLink,msg} = this.props;
        
        if(msgColor){
            return (
                <div id="snackbar" style={msgColor ? {backgroundColor:msgColor} : {}}>
                    { msgLink ?  <Link to={msgLink}> {msg} </Link> : msg }
                    <i onClick={this.snackBarClose} style={{background:'#DD7A3A', width:'12px', height:'12px', fontSize:'8px', display:'flex', justifyContent:'center', alignItems:'center', alignContent:'center'}} className="fa fa-times snackbarClose "></i>
                </div>
            )
        }else{
            return (
                <div id="snackbar" style={msgColor ? {backgroundColor:msgColor} : {}}>
                    { msgLink ?  <Link to={msgLink}> {msg} </Link> : msg }
                    <i onClick={this.snackBarClose} style={{background:'#6EC17E', width:'12px', height:'12px', fontSize:'8px', display:'flex', justifyContent:'center', alignItems:'center', alignContent:'center'}} className="fa fa-times snackbarClose "></i>
                </div>
            )
        }
        
    }
}

export default PushNotification;