import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import "./Login.css";
// import "../Register/Register.css";
import axios from "axios";
import Cookies from "universal-cookie";
import { LOGIN, LOGIN_FAILURE, LOGOUT } from "../../actions";
import LoginSlider from "../../components/LoginSlider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';



const cookies = new Cookies();
class Login extends Component {
  state = {
    username: {
      data: "",
      required: true,
      errorMsg: "Required",
    },
    password: {
      data: "",
      required: true,
      errorMsg: "Required",
    },
    rememberPassword: false,
    error: "",
    formError: {},
    checked: false,
    loader: false,
  };
  handelChecked() {
    if (this.state.rememberPassword) {
      let accountInfo =
        this.state.username.data + "&" + this.state.password.data;

      let Days = 3;
      let exp = new Date();
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
      document.cookie =
        "accountInfo" +
        "=" +
        escape(accountInfo) +
        ";expires=" +
        exp.toGMTString();
    } else {
      let exp = new Date();
      exp.setTime(exp.getTime() - 1);
      let accountInfo = document.cookie;
      var cookie_pos = accountInfo.indexOf("accountInfo");

      if (cookie_pos != -1) {
        document.cookie =
          "accountInfo" + "=" + " " + ";expires=" + exp.toGMTString();
      }

      this.state.name.data = "";
      this.state.password.data = "";
    }
  }

  //判断cookie中是否有账号信息，有就可以进行预填写，没有则直接返回
  loadAccountInfo = () => {
    //读取cookie
    let arr,
      reg = new RegExp("(^| )" + "accountInfo" + "=([^;]*)(;|$)");
    let accountInfo = "";
    if ((arr = document.cookie.match(reg))) {
      accountInfo = unescape(arr[2]);
    } else {
      accountInfo = null;
    }

    if (Boolean(accountInfo) == false) {
      return false;
    } else {
      let userName = "";
      let passWord = "";

      let i = new Array();
      i = accountInfo.split("&");
      userName = i[0];
      passWord = i[1];

      this.state.username.data = userName;
      this.state.password.data = passWord;
      this.setState({
        rememberPassword: true,
      });
    }
  };

  componentDidMount() {
    let check = localStorage.getItem("checked") === "true";
    let user = localStorage.getItem("username");
    let pass = localStorage.getItem("password");
    if (user != "" && user != undefined && user != null) {
      this.state.username.data = user;
      this.state.password.data = pass;
      this.state.checked = check;
    }
    this.setState({});
    //   let userDatainCookie = cookies.get('userdata')
    //   if(userDatainCookie){
    //   if(Object.keys(userDatainCookie).length > 0){
    //       this.state.username.data = userDatainCookie.username
    //       this.state.password.data = userDatainCookie.password
    //       this.setState({
    //           username : this.state.username , password : this.state.password , checked:true
    //       });

    //   }
    // }
    //   localStorage.removeItem('user');
    //   this.props.dispatch(LOGOUT({}))
  }

  handleValidation() {
    let self = this;
    Object.keys(this.state).map(function (key) {
      if (self.state[key].required && self.state[key].data === "") {
        self.state.formError[key] = self.state[key].errorMsg
          ? self.state[key].errorMsg
          : "Required!";
      }
      self.setState(
        {
          formError: self.state.formError,
        },
        () => {
          console.log("qwerty========");
        }
      );
    });
  }

  formSubmit(e) {
    e.preventDefault();
    //console.log(cookies.get('userdata'));
    let self = this;
    self.setState({
      loader: true,
    });
    this.handleValidation();
    let payload = {};
    if (
      Object.keys(self.state.formError).length > 0 ||
      Object.keys(self.state.formError) === "undefined"
    ) {
      self.setState({
        loader: false,
      });
      return false;
    }
    Object.keys(self.state).map(function (key) {
      payload[key] = self.state[key].data;
    });
    //console.log("url is"+ process.env.REACT_APP_BASE_URL)
    let url = process.env.REACT_APP_BASE_URL;
    console.log("url is" + url);
    axios
      .post(url + "auth/login", payload)
      .then(function (response) {
        console.log("res " + response.data.status);
        if (response.data.status == "no") {
          self.setState({
            error: response.data.message,
            loader: false,
          });
          self.props.dispatch(
            LOGIN_FAILURE({
              error: response.data.message,
            })
          );
          return false;
        }
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // window.fcWidget.user.setFirstName(response.data.username);
        // window.fcWidget.user.setEmail(response.data.userdata.email);
        let userdata =
          response && response.data && response.data.data
            ? JSON.parse(atob(response.data.data.split(".")[1]))
            : "";
        userdata.user_token = response.data.data;
        if (userdata.user_token) {
          self.setState({
            loader: false,
          });
        }
        localStorage.setItem("user", JSON.stringify(userdata));
        if (self.state.checked) {
          localStorage.setItem("checked", self.state.checked);
          localStorage.setItem("username", self.state.username.data);
          localStorage.setItem("password", self.state.password.data);
        } else {
          localStorage.setItem("username", "");
          localStorage.setItem("password", "");
        }
        //localStorage.setItem('user_token', response.data.data);
        self.props.dispatch(LOGIN(userdata));
        switch (userdata.role) {
          case "processor":
            self.props.history.push("/processordashboard");
            break;
          case "admin":
            self.props.history.push("/processordashboard");
            break;
          case "butcher":
            self.props.history.push("/processordashboard");
            break;
          case "manager":
            self.props.history.push("/processordashboard");
            break;

          case "seller":
            self.props.history.push("/sellerdashboard");
            break;

          default:
            self.props.history.push("/buyerdashboard");
            break;
        }
      })
      .catch(function (error) {
        self.setState({
          error: "Try later!",
          loader: false,
        });
        return false;
      });
  }

  handleChange = (name) => (event) => {
    let preState = this.state[name];
    if (this.state.formError[name]) {
      delete this.state.formError[name];
    }
    preState.data = event.target.value;
    this.setState({
      [name]: preState,
      formError: this.state.formError,
      error: "",
    });
  };

  handleClick = (name) => (e) => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  closeToggle = (path) => (event) => {
    const { name } = event.target;
    this.setState(() => {
      this.props.history.push(path);
    });
  };

  render() {
    return (
      <div className="G-zr">
        <div className="row m-auto w-100">
          <div className="col-lg-5 p-0 col-md-6 col-sm-12 order-lg-0 order-md-0 order-sm-1 order-xs-1">
            <div className="form-left">
              <img src="./images/logo-white.png" className="img-fluid mr-2" alt="geo" width="251px"></img>
              <img src="./images/meat-login.png" className="img-fluid mr-2 img-meat" alt="geo" width="450px"></img>
            </div>
          </div>
          <div className="col-lg-7 col-md-6 col-sm-12">
            <div className="form-right">
              <div className="dont-have-account mb-5">
                <span>I have don’t have an account<Link style={{color:'#3C923F'}} className="ml-1" to="/register">Sign Up </Link></span>
              </div>
              <h1 className="login-title pb-3 py-4">Please Login</h1>
              {/* <div className="login-button">
                <a href="#" className="btn-google">
                  <img src="./images/google.svg" className="img-fluid mr-2" alt="geo" width="22" height="22"></img>
                  <span className="text">Sign In with Google Account</span>
                </a>
                <a href="#" className="btn-fb">
                <img src="./images/fb-logo.svg" className="img-fluid mr-2" alt="geo" width="22" height="22"></img>
                  <span className="text">Sign In with Facebook</span>
                </a>
              </div> */}
              {/* <span className="msg-or">Or sign in with login and password</span> */}
              <div className="G-form">
                <form className="form" onSubmit={this.formSubmit.bind(this)}>
                  <div className="form-input_ row">
                    <div className="form-group col-lg-6 mb-4">
                      <label className="G-label">Email address or username</label>
                      <div className="input-login">
                        <img src="./images/user-icon.svg" className="input-login-icon img-fluid mr-2" alt="geo" width="22" height="22"></img>
                        <input 
                          // style={{marginRight:"1em"}}
                          className="input-login-form"
                          type="email"
                          placeholder="Type Here"
                          name="username"
                          value={this.state.username.data}
                          onChange={this.handleChange("username")}
                          autoFocus
                        />
                      </div> 
                      <span style={{ color: "red" }}>
                        {this.state.formError["username"]}
                      </span>
                    </div>
                    <div className="form-group col-lg-6 mb-4">
                      <label className="G-label">Password</label>
                      <div className="input-login">
                        <img src="./images/password-icon.svg" className="input-login-icon img-fluid mr-2" alt="geo" width="22" height="22"></img>
                        <input 
                          // style={{marginRight:"1em"}}
                          className="input-login-form"
                          type="password"
                          placeholder="Enter your password"
                          name="password"
                          value={this.state.password.data}
                          onChange={this.handleChange("password")}
                        />
                      </div> 
                      <span style={{ color: "red" }}>
                        {this.state.formError["password"]}
                      </span>
                    </div>
                  </div>
                  
                  <div className="form-group mb-4">
                    <label className="con-ner">
                      Remember me
                      <input
                        type="checkbox"
                        name="remember"
                        onChange={this.handleClick("remember")}
                        checked={this.state.checked}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  {this.state.error ? (
                    <div className="alert alert-danger" role="alert">
                      {this.state.error}
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.loader ? (
                    <div className="cp-spinner cp-bubble"></div>
                  ) : (
                    <button
                      className="btn-login"
                      type="submit"
                    >
                      Sign In <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                    </button>
                  )}
                </form>
              </div>
              <span className="mt-3"><Link style={{color:'#3C923F'}} className="ml-1" to="/forgotPassword">Forgot Password?</Link></span>
              <span className="msg-copyright">Copyright © 2023 Grazr </span>
              {/* <div className="d-block d-md-none text-center mt-2">
                <h6>Don't have an account ?</h6>
                <Link to="/register">Register now </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { user, loggedIn } = state.login;
  return { user, loggedIn };
}

export default connect(mapStateToProps)(Login);
