import React from 'react'
import Service from '../../Service/Service'
import moment from 'moment';
import axios from 'axios';
import { NOTIFICATION } from '../../actions';
import { connect } from 'react-redux';
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";


class ConfirmPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formError: {},
            active_listing_data: [],
            active_listing_data_added: [],
            project_id: 0,
            event_id: 0,
            event_title: "",
            ng_buyer_id: {
                data: 0,
                required: false,
                errMsg: "Required",
            },
            ng_cart_id: {
                data: 0,
                required: false,
                errMsg: "Required",
            },
            buyerName: {
                data: "",
                required: true,
                errMsg: "Required",
            },
            last_name: {
                data: "",
                required: true,
                errMsg: "Required",
            },
            userEmail: {
                data: "",
                required: true,
                errMsg: "Required",
            },
            mobileNumber: {
                data: "",
                required: true,
                errMsg: "Required",
            },
            streetAddress: {
                data: "",
                required: true,
                errMsg: "Required",
            },
            eventName: {
                data: this.props.title,
                required: true,
                errMsg: "Required",
            },
            scheduleDate: {
                data:moment(this.props.currenteventdate).format("YYYY-MM-DD"),
                required: true,
                errMsg: "Required",
            },
            btnloading: false,
            non_schedule_multi: {
                data: "",
                checked: false,
            },
            multi_buyers: [
                {
                    ng_buyer_id: {
                        data: 0,
                        required: false,
                        errMsg: "Required",
                    },
                    ng_cart_id: {
                        data: 0,
                        required: false,
                        errMsg: "Required",
                    },
                    buyerName: {
                        data: "",
                        required: false,
                        errMsg: "Required",
                    },
                    last_name: {
                        data: "",
                        required: false,
                        errMsg: "Required",
                    },
                    userEmail: {
                        data: "",
                        required: false,
                        errMsg: "Required",
                    },
                    mobileNumber: {
                        data: "",
                        required: false,
                        errMsg: "Required",
                    },
                    streetAddress: {
                        data: "",
                        required: false,
                        errMsg: "Required",
                    },
                    quarter: {
                        data: "",
                        required: false,
                        errMsg: "Required",
                    },
                },
            ],
        };
    }

    componentDidMount() {
        if (this.props.action == "edit") {
            let event_list = this.props.active_listing_data.filter(
                (item) => item.id == this.props.event_id
            );

            if (event_list.length > 0) {
                if (event_list.length > 1) {
                    let multi_buyers_list = [];

                    for (let i = 0; i < event_list.length; i++) {
                        multi_buyers_list.push({
                            ng_buyer_id: {
                                data: event_list[i].ng_buyer_id || 0,
                                required: false,
                                errMsg: "Required",
                            },
                            ng_cart_id: {
                                data: event_list[i].ng_cart_id || 0,
                                required: false,
                                errMsg: "Required",
                            },
                            buyerName: {
                                data: event_list[i].ng_buyer_firstname || "",
                                required: false,
                                errMsg: "Required",
                            },
                            last_name: {
                                data: event_list[i].ng_buyer_lastname || "",
                                required: false,
                                errMsg: "Required",
                            },
                            userEmail: {
                                data: event_list[i].ng_email || "",
                                required: false,
                                errMsg: "Required",
                            },
                            mobileNumber: {
                                data: event_list[i].ng_phone || "",
                                required: false,
                                errMsg: "Required",
                            },
                            streetAddress: {
                                data: event_list[i].ng_address || "",
                                required: false,
                                errMsg: "Required",
                            },
                            quarter: {
                                data: event_list[i].ng_quarter || "",
                                required: false,
                                errMsg: "Required",
                            },
                        });
                    }

                    this.setState({
                        active_listing_data: event_list,
                        non_schedule_multi: {
                            data: "",
                            checked: true,
                        },
                        multi_buyers: multi_buyers_list,
                    });
                } else {
                    this.setState({
                        active_listing_data: event_list,
                        ng_buyer_id: {
                            data: event_list[0].ng_buyer_id || 0,
                            required: false,
                            errMsg: "Required",
                        },
                        ng_cart_id: {
                            data: event_list[0].ng_cart_id || 0,
                            required: false,
                            errMsg: "Required",
                        },
                        buyerName: {
                            data: event_list[0].ng_buyer_firstname || "",
                            required: false,
                            errMsg: "Required",
                        },
                        last_name: {
                            data: event_list[0].ng_buyer_lastname || "",
                            required: false,
                            errMsg: "Required",
                        },
                        userEmail: {
                            data: event_list[0].ng_email || "",
                            required: false,
                            errMsg: "Required",
                        },
                        mobileNumber: {
                            data: event_list[0].ng_phone || "",
                            required: false,
                            errMsg: "Required",
                        },
                        streetAddress: {
                            data: event_list[0].ng_address || "",
                            required: false,
                            errMsg: "Required",
                        },
                    });
                }
            } else {
                this.props.closeModal(false);
            }
        }
    }

    handleValidation = () => {
        let { formError } = this.state;
        if (this.state.non_schedule_multi.checked) {

            if (this.state['eventName'].data === "")
                formError['eventName'] =
                    this.state['eventName'].errMsg && this.state['eventName'].errMsg !== ""
                        ? this.state['eventName'].errMsg
                        : "Required";
        } else {
            Object.keys(this.state).map((key) => {
                if (
                    this.state[key] &&
                    this.state[key].required &&
                    this.state[key].data === ""
                )
                    formError[key] =
                        this.state[key].errMsg && this.state[key].errMsg !== ""
                            ? this.state[key].errMsg
                            : "Required";
            });
        }
        this.setState(
            {
                formError: formError,
            },
            () => {
                console.log("error set");
            }
        );
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            btnloading: true,
        });

        if (this.props.user && this.props.user.card_profileid != "") {
            console.log("grazr pay is required  ----");
        } else {
            window.location.href = "/paymentgateway";
            return false;
        }

        let { currenteventdate } = this.props;

        let currenteventdate1 = new Date(currenteventdate);

        let payload = {
            event_id: this.props.event_id,
            action: this.props.action,
            event_date: moment(this.props.currenteventdate).format(
                "YYYY-MM-DD HH:mm:ss"
            ),
            order_id: 0,
            processor_id: this.props.user.id,
            seller_id: "seller",
            category_type: this.props.needarg.category,
            process_option: this.props.needarg.process_option,
            eventName: this.state.eventName.data,
            eventDesc: "",
            day: currenteventdate1.getDay(),
            cart_id: 0,
            extra_field_1: 1,
            scheduleDate: moment(this.state.scheduleDate.data).format("YYYY-MM-DD"),
        };
        payload.ng_buyer_id = this.state.ng_buyer_id.data;
        payload.ng_cart_id = this.state.ng_cart_id.data;
        payload.buyerName = this.state.buyerName.data;
        payload.last_name = this.state.last_name.data;
        payload.userEmail = this.state.userEmail.data;
        payload.streetAddress = this.state.streetAddress.data;
        payload.mobileNumber = this.state.mobileNumber.data;
        payload.status = 0;
        payload.multi_buyers = this.state.multi_buyers;
        payload.non_schedule_multi = this.state.non_schedule_multi.checked;

        payload.nonGrazrOrder = 1;

        console.log("payload =======", payload);
        this.handleValidation();
        if (
            Object.keys(this.state.formError).length > 0 ||
            Object.keys(this.state.formError) === "undefined"
        ) {
            console.log("this.state.formError ==", this.state.formError);
            this.setState({
                btnloading: false,
            });
            return false;
        }

        return new Service(this.props.user.user_token)
            .post("/calendar/calenderEventAction", payload)
            .then((data) => {

                let { formError } = this.state;

                if (data.status === "yes") {
                    formError["error"] = "";
                    this.setState({ formError, btnloading: false });
                    this.props.confirm(
                        this.props.event_id,
                        this.props.action
                    )
                }
                if (data.status === "no") {
                    formError["error"] = data.message;
                    this.setState({ formError, btnloading: false });
                }


            });
    };

    handleChange1 = (e) => {
        console.log("handlechange1 ====", e.target.value);
        let { name, value } = e.target;
        let { formError } = this.state;
        if (formError[name]) delete formError[name];
        if (formError["error"]) delete formError["error"];
        let prestate = this.state[name];
        prestate.data = value;
        this.setState({
            [name]: prestate,
        });
    };

    handleChange2 = (e, index) => {
        let { multi_buyers } = this.state;
        console.log("handlechange2 ====", e.target.value);
        let { name, value } = e.target;
        multi_buyers[index][name].data = value;
        this.setState({
            multi_buyers: multi_buyers,
        });
    };

    handleDatePicker = () => (e) => {
        let {
            scheduleDate,
            formError
        } = this.state;
        if (formError["scheduleDate"]) delete formError["scheduleDate"];
        if (formError["error"]) delete formError["error"];
        if (e){
            scheduleDate.data = e;
        } else{
            scheduleDate.data = "";
        }
        this.setState({
            scheduleDate: scheduleDate,
        });
    };

    render() {
        let { formError } = this.state;
        let { non_schedule_multi, multi_buyers } = this.state;

        console.log("render prop s on reason popou --", this.props);
        return (
            <div className="G-zr">
                <div className="Addmodal zindex" id="myModal">
                    <div
                        className="modal-dialog"
                        style={{ width: "30%", "max-width": "30%", }}
                    >
                        <div className="modal-content trck-lfpop">
                            <div className="modal-header align-items-center">
                                <h4 className="modal-title">{this.props.title}</h4>
                                <span
                                    className="fs-14 fw-sm fa fa-close fa-close-cls close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.props.closeModal(false)}
                                ></span>
                            </div>

                            {this.props.action == "delete" ? (
                                <div className="modal-body align-items-center">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <label htmlFor="EventName">
                                                Are you sure want to {this.props.action} this order ?
                                            </label>
                                            <br></br>
                                            <button
                                                type="submit"
                                                className="btn btn-green"
                                                onClick={(e) =>
                                                    this.props.confirm(
                                                        this.props.event_id,
                                                        this.props.action
                                                    )
                                                }
                                            >
                                                yes
                                            </button>
                                            &nbsp;&nbsp;
                                            <button
                                                type="submit"
                                                className="btn btn-danger"
                                                onClick={this.props.closeModal(false)}
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="modal-body align-items-center"
                                    style={{ overflowY: "scroll", maxHeight:'80vh' }}
                                >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <label htmlFor="EventName">Event Name</label>
                                                            <input
                                                                type="text"
                                                                name="eventName"
                                                                value={this.state.eventName.data}
                                                                onChange={(e) => this.handleChange1(e)}
                                                                className="form-control"
                                                                id="EventName"
                                                                placeholder={"Non Grazr Order"}
                                                                disable={true}
                                                            ></input>
                                                            <span style={{ color: "red" }}>
                                                                {formError["eventName"]}
                                                            </span>
                                                            <br></br>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label htmlFor="scheduleDate">Schedule Date</label>
                                                            <DayPickerInput
                                                                style={{ width: '100%', height: '40px' }}
                                                                dayPickerProps={{ disabledDays: { before: new Date()} }}
                                                                inputProps={{ style: { width: '100%', height: '100%', padding: '0 10px' } }}
                                                                // ref={this.toRef}
                                                                placeholder="Enter schedule date"
                                                                value={this.state.scheduleDate.data}
                                                                format="YYYY-MM-DD"
                                                                onDayChange={this.handleDatePicker()}
                                                            />
                                                            <span style={{ color: "red" }}>
                                                                {formError["scheduleDate"]}
                                                            </span>
                                                            <br></br>
                                                        </div>
                                                        {non_schedule_multi.checked ? (
                                                            <>
                                                                {multi_buyers.map((val, index) => (
                                                                    <>
                                                                        <div className="col-md-12">
                                                                            <div><b>Customer {index + 1}</b></div>
                                                                            <br></br>
                                                                            <label htmlFor="buyerName">
                                                                                First Name
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                name="buyerName"
                                                                                value={
                                                                                    multi_buyers[index].buyerName.data
                                                                                }
                                                                                className="form-control"
                                                                                id="buyerName"
                                                                                onChange={(e) =>
                                                                                    this.handleChange2(e, index)
                                                                                }
                                                                                placeholder="Enter Name"
                                                                            ></input>

                                                                            <br></br>
                                                                            <label htmlFor="last_name">
                                                                                Last Name
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                name="last_name"
                                                                                value={
                                                                                    multi_buyers[index].last_name.data
                                                                                }
                                                                                className="form-control"
                                                                                id="last_name"
                                                                                onChange={(e) =>
                                                                                    this.handleChange2(e, index)
                                                                                }
                                                                                placeholder="Enter last Name"
                                                                            ></input>

                                                                            <br></br>
                                                                            <label htmlFor="streetAddress">
                                                                                Street address
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                name="streetAddress"
                                                                                value={
                                                                                    multi_buyers[index].streetAddress.data
                                                                                }
                                                                                className="form-control"
                                                                                id="streetAddress"
                                                                                placeholder="Enter Address"
                                                                                onChange={(e) =>
                                                                                    this.handleChange2(e, index)
                                                                                }
                                                                            ></input>
                                                                            <br></br>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <br></br>
                                                                            <>
                                                                                <label htmlFor="mobileNumber">
                                                                                    Mobile number
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="mobileNumber"
                                                                                    value={
                                                                                        multi_buyers[index].mobileNumber
                                                                                            .data
                                                                                    }
                                                                                    className="form-control"
                                                                                    id="mobileNumber"
                                                                                    placeholder="Enter Mobile Number"
                                                                                    onChange={(e) =>
                                                                                        this.handleChange2(e, index)
                                                                                    }
                                                                                ></input>

                                                                                <br></br>
                                                                                <label htmlFor="userEmail">Email</label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="userEmail"
                                                                                    value={
                                                                                        multi_buyers[index].userEmail.data
                                                                                    }
                                                                                    className="form-control"
                                                                                    id="userEmail"
                                                                                    placeholder="Enter Email"
                                                                                    onChange={(e) =>
                                                                                        this.handleChange2(e, index)
                                                                                    }
                                                                                ></input>
                                                                                <br></br>
                                                                                <label htmlFor="streetAddress">
                                                                                    Quarter
                                                                                </label>
                                                                                <select
                                                                                    type="text"
                                                                                    name="quarter"
                                                                                    value={
                                                                                        multi_buyers[index].quarter.data
                                                                                    }
                                                                                    className="form-control"
                                                                                    id="quarter"
                                                                                    placeholder="Select Quarter"
                                                                                    onChange={(e) =>
                                                                                        this.handleChange2(e, index)
                                                                                    }
                                                                                >
                                                                                    <option>Select Quarter</option>
                                                                                    <option value="1">1 Quarter</option>
                                                                                    <option value="2">2 Quarters</option>
                                                                                    <option value="3">3 Quarters</option>
                                                                                </select>
                                                                                <br></br>
                                                                            </>

                                                                            {/* {this.state.non_schedule_multi.checked &&
                                                                                index == multi_buyers.length - 1 ? (
                                                                                <div className="text-right">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn plnButton"
                                                                                        onClick={() => this.addCustomer()}
                                                                                    >
                                                                                        {" "}
                                                                                        <span class="material-icons">
                                                                                            add_circle_outline
                                                                                        </span>{" "}
                                                                                        Add more
                                                                                    </button>
                                                                                </div>
                                                                            ) : null} */}
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-md-12">
                                                                    <br></br>

                                                                    <label htmlFor="buyerName">
                                                                        First Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="buyerName"
                                                                        value={this.state.buyerName.data}
                                                                        className="form-control"
                                                                        id="buyerName"
                                                                        onChange={(e) => this.handleChange1(e)}
                                                                        placeholder="Enter Name"
                                                                    ></input>
                                                                    <span style={{ color: "red" }}>
                                                                        {formError["buyerName"]}
                                                                    </span>
                                                                    <br></br>
                                                                    <label htmlFor="last_name">
                                                                        Last Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="last_name"
                                                                        value={this.state.last_name.data}
                                                                        className="form-control"
                                                                        id="last_name"
                                                                        onChange={(e) => this.handleChange1(e)}
                                                                        placeholder="Enter last Name"
                                                                    ></input>
                                                                    <span style={{ color: "red" }}>
                                                                        {formError["last_name"]}
                                                                    </span>
                                                                    <br></br>
                                                                    <label htmlFor="streetAddress">
                                                                        Street address
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="streetAddress"
                                                                        value={this.state.streetAddress.data}
                                                                        className="form-control"
                                                                        id="streetAddress"
                                                                        placeholder="Enter Address"
                                                                        onChange={(e) => this.handleChange1(e)}
                                                                    ></input>
                                                                    <span style={{ color: "red" }}>
                                                                        {formError["streetAddress"]}
                                                                    </span>
                                                                    <br></br>

                                                                </div>
                                                                <div className="col-md-12">
                                                                    <br></br>
                                                                    <label htmlFor="mobileNumber">
                                                                        Mobile number
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="mobileNumber"
                                                                        value={this.state.mobileNumber.data}
                                                                        className="form-control"
                                                                        id="mobileNumber"
                                                                        placeholder="Enter Mobile Number"
                                                                        onChange={(e) => this.handleChange1(e)}
                                                                    ></input>
                                                                    <span style={{ color: "red" }}>
                                                                        {formError["mobileNumber"]}
                                                                    </span>
                                                                    <br></br>
                                                                    <label htmlFor="userEmail">Email</label>
                                                                    <input
                                                                        type="text"
                                                                        name="userEmail"
                                                                        value={this.state.userEmail.data}
                                                                        className="form-control"
                                                                        id="userEmail"
                                                                        placeholder="Enter Email"
                                                                        onChange={(e) => this.handleChange1(e)}
                                                                    ></input>
                                                                    <span style={{ color: "red" }}>
                                                                        {formError["userEmail"]}
                                                                    </span>
                                                                    <br></br>

                                                                    {/* {this.state.non_schedule_multi.checked ? (
                                                                        <div className="text-right">
                                                                            <button
                                                                                type="button"
                                                                                className="btn plnButton"
                                                                                onClick={() => this.addCustomer()}
                                                                            >
                                                                                {" "}
                                                                                <span class="material-icons">
                                                                                    add_circle_outline
                                                                                </span>{" "}
                                                                                Add more
                                                                            </button>
                                                                        </div>
                                                                    ) : null} */}
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className="col-md-12">
                                                            <div className="text-center">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                    disabled={
                                                                        this.state.btnloading
                                                                            ? true
                                                                            : false
                                                                    }
                                                                >
                                                                    Update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const { login: { loggedIn }, login: { user } } = state;
    return { loggedIn, user };
}
export default connect(mapStateToProps)(ConfirmPopup);