// axios import
import axios from 'axios';


// Service class ti interact with server from client
export default class Service {
    constructor(token) {
        this.url = process.env.REACT_APP_BASE_URL;
        this.token =  token;
        this.payload = {};
        this.completeUrl = '';
        this.api = null;
    }
    getInitializedApi() {
        if (this.api) return this.api; // return initialized api if already initialized.
        return (this.api = axios.create({
            baseURL: this.url,
            responseType: 'json',
            headers: {Bearer:this.token}
        }));
    }
    get(url) {
        return this.getInitializedApi().get(url);
    }
    async post(url, data) {
        // console.log("url --> " + url);
        // console.log("data in service --> " + JSON.stringify(data));
        await this.getInitializedApi().post(url, data).then(response=>{
            let {data} = response;
            this.payload = data;
        }).catch(error=>Promise.reject(error));
        return Promise.resolve(this.payload);
    }
}
// End of service class