import React from 'react';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';

const LoadingButton = (props) => {

  return (
    <button
      className={props.className}
      disabled={props.isLoading || props.disabled}
      onClick={!props.isLoading ? props.onClick : null}
      style={props.style}
    >
      {props.isLoading ? 'Loading…' : props.children}
    </button>
  );
}

function mapStateToProps(state) {
    const { user, loggedIn } = state.login;
  
    return { user, loggedIn };
}
export default connect(mapStateToProps)(LoadingButton);