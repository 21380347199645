import React from 'react'; 
import { Switch, Route } from 'react-router-dom'

import Register from '../views/Register/Register'
import Login from '../views/Login/Login'
let routes = [
    {
        path: '/register',
        component: Register,
        exact: true
    },
    {
        path: '/login',
        component: Login,
        exact: true
    }
]

const LoginRoutes = (props) => {
    return (<Switch>
        {routes.map((ele,ind) => {
            return <Route key={ind} history={props.history} {...ele} />
        })}
    </Switch>)
}

export default LoginRoutes;