export const SideMenuData = {
  seller: [
    {
      id: 1,
      name: "Farm Profile",
      active: false,
      url: "/sellerdashboard/profile",
    },
    // {
    //     id:2,
    //     name: 'Transaction',
    //     active: false,
    //     url:'/sellerdashboard/tracker'
    // },
    // {
    //     id:3,
    //     name: 'Messages',
    //     active: false,
    //     url:'/sellerdashboard/message'
    // },
    // {
    //     id:4,
    //     name: 'My Community',
    //     active: false,
    //     url:'/sellerdashboard/mycommunity'
    // }
  ],
  processor: [
    {
      id: 1,
      name: "My Profile",
      active: false,
      url: "/processorprofile",
    },
    // {
    //     id:2,
    //     name: 'My Calender',
    //     active: false,
    //     url:'/processorCalenderNew'
    // },
    // {
    //     id:3,
    //     name: 'Messages',
    //     active: false,
    //     url:'/processordashboard/message'
    // },
    // {
    //     id:4,
    //     name: 'Orders',
    //     active: false,
    //     url:'/processordashboard/order'
    // },
    {
      id: 2,
      name: "Notification Center",
      active: false,
      url: "/processor/communications",
    },
    {
      id: 3,
      name: "Invoice Template",
      active: false,
      url: "/processor/invoice-template",
    },
  ],
  buyer: [
    {
      id: 1,
      name: "My Profile",
      active: false,
      url: "/buyerdashboard/profile",
    },
    // {
    //     id:2,
    //     name: 'Watchlist',
    //     active: false,
    //     url:'/buyerdashboard/watchlist'
    // },
    // {
    //     id:3,
    //     name: 'Tracker',
    //     active: false,
    //     url:'/buyerdashboard/tracker'
    // },
    // {
    //     id:4,
    //     name: 'Favorite Farms',
    //     active: false,
    //     url:'/buyerdashboard/favoritefarm'
    // },
    // {
    //     id:5,
    //     name: 'Messages',
    //     active: false,
    //     url:'/buyerdashboard/message'
    // }
  ],
};
