import React, { Component } from 'react';
import './Message.css';
import { Link } from 'react-router-dom';
import Buyerprofile from '../Profile/BuyerProfile/Buyerprofile';
import Sidebar from '../Sidebar/Sidebar';

class Message extends Component {
  render() {
    var prop = this.props;
    console.log('FFFFFF', prop)
    return (
      <div className="G-zr top-h">
        <section className="px-5 pb-5">
          <Sidebar sideMenu='false'></Sidebar>
          <div className="row">
            <div className="col-md-3">
              <Sidebar activeMenu="message"></Sidebar>
            </div>
            <div className="col-md-9">
              <div className="Dash-right">
                <div className="row">
                  <div className="col-md-8">
                    <div className="msg-head d-flex ">
                      <figure className="m-auto w-8p">
                        <img src="/images/feed-b.png" className="img-fluid" alt="user-avator" />
                      </figure>
                      <div className="d-flex justify-content-between align-items-center  w-92p">
                        <span>
                          <h5 className="fs-16 fw-m m-0 text-white mb-1">Stephen Ron</h5>
                          <p className="fs-14 fw-r  m-0">Online</p>
                        </span>
                        <span>
                          <div className="dropdown">
                            <span id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <svg xmlns="http://www.w3.org/2000/svg" width={4} height={18} viewBox="0 0 4 18">
                                <g id="Group_8638" data-name="Group 8638" transform="translate(589.111 -1243.149) rotate(90)">
                                  <circle id="Ellipse_481" data-name="Ellipse 481" cx={2} cy={2} r={2} transform="translate(1243.149 585.111)" fill="#fff" />
                                  <circle id="Ellipse_482" data-name="Ellipse 482" cx={2} cy={2} r={2} transform="translate(1250.149 585.111)" fill="#fff" />
                                  <circle id="Ellipse_483" data-name="Ellipse 483" cx={2} cy={2} r={2} transform="translate(1257.149 585.111)" fill="#fff" />
                                </g>
                              </svg>
                            </span>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" href="#">Leave Group</a>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="msg-body">
                      <span className="reciver-msg">
                        <h6 className="m-0 fw-r">Hey...</h6>
                        <p className="m-0 mon fs-12 fw-m montserrat">08:52 PM</p>
                      </span>
                      <span className="reciver-msg">
                        <h6 className="m-0 fw-r">Lorem ipsum dolor sit amet, consectetur</h6>
                        <p className="m-0 mon fs-12 fw-m montserrat">08:52 PM</p>
                      </span>
                      <p className="date-divider montserrat text-center my-3 txt-gray fs-12 fw-m position-relative">31/10/2019</p>
                      <span className="sender-msg">
                        <h6 className="m-0 fw-r">Lorem ipsum ...</h6>
                        <p className="m-0 mon fs-12 fw-m montserrat">08:52 PM</p>
                      </span>
                      <span className="sender-msg">
                        <h6 className="m-0 fw-r">Lorem ipsum dolor sit amet, consectetur</h6>
                        <p className="m-0 mon fs-12 fw-m montserrat">08:52 PM</p>
                      </span>
                      <span className="sender-msg">
                        <h6 className="m-0 fw-r">Lorem ipsum ...</h6>
                        <p className="m-0 mon fs-12 fw-m montserrat">08:52 PM</p>
                      </span>
                    </div>
                    <div className="msg-fot">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <object data="/images/icon/smily.svg" type="image/svg+xml" />
                        </div>
                        <input type="text" className="form-control" placeholder="Type a Message..." aria-label="Example text with button addon" aria-describedby="button-addon1" />
                        <div className="input-group-prepend">
                          <object data="/images/icon/send.svg" type="image/svg+xml" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="msg-list-head">
                      <form className="comunity-sch">
                        <div className="input-group">
                          <input type="text" className="form-control a-in" id="validationTooltipUsername" placeholder="Search for messages, people..." aria-describedby="validationTooltipUsernamePrepend" required />
                          <div className="input-group-prepend">
                            <span className="input-group-text " id="validationTooltipUsernamePrepend">
                              {/* <object data="./images/search.svg" type="image/svg+xml" class="object-circle">
                                                            </object> */}
                              <figure className="m-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.811" height="19.811" viewBox="0 0 19.811 19.811">
                                  <g id="search_1_" data-name="search (1)" transform="translate(0.75 0.75)">
                                    <circle id="Ellipse_389" data-name="Ellipse 389" cx={8} cy={8} r={8} strokeWidth="1.5" stroke="#358539" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                                    <line id="Line_14" data-name="Line 14" x1="4.35" y1="4.35" transform="translate(13.65 13.65)" fill="none" stroke="#358539" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                  </g>
                                </svg>
                              </figure>
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="msg-list" id="style-7">
                      <span className="B-L d-flex justify-content-between align-items-center">
                        <figure className="m-0">
                          <img src="/images/feed-b.png" className="img-fluid" alt="list user" />
                        </figure>
                        <div className="list-bdy d-flex justify-content-between align-items-end">
                          <span>
                            <h5 className="fs-15 fw-r">Amy Will</h5>
                            <p className="fs-12 fw-r  txt-gray m-0">Lorem ipsum dolor sit ame...</p>
                          </span>
                          <span>
                            <p className="fw-m fs-10 montserrat m-0 txt-gray">12:52 AM</p>
                          </span>
                        </div>
                      </span>
                      <span className="B-L  active d-flex justify-content-between align-items-center">
                        <figure className="m-0">
                          <img src="/images/feed-a.png" className="img-fluid" alt="list user" />
                        </figure>
                        <div className="list-bdy d-flex justify-content-between align-items-end">
                          <span>
                            <h5 className="fs-15 fw-r">Amy Will</h5>
                            <p className="fs-12 fw-r  txt-gray m-0">Lorem ipsum dolor sit ame...</p>
                          </span>
                          <span>
                            <p className="fw-m fs-10 montserrat m-0 txt-gray">12:52 AM</p>
                          </span>
                        </div>
                      </span>
                      <span className="B-L d-flex justify-content-between align-items-center">
                        <figure className="m-0">
                          <img src="/images/mem-d.png" className="img-fluid" alt="list user" />
                        </figure>
                        <div className="list-bdy d-flex justify-content-between align-items-end">
                          <span>
                            <h5 className="fs-15 fw-r">Amy Will</h5>
                            <p className="fs-12 fw-r  txt-gray m-0">Lorem ipsum dolor sit ame...</p>
                          </span>
                          <span>
                            <h5 className="fs-12 fw-m montserrat unread">02</h5>
                            <p className="fw-m fs-10 montserrat m-0 txt-gray">12:52 AM</p>
                          </span>
                        </div>
                      </span>
                      <span className="B-L active d-flex justify-content-between align-items-center">
                        <figure className="m-0">
                          <img src="/images/mem-a.png" className="img-fluid" alt="list user" />
                        </figure>
                        <div className="list-bdy d-flex justify-content-between align-items-end">
                          <span>
                            <h5 className="fs-15 fw-r">Amy Will</h5>
                            <p className="fs-12 fw-r  txt-gray m-0">Lorem ipsum dolor sit ame...</p>
                          </span>
                          <span>
                            <p className="fw-m fs-10 montserrat m-0 txt-gray">12:52 AM</p>
                          </span>
                        </div>
                      </span>
                      <span className="B-L d-flex justify-content-between align-items-center">
                        <figure className="m-0">
                          <img src="/images/feed-b.png" className="img-fluid" alt="list user" />
                        </figure>
                        <div className="list-bdy d-flex justify-content-between align-items-end">
                          <span>
                            <h5 className="fs-15 fw-r">Amy Will</h5>
                            <p className="fs-12 fw-r  txt-gray m-0">Lorem ipsum dolor sit ame...</p>
                          </span>
                          <span>
                            <p className="fw-m fs-10 montserrat m-0 txt-gray">12:52 AM</p>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>



    )
  }
}

export default Message;