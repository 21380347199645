import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../views/Header/Header";
import Footer from "../views/Footer/Footer";
import Message from "../views/Message/Message";
import Error404 from "../views/Error/Error404";

const Home = React.lazy(() => import("../views/Home/Home"));
const Register = React.lazy(() => import("../views/Register/Register"));
const Login = React.lazy(() => import("../views/Login/Login"));
const paymentngorder = React.lazy(() =>
  import("../views/Paymentngprocess/Paymentngprocess")
);
const paymentorder = React.lazy(() =>
  import("../views/Paymentprocess/Paymentprocess")
);
const ForgotPassword = React.lazy(() =>
  import("../views/ForgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("../views/ResetPassword/ResetPassword")
);
const Search = React.lazy(() => import("../views/Search/NewSearch"));
const ProductView = React.lazy(() =>
  import("../views/ProductView/ProductView")
);
const SearchSublot = React.lazy(() =>
  import("../views/SearchSublot/SearchSublot")
);
const SellerDashboard = React.lazy(() =>
  import("../views/Dashboard/Seller/SellerDashboard")
);
const BuyerDashboard = React.lazy(() =>
  import("../views/Dashboard/Buyer/BuyerDashboard")
);
const ProcessorDashboard = React.lazy(() =>
  import("../views/Dashboard/Processor/ProcessorDashboard")
);
const NewOrder = React.lazy(() =>
  import("../views/Dashboard/Processor/NewOrder")
);
const Sellerprofile = React.lazy(() =>
  import("../views/Profile/SellerProfile/Sellerprofile")
);
const Buyerprofile = React.lazy(() =>
  import("../views/Profile/BuyerProfile/Buyerprofile")
);
const Productpost = React.lazy(() =>
  import("../views/Productpost/Productpost2")
);
const Productedit = React.lazy(() =>
  import("../views/Productpost/Productedit")
);
const PostSuccess = React.lazy(() =>
  import("../views/Productpost/PostSuccess")
);
const SellerTracker = React.lazy(() =>
  import("../views/Tracker/SellerTracker/SellerTracker")
);
const BuyerTracker = React.lazy(() =>
  import("../views/Tracker/BuyerTracker/BuyerTracker")
);
const MyCommunity = React.lazy(() =>
  import("../views/MyCommunity/MyCommunity")
);
const CreateCommunity = React.lazy(() =>
  import("../views/MyCommunity/CreateCommunity")
);
const About = React.lazy(() => import("../views/MyCommunity/About"));
const MessageNew = React.lazy(() => import("../views/Message/MessageNew"));
const Error422 = React.lazy(() => import("../views/Error/Error422"));
const Error500 = React.lazy(() => import("../views/Error/Error500"));
const Checkout = React.lazy(() => import("../views/Checkout/Checkout"));
const ProfileView = React.lazy(() =>
  import("../views/ProfileView/ProfileView")
);
const Watchlist = React.lazy(() => import("../views/Watchlist/Watchlist"));
const FavoriteFarm = React.lazy(() =>
  import("../views/FavoriteFarm/FavoriteFarm")
);
const SearchCommunity = React.lazy(() =>
  import("../views/MyCommunity/SearchCommunity")
);
const ProcessorDashboardw = React.lazy(() =>
  import("../views/ProcessorDashboard/ProcessorDashboard.component")
);
const ProcessorProfile = React.lazy(() =>
  import("../views/Profile/Processor/ProcessorProfile.componnt")
);
const ProcessorCalender = React.lazy(() =>
  import("../views/ProcessorDashboard/ProcessorDashCalender.component")
);
const SellerMyCommunity = React.lazy(() =>
  import("../views/MyCommunity/SellerMyCommunity")
);
const Discussion = React.lazy(() => import("../views/MyCommunity/Discussion"));
const ProcessorOrders = React.lazy(() =>
  import("../views/ProcessorDashboard/ProcessorOrder/ProcessorOrders")
);
const Announcements = React.lazy(() =>
  import("../views/MyCommunity/Announcements")
);
const Members = React.lazy(() => import("../views/MyCommunity/Members"));
const PhotoFile = React.lazy(() => import("../views/MyCommunity/PhotoFile"));
const ContactUs = React.lazy(() => import("../views/ContactUs/ContactUs"));
const AboutUs = React.lazy(() => import("../views/AboutUs/AboutUs"));
const privacypolicy = React.lazy(() =>
  import("../views/privacypolicy/privacypolicy")
);
const termscondition = React.lazy(() =>
  import("../views/termscondition/TermsOfService")
);
const PaymentGateway = React.lazy(() =>
  import("../views/PaymentGateway/PaymentGateway")
);
const ActiveOrders = React.lazy(() =>
  import("../views/Dashboard/Processor/ActiveOrders")
);
const PendingOrders = React.lazy(() =>
  import("../views/Dashboard/Processor/PendingOrders")
);
const InprogressOrders = React.lazy(() =>
  import("../views/Dashboard/Processor/InprogressOrders")
);
const DropoffOrders = React.lazy(() =>
  import("../views/Dashboard/Processor/DropoffOrders")
);
const CompleteOrders = React.lazy(() =>
  import("../views/Dashboard/Processor/CompleteOrders")
);
const PendingOrdersNew = React.lazy(() =>
  import("../views/Dashboard/Processor/PendingOrdersNew")
);
const OpenOrders = React.lazy(() =>
  import("../views/Dashboard/Processor/OpenOrders")
);
const OpenOrderDetail = React.lazy(() =>
  import("../views/Dashboard/Processor/OpenOrderDetail")
);
const OpenOrdersNew = React.lazy(() =>
  import("../views/Dashboard/Processor/OpenOrdersNew")
);
const AllCustomer = React.lazy(() =>
  import("../views/Dashboard/Processor/AllCustomer")
);
const Recipes = React.lazy(() =>
  import("../views/Dashboard/Processor/Recipes")
);
const AddLabel = React.lazy(() =>
  import("../views/Dashboard/Processor/label/AddLabel")
);
const EditLabel = React.lazy(() =>
  import("../views/Dashboard/Processor/label/EditLabel")
);
const Calendar = React.lazy(() =>
  import("../views/Dashboard/Processor/Calendar")
);
const ProcessorCutSheet = React.lazy(() =>
  import("../views/Dashboard/Processor/CutSheet")
);
const CutSheetBuilder = React.lazy(() =>
  import("../views/Dashboard/Processor/CutSheetBuilder")
);
const CutSheetForm = React.lazy(() =>
  import("../views/Dashboard/Processor/CutSheetForm")
);
const TranscationDetails = React.lazy(() =>
  import("../views/Dashboard/Processor/TransactionDetails")
);
const ProcessorCommunications = React.lazy(() =>
  import("../views/ProcessorDashboard/ProcessorCommunications")
);
const ProcessorUsersAndRoles = React.lazy(() =>
  import("../views/ProcessorDashboard/ProcessorUsersAndRoles")
);
const InvoiceTemplate = React.lazy(() =>
  import("../views/ProcessorDashboard/InvoiceTemplate")
);
const SellerTranscationDetails = React.lazy(() =>
  import("../views/Dashboard/Seller/TransactionDetails")
);
const Report = React.lazy(() =>
  import("../views/Dashboard/Processor/Report")
);
const BarcodeUrl = React.lazy(() =>
  import("../components/BarcodeUrl/BarcodeUrl")
);
const POS = React.lazy(() => import("../views/POS"));

let routes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/processordashboard/order",
    component: ProcessorOrders,
    exact: true,
  },
  {
    path: "/processordashboard/ngorder",
    component: ProcessorOrders,
    exact: true,
  },
  {
    path: "/search",
    component: Search,
    exact: true,
  },
  {
    path: "/productview/:id",
    component: ProductView,
    exact: true,
  },
  {
    path: "/login/forgot_password",
    //path: '/ResetPassword',
    component: ResetPassword,
    exact: true,
  },
  {
    path: "/forgotPassword",
    component: ForgotPassword,
    exact: true,
  },
  {
    path: "/paymentngorder/:id",
    component: paymentngorder,
    exact: true,
  },
  {
    path: "/paymentorder/:id",
    component: paymentorder,
    exact: true,
  },
  {
    path: "/paymentngorder_pos/:cart_id",
    component: paymentngorder,
    exact: true,
  },
  {
    path: "/paymentorder_pos/:cart_id",
    component: paymentorder,
    exact: true,
  },
  {
    path: "/searchsublot",
    component: SearchSublot,
    exact: true,
  },
  {
    path: "/sellerdashboard",
    component: SellerDashboard,
    exact: true,
  },
  {
    path: "/buyerdashboard",
    component: BuyerDashboard,
    exact: true,
  },
  {
    path: "/processordashboard",
    component: ProcessorDashboard,
    exact: true,
  },
  {
    path: "/buyerdashboard/watchlist",
    component: Watchlist,
    exact: true,
  },
  {
    path: "/buyerdashboard/favoritefarm",
    component: FavoriteFarm,
    exact: true,
  },
  {
    path: "/sellerdashboard/profile",
    component: Sellerprofile,
    exact: true,
  },
  {
    path: "/profileview/:id",
    component: ProfileView,
    exact: true,
  },
  {
    path: "/profileview/",
    component: ProfileView,
    exact: true,
  },
  {
    path: "/sellerdashboard/mycommunity",
    component: MyCommunity,
    exact: true,
  },
  {
    path: "/sellerdashboard/createcommunity",
    component: CreateCommunity,
    exact: true,
  },
  {
    path: "/sellerdashboard/productpost",
    component: Productpost,
    exact: true,
  },
  {
    path: "/sellerdashboard/productedit",
    component: Productedit,
    exact: true,
  },
  {
    path: "/sellerdashboard/productpost/postsuccess",
    component: PostSuccess,
    exact: true,
  },
  {
    path: "/sellerdashboard/tracker",
    component: SellerTracker,
    exact: true,
  },
  {
    path: "/sellerdashboard/allcommunity",
    component: SearchCommunity,
    exact: true,
  },
  {
    path: "/community/about/:id",
    component: About,
    exact: true,
  },
  {
    path: "/community/discussion/:id",
    component: Discussion,
    exact: true,
  },
  {
    path: "/community/announcement/:id",
    component: Announcements,
    exact: true,
  },
  {
    path: "/community/members/:id",
    component: Members,
    exact: true,
  },
  {
    path: "/community/photofile/:id",
    component: PhotoFile,
    exact: true,
  },
  {
    path: "/error422",
    component: Error422,
    exact: true,
  },
  {
    path: "/error500",
    component: Error500,
    exact: true,
  },
  {
    path: "/sidenav",
    component: ProcessorDashboardw,
    exact: true,
  },
  {
    path: "/processorProfile",
    component: ProcessorProfile,
    exact: true,
  },
  {
    path: "/processorCalender",
    component: ProcessorCalender,
    exact: true,
  },
  {
    path: "/processor/communications",
    component: ProcessorCommunications,
    exact: true,
  },
  {
    path: "/processor/users-and-roles",
    component: ProcessorUsersAndRoles,
    exact: true,
  },
  {
    path: "/processor/invoice-template",
    component:InvoiceTemplate,
    exact: true,
  },
  {
    path: "/buyerdashboard/profile",
    component: Buyerprofile,
    exact: true,
  },
  {
    path: "/buyerdashboard/message",
    component: MessageNew,
    exact: true,
    isBuyerMessage: "BuyerMessage",
    user_role: "buyer",
  },
  {
    path: "/sellerdashboard/message",
    component: MessageNew,
    exact: true,
    isBuyerMessage: "SellerMessage",
    user_role: "seller",
  },
  {
    path: "/processordashboard/message",
    component: MessageNew,
    exact: true,
    isBuyerMessage: "SellerMessage",
    user_role: "processor",
  },
  {
    path: "/checkout",
    component: Checkout,
    exact: true,
  },
  {
    path: "/buyerdashboard/tracker",
    component: BuyerTracker,
    exact: true,
  },
  {
    path: "/sellermycommunity",
    component: SellerMyCommunity,
    exact: true,
  },
  {
    path: "/contactus",
    component: ContactUs,
    exact: true,
  },
  {
    path: "/aboutus",
    component: AboutUs,
    exact: true,
  },
  {
    path: "/privacypolicy",
    component: privacypolicy,
    exact: true,
  },
  {
    path: "/termscondition",
    component: termscondition,
    exact: true,
  },
  {
    path: "/paymentgateway",
    component: PaymentGateway,
    exact: true,
  },
  {
    path: "/processordashboard/active",
    component: ActiveOrders,
    exact: true,
  },
  {
    path: "/processordashboard/pending",
    component: PendingOrders,
    exact: true,
  },
  {
    path: "/processordashboard/pendingOrders",
    component: PendingOrdersNew,
    exact: true,
  },
  {
    path: "/processordashboard/inprogressOrders",
    component: InprogressOrders,
    exact: true,
  },
  {
    path: "/processordashboard/dropoffOrders",
    component: DropoffOrders,
    exact: true,
  },
  {
    path: "/processordashboard/completeOrders",
    component: CompleteOrders,
    exact: true,
  },
  {
    path: "/processordashboard/openOrders/:order_id",
    component: OpenOrderDetail,
    exact: true,
  },
  {
    path: "/processordashboard/open",
    component: OpenOrders,
    exact: true,
  },
  {
    path: "/processordashboard/openOrders",
    component: OpenOrdersNew,
    exact: true,
  },
  {
    path: "/allCustomer",
    component: AllCustomer,
    exact: true,
  },
  {
    path: "/recipes",
    component: Recipes,
    exact: true,
  },
  {
    path: "/processorprofile/addLabel",
    component: AddLabel,
    exact: true,
  },
  {
    path: "/processorprofile/editLabel/:label_id",
    component: EditLabel,
    exact: true,
  },
  {
    path: "/processorCalenderNew",
    component: Calendar,
    exact: true,
  },
  {
    path: "/processorCutSheet",
    component: ProcessorCutSheet,
    exact: true,
  },
  {
    path: "/CutSheetBuilder/:form_id",
    component: CutSheetBuilder,
    exact: true,
  },
  {
    path: "/CutSheetForm/:form_id/:data_id",
    component: CutSheetForm,
    exact: true,
  },
  {
    path: "/CutSheetForm/:form_id/:data_id/:action",
    component: CutSheetForm,
    exact: true,
  },
  {
    path: "/grazrpaytransaction",
    component: TranscationDetails,
    exact: true,
  },
  {
    path: "/sellerdashboard/grazrpaytransaction",
    component: SellerTranscationDetails,
    exact: true,
  },
  {
    path: "/pos",
    component: POS,
    exact: true,
  },
  {
    path: "/neworder",
    component: NewOrder,
    exact: true,
  },
  {
    path: "/reports",
    component: Report,
    exact: true,
  },
  {
    path: "/o/:order_id",
    component: BarcodeUrl,
    exact: true,
  }
];

const AllRoutes = (props) => {
  return (
    <>
      {/* <Header history={props.history} /> */}
      <Suspense fallback="Loading...">
        <Switch>
          {routes.map((ele, ind) => {
            return (
              <Route
                key={ind}
                history={props.history}
                isAuthed={props.history}
                {...ele}
              />
            );
          })}
        </Switch>
      </Suspense>
      {/* <Footer /> */}
    </>
  );
};

export default AllRoutes;
