import React, {Component} from 'react';
import './Error.css';
import { Link } from 'react-router-dom';

class Error404 extends Component{
    render(){

        return (
            <div className="G-zr">
            <div className="container place-order text-center site-top-hight">
            <figure className="mb-5 error-page">
              <svg xmlns="http://www.w3.org/2000/svg" width="528.615" height="538.325" viewBox="0 0 528.615 538.325">
                <g id="Group_9311" data-name="Group 9311" transform="translate(-424 -159.118)">
                  <path id="Path_3589" data-name="Path 3589" d="M221.478,367.223S228.067,177.3,346.643,213.187s138.631,126.977,223.478,56.588S772.21,338.806,650.34,445.76s140.382,173.2,9.256,224.268-209-139.4-303.5-51.067S153.6,580.057,192.167,502.9,221.478,367.223,221.478,367.223Z" transform="translate(239.855 -49.556)" fill="rgba(59,147,63,0.66)" opacity="0.16" />
                  <g id="Group_9260" data-name="Group 9260" transform="translate(-34.807 -97.067)">
                    <g id="Group_9241" data-name="Group 9241" transform="translate(537.135 263.24)">
                      <path id="Path_3411" data-name="Path 3411" d="M607.84,483.587a2.63,2.63,0,1,0-2.63,2.63A2.63,2.63,0,0,0,607.84,483.587Z" transform="translate(-338.517 -303.688)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3412" data-name="Path 3412" d="M567.063,915.488a1.99,1.99,0,1,0-1.99,1.989A1.991,1.991,0,0,0,567.063,915.488Z" transform="translate(-330.589 -390.507)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3413" data-name="Path 3413" d="M443.654,594.106a4.7,4.7,0,1,0-4.7,4.7A4.7,4.7,0,0,0,443.654,594.106Z" transform="translate(-304.73 -325.455)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3414" data-name="Path 3414" d="M823.969,869.041a3.184,3.184,0,1,0-3.183,3.184A3.183,3.183,0,0,0,823.969,869.041Z" transform="translate(-381.676 -380.944)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3415" data-name="Path 3415" d="M763.136,480.091a3.769,3.769,0,1,0-3.769,3.769A3.769,3.769,0,0,0,763.136,480.091Z" transform="translate(-369.23 -302.758)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3416" data-name="Path 3416" d="M343.9,515.627a3.184,3.184,0,1,0-3.184,3.184A3.183,3.183,0,0,0,343.9,515.627Z" transform="translate(-285.317 -310.008)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3417" data-name="Path 3417" d="M446.253,681.178a1.153,1.153,0,1,0-1.153,1.153A1.153,1.153,0,0,0,446.253,681.178Z" transform="translate(-306.677 -343.645)" fill="rgba(41,203,49,0.25)" />
                      <circle id="Ellipse_502" data-name="Ellipse 502" cx="1.513" cy="1.513" r="1.513" transform="translate(443.414 425.014)" fill="rgba(41,203,49,0.25)" />
                      <circle id="Ellipse_503" data-name="Ellipse 503" cx="1.513" cy="1.513" r="1.513" transform="translate(286.787 72.325)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3418" data-name="Path 3418" d="M274.511,726.123a1.153,1.153,0,1,0-1.153,1.153A1.153,1.153,0,0,0,274.511,726.123Z" transform="translate(-272.205 -352.666)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3419" data-name="Path 3419" d="M294.732,638.665a1.153,1.153,0,1,0-1.153,1.153A1.153,1.153,0,0,0,294.732,638.665Z" transform="translate(-276.264 -335.111)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3420" data-name="Path 3420" d="M549.228,467.078a1.153,1.153,0,1,0-1.135,1.169A1.154,1.154,0,0,0,549.228,467.078Z" transform="translate(-327.346 -300.674)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3421" data-name="Path 3421" d="M299.067,419.423a1.153,1.153,0,1,0-1.135,1.169A1.153,1.153,0,0,0,299.067,419.423Z" transform="translate(-277.134 -291.109)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3422" data-name="Path 3422" d="M493.879,344.394a1.153,1.153,0,1,0-1.153,1.153A1.153,1.153,0,0,0,493.879,344.394Z" transform="translate(-316.236 -276.046)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3423" data-name="Path 3423" d="M603.554,548.512a1.153,1.153,0,1,0-.924,1.344A1.153,1.153,0,0,0,603.554,548.512Z" transform="translate(-338.253 -317.058)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3424" data-name="Path 3424" d="M653.8,513.293a1.153,1.153,0,1,0-1.153,1.152A1.153,1.153,0,0,0,653.8,513.293Z" transform="translate(-348.336 -309.947)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3425" data-name="Path 3425" d="M834.99,400.474a1.153,1.153,0,1,0-1.153,1.153A1.153,1.153,0,0,0,834.99,400.474Z" transform="translate(-384.703 -287.302)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3426" data-name="Path 3426" d="M601.549,362.68a1.153,1.153,0,1,0-1.152,1.153A1.152,1.152,0,0,0,601.549,362.68Z" transform="translate(-337.847 -279.717)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3427" data-name="Path 3427" d="M566.918,534.165a1.153,1.153,0,1,0-1.153,1.153A1.153,1.153,0,0,0,566.918,534.165Z" transform="translate(-330.896 -314.136)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3428" data-name="Path 3428" d="M734.3,476.218a1.153,1.153,0,1,0-1.153,1.153A1.152,1.152,0,0,0,734.3,476.218Z" transform="translate(-364.493 -302.506)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3429" data-name="Path 3429" d="M488.058,295.464a2.017,2.017,0,1,0-2.017,2.017A2.017,2.017,0,0,0,488.058,295.464Z" transform="translate(-314.721 -266.052)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3430" data-name="Path 3430" d="M321.7,607.047a2.018,2.018,0,1,0-2.018,2.017A2.017,2.017,0,0,0,321.7,607.047Z" transform="translate(-281.33 -328.592)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3431" data-name="Path 3431" d="M727.458,617.621a1.153,1.153,0,1,0-1.153,1.153A1.153,1.153,0,0,0,727.458,617.621Z" transform="translate(-363.119 -330.888)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3432" data-name="Path 3432" d="M659.335,442.119a1.153,1.153,0,1,0-1.152,1.152A1.152,1.152,0,0,0,659.335,442.119Z" transform="translate(-349.446 -295.661)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3433" data-name="Path 3433" d="M409.761,521.394a1.153,1.153,0,1,0-1.153,1.153A1.153,1.153,0,0,0,409.761,521.394Z" transform="translate(-299.352 -311.573)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3434" data-name="Path 3434" d="M370.9,348.21a1.153,1.153,0,1,0-1.153,1.153A1.152,1.152,0,0,0,370.9,348.21Z" transform="translate(-291.551 -276.812)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3435" data-name="Path 3435" d="M287.088,454.8a1.153,1.153,0,1,0-1.152,1.152A1.153,1.153,0,0,0,287.088,454.8Z" transform="translate(-274.73 -298.206)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <circle id="Ellipse_504" data-name="Ellipse 504" cx="0.648" cy="0.648" r="0.648" transform="translate(206.41 394.819)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3436" data-name="Path 3436" d="M502.092,691.108a2.247,2.247,0,1,0-2.247,2.247A2.247,2.247,0,0,0,502.092,691.108Z" transform="translate(-317.445 -345.418)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3437" data-name="Path 3437" d="M446.3,460.149a2.246,2.246,0,1,0-2.246,2.246A2.246,2.246,0,0,0,446.3,460.149Z" transform="translate(-306.247 -299.061)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3438" data-name="Path 3438" d="M277.359,306.289a1.685,1.685,0,1,0-1.685,1.685A1.685,1.685,0,0,0,277.359,306.289Z" transform="translate(-272.563 -268.291)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3439" data-name="Path 3439" d="M748.563,673.97a1.178,1.178,0,1,0-1.177,1.178A1.177,1.177,0,0,0,748.563,673.97Z" transform="translate(-367.346 -342.193)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3440" data-name="Path 3440" d="M294.686,814.883a1.153,1.153,0,1,0-1.153,1.153A1.152,1.152,0,0,0,294.686,814.883Z" transform="translate(-276.255 -370.482)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3441" data-name="Path 3441" d="M790.49,605.4l-.979,4.568-.979-4.568-3.457-1.092,3.457-1.091.979-4.569.979,4.569,3.457,1.091Z" transform="translate(-375.147 -327.311)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3442" data-name="Path 3442" d="M399.806,417.465l-2.537,11.841-2.538-11.841-8.96-2.828,8.96-2.828,2.538-11.841,2.537,11.841,8.96,2.828Z" transform="translate(-295 -287.433)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3443" data-name="Path 3443" d="M554.023,287.716l-2.537,11.841-2.538-11.841-8.96-2.828,8.96-2.828,2.538-11.841,2.537,11.841,8.96,2.828Z" transform="translate(-325.954 -261.39)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3444" data-name="Path 3444" d="M446.684,357.7l-1.591,7.425L443.5,357.7l-5.618-1.773,5.618-1.773,1.591-7.425,1.591,7.425,5.617,1.773Z" transform="translate(-305.46 -276.747)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3445" data-name="Path 3445" d="M288.348,271.13l-1.591,7.424-1.591-7.424-5.617-1.773,5.617-1.774,1.591-7.424,1.591,7.424,5.617,1.774Z" transform="translate(-273.679 -259.37)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3446" data-name="Path 3446" d="M701.969,399.752l-1.59,7.424-1.591-7.424-5.617-1.774,5.617-1.773,1.591-7.424,1.59,7.424,5.618,1.773Z" transform="translate(-356.7 -285.187)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3447" data-name="Path 3447" d="M318.566,470.16l-.994,4.641-.994-4.641-3.511-1.108,3.511-1.109.994-4.64.994,4.64,3.51,1.109Z" transform="translate(-280.407 -300.145)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3448" data-name="Path 3448" d="M352.051,646.858l-1.591,7.424-1.591-7.424-5.618-1.773,5.618-1.773,1.591-7.425,1.591,7.425,5.617,1.773Z" transform="translate(-286.465 -334.786)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3449" data-name="Path 3449" d="M423.984,859.376l-2.812,13.118-2.811-13.118-9.926-3.133,9.926-3.133,2.811-13.119,2.812,13.119,9.926,3.133Z" transform="translate(-299.549 -375.753)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3450" data-name="Path 3450" d="M693.006,655.821l-1.591,7.424-1.59-7.424-5.618-1.773,5.618-1.774,1.59-7.424,1.591,7.424,5.617,1.774Z" transform="translate(-354.901 -336.585)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3451" data-name="Path 3451" d="M315.207,884.325l-1.142,5.327-1.141-5.327-4.031-1.272,4.031-1.273,1.141-5.327,1.142,5.327,4.031,1.273Z" transform="translate(-279.569 -383.071)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3452" data-name="Path 3452" d="M467.909,900.453a1.989,1.989,0,1,0-1.989,1.99A1.989,1.989,0,0,0,467.909,900.453Z" transform="translate(-310.688 -387.489)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3453" data-name="Path 3453" d="M778.68,905.71a1.99,1.99,0,1,0-1.99,1.989A1.99,1.99,0,0,0,778.68,905.71Z" transform="translate(-373.064 -388.544)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3454" data-name="Path 3454" d="M308.665,770.907a3.184,3.184,0,1,0-3.184,3.183A3.183,3.183,0,0,0,308.665,770.907Z" transform="translate(-278.245 -361.247)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3455" data-name="Path 3455" d="M430,263.5a3.184,3.184,0,1,0-4.118,1.822A3.183,3.183,0,0,0,430,263.5Z" transform="translate(-302.641 -259.172)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3456" data-name="Path 3456" d="M351.619,908.689a1.153,1.153,0,1,0-1.152,1.153A1.152,1.152,0,0,0,351.619,908.689Z" transform="translate(-287.682 -389.31)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3457" data-name="Path 3457" d="M372.959,815.809a1.153,1.153,0,1,0-1.153,1.153A1.153,1.153,0,0,0,372.959,815.809Z" transform="translate(-291.965 -370.668)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3458" data-name="Path 3458" d="M705.421,838.337a2.161,2.161,0,1,0-2.161,2.161A2.162,2.162,0,0,0,705.421,838.337Z" transform="translate(-358.291 -374.987)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3459" data-name="Path 3459" d="M735.409,824.881a1.153,1.153,0,1,0-1.153,1.153A1.153,1.153,0,0,0,735.409,824.881Z" transform="translate(-364.715 -372.488)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3460" data-name="Path 3460" d="M593.12,439.841a1.153,1.153,0,1,0-1.153,1.153A1.152,1.152,0,0,0,593.12,439.841Z" transform="translate(-336.156 -295.204)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3461" data-name="Path 3461" d="M465.735,501.365a1.429,1.429,0,1,0-1.429,1.428A1.429,1.429,0,0,0,465.735,501.365Z" transform="translate(-310.476 -307.498)" fill="rgba(41,203,49,0.25)" />
                      <circle id="Ellipse_505" data-name="Ellipse 505" cx="1.153" cy="1.153" r="1.153" transform="translate(60.954 3.179)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3462" data-name="Path 3462" d="M477.418,692.451a.648.648,0,1,0-.648.648A.648.648,0,0,0,477.418,692.451Z" transform="translate(-313.135 -346.009)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3463" data-name="Path 3463" d="M532.557,868.509a.648.648,0,1,0-.648.648A.648.648,0,0,0,532.557,868.509Z" transform="translate(-324.202 -381.346)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3464" data-name="Path 3464" d="M793.8,269.165a.648.648,0,1,0-.648.648A.648.648,0,0,0,793.8,269.165Z" transform="translate(-376.639 -261.048)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3465" data-name="Path 3465" d="M778.707,507.785a.649.649,0,1,0-.648.648A.648.648,0,0,0,778.707,507.785Z" transform="translate(-373.608 -308.943)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3466" data-name="Path 3466" d="M376.717,709.034a.649.649,0,1,0-.649.648A.648.648,0,0,0,376.717,709.034Z" transform="translate(-292.922 -349.337)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3467" data-name="Path 3467" d="M826.848,922.125a1.153,1.153,0,1,0-1.153,1.152A1.153,1.153,0,0,0,826.848,922.125Z" transform="translate(-383.069 -392.007)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3468" data-name="Path 3468" d="M644.1,610a2.162,2.162,0,1,0-2.162,2.162A2.161,2.161,0,0,0,644.1,610Z" transform="translate(-345.983 -329.156)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3469" data-name="Path 3469" d="M578.149,717.63l-.979,4.568-.979-4.568-3.457-1.092,3.457-1.091.979-4.569.979,4.569,3.457,1.091Z" transform="translate(-332.526 -349.837)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3470" data-name="Path 3470" d="M494.684,623l-.979,4.569L492.726,623l-3.457-1.091,3.457-1.091.979-4.569.979,4.569,3.457,1.091Z" transform="translate(-315.774 -330.844)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3471" data-name="Path 3471" d="M552.308,835.3l-1.591,7.425-1.591-7.425-5.618-1.773,5.618-1.774,1.591-7.424,1.591,7.424,5.617,1.774Z" transform="translate(-326.66 -372.61)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3472" data-name="Path 3472" d="M700.357,896.31l-2.26,10.546-2.26-10.546-7.979-2.519,7.979-2.519,2.26-10.546,2.26,10.546,7.98,2.519Z" transform="translate(-355.634 -394.575)" fill="rgba(41,203,49,0.25)" />
                      <path id="Path_3473" data-name="Path 3473" d="M822.678,745.193l-.9,4.181-.9-4.181-3.163-1,3.163-1,.9-4.18.9,4.18,3.163,1Z" transform="translate(-381.7 -355.485)" fill="rgba(41,203,49,0.25)" opacity="0.5" />
                      <path id="Path_3474" data-name="Path 3474" d="M735.285,373.969a2.017,2.017,0,1,0-2.017,2.017A2.017,2.017,0,0,0,735.285,373.969Z" transform="translate(-364.343 -281.809)" fill="rgba(41,203,49,0.25)" />
                      <g id="Group_9176" data-name="Group 9176" transform="translate(303.113 273.345)">
                        <path id="Path_3475" data-name="Path 3475" d="M662.283,623.786a5.46,5.46,0,1,1-6.28-4.492A5.459,5.459,0,0,1,662.283,623.786Z" transform="translate(-651.437 -604.785)" fill="rgba(41,203,49,0.25)" />
                        <path id="Path_3476" data-name="Path 3476" d="M677.214,613.348a2.73,2.73,0,1,1-3.14-2.246A2.73,2.73,0,0,1,677.214,613.348Z" transform="translate(-655.522 -603.148)" fill="rgba(41,203,49,0.25)" />
                        <path id="Path_3477" data-name="Path 3477" d="M691.825,604.2a3.64,3.64,0,1,1-4.187-2.995A3.64,3.64,0,0,1,691.825,604.2Z" transform="translate(-658.092 -601.16)" fill="rgba(41,203,49,0.25)" />
                      </g>
                    </g>
                    <g id="Group_9238" data-name="Group 9238" transform="translate(626.044 338.857)">
                      <g id="Group_9178" data-name="Group 9178" transform="translate(124.204 86.115)">
                        <path id="Path_3478" data-name="Path 3478" d="M522.556,464.026a74.467,74.467,0,0,1,63.939,12.538c3.869,2.969,7.8,7.6,6.123,12.177-1.813,4.962-8.468,5.59-13.745,5.331-9.553-.469-19.222-1.053-28.331-3.968s-17.74-8.441-22.484-16.747" transform="translate(-522.556 -461.518)" fill="#e5e5e5" />
                      </g>
                      <path id="Path_3479" data-name="Path 3479" d="M460.179,462.059c-29.172,17.586-29.117,69.529-9.757,101.644.328.544.673,1.062,1.01,1.592l-.063-.021s18.009,30.063,42.689,51c20.367,17.278,39.1,25.191,48.385,26.265,7.369.854,16.188-1.734,6.234-15.143-7.921-10.67-24.946-34.646-12.391-40.724s22.405,21.727,25.95,30.932c8.089,21,16.034,4.892,16.034,4.892,13.571-52.175-16.145-101.228-25.37-114.777a57.363,57.363,0,0,0-7.275-10.963C523.3,471.341,489.351,444.473,460.179,462.059Z" transform="translate(-381.161 -374.379)" fill="#e5e5e5" />
                      <path id="Path_3480" data-name="Path 3480" d="M481.538,518.636l36.636-21.583L540.3,512.537l-47.551,32.115Z" transform="translate(-390.119 -382.536)" fill="#d3d3d3" />
                      <g id="Group_9179" data-name="Group 9179" transform="translate(43.593 129.894)">
                        <path id="Path_3485" data-name="Path 3485" d="M462.276,556.7c-1.576-3.009-6.087-1.947-9.144-.465q-1.2.582-2.4,1.18c-.014-2.6-.155-5.3-.44-8.061-1.962-19.042-9.854-33.83-17.626-33.029s-12.482,16.886-10.52,35.929c1.813,17.59,8.685,31.52,15.849,32.915l.054.15c9.48,2.349,22.72-13.462,24.513-24.87A6.06,6.06,0,0,0,462.276,556.7Z" transform="translate(-421.701 -516.291)" fill="#e5e5e5" />
                      </g>
                      <path id="Path_3486" data-name="Path 3486" d="M554.673,658.919c4.586,4.63,6.159,10.509,3.512,13.13s-8.51.993-13.1-3.637-6.159-10.509-3.512-13.13S550.087,654.289,554.673,658.919Z" transform="translate(-401.911 -414.044)" fill="#d3d3d3" />
                      <path id="Path_3487" data-name="Path 3487" d="M440.258,583.949c3.462-2.662,7.5-3.215,9.022-1.236s-.052,5.741-3.514,8.4-7.5,3.215-9.022,1.236S436.8,586.61,440.258,583.949Z" transform="translate(-381.01 -399.491)" fill="#d3d3d3" />
                      <path id="Path_3488" data-name="Path 3488" d="M594.906,482.978c3.757,2.109,5.753,5.689,4.458,8s-5.391,2.47-9.148.361-5.753-5.688-4.458-8S591.148,480.87,594.906,482.978Z" transform="translate(-410.957 -379.413)" fill="#d3d3d3" />
                      <ellipse id="Ellipse_506" data-name="Ellipse 506" cx="6.745" cy="11.8" rx="6.745" ry="11.8" transform="translate(180.868 214.144) rotate(-10.982)" fill="#d3d3d3" />
                      <path id="Path_3481" data-name="Path 3481" d="M491.537,386.291c-17.718-29.391-60.935-42.733-92.76-23.548s-40.206,63.632-22.487,93.023,55.589,33.863,87.413,14.679S509.254,415.682,491.537,386.291Z" transform="translate(-367.162 -353.778)" fill="#e5e5e5" />
                    </g>
                    <g id="Group_9239" data-name="Group 9239" transform="matrix(0.995, 0.105, -0.105, 0.995, 643.409, 347.715)">
                      <path id="Path_3482" data-name="Path 3482" d="M103.561,26.209C89.426,2.762,53.19-8.546,26.572,7.5s-33.538,53.37-19.4,76.817,44.773,25.468,71.39,9.422S117.7,49.657,103.561,26.209Z" transform="translate(-0.001)" fill="#d3d3d3" />
                      <path id="Path_3483" data-name="Path 3483" d="M14.273,3.612A8.043,8.043,0,0,0,3.662,1.034,8.043,8.043,0,0,0,.988,11.621c1.948,3.232,6.171,3.51,9.839,1.3S16.221,6.844,14.273,3.612Z" transform="matrix(0.309, -0.951, 0.951, 0.309, 11.066, 87.148)" fill="#f2f2f2" />
                      <path id="Path_3484" data-name="Path 3484" d="M6.717,1.7A3.785,3.785,0,0,0,1.723.486,3.785,3.785,0,0,0,.465,5.469c.917,1.521,2.9,1.652,4.63.611S7.633,3.221,6.717,1.7Z" transform="matrix(0.309, -0.951, 0.951, 0.309, 27.557, 93.611)" fill="#f2f2f2" />
                    </g>
                    <g id="cow" transform="translate(634.554 395.292) rotate(-36)">
                      <path id="Path_3560" data-name="Path 3560" d="M9.978,30.589S-1.268,25.044.118,9.739A22.783,22.783,0,0,1,2.411,1.692L2.988.524A.941.941,0,0,1,4.749.731c1.178,5.122,4.879,15.7,15.5,16.893L22.913,18Zm0,0" transform="translate(14.414 0)" fill="#bc7d53" />
                      <path id="Path_3561" data-name="Path 3561" d="M13.366,26.913l-3.388,3.3S-1.266,24.664.118,9.361A22.776,22.776,0,0,1,2.411,1.313L2.99.145A.921.921,0,0,1,3.079,0a26.434,26.434,0,0,0-.427,2.886c-1.37,15.149,7.77,22.176,10.714,24.027Zm0,0" transform="translate(14.413 0.379)" fill="#af663e" />
                      <path id="Path_3562" data-name="Path 3562" d="M19.961,4.778S14.032-2.049,1.3.618A1.626,1.626,0,0,0,.047,2.591c.934,3.81,4.52,13.173,17.34,14.374Zm0,0" transform="translate(0 25.834)" fill="#ffc1b8" />
                      <path id="Path_3563" data-name="Path 3563" d="M18.088,13.16l-.7,3.324C4.566,15.282.98,5.918.046,2.109A1.628,1.628,0,0,1,1.3.136C1.538.087,1.769.042,2,0,3.28,4.147,7.1,11.741,18.088,13.16Zm0,0" transform="translate(0 26.316)" fill="#ffb3a8" />
                      <path id="Path_3564" data-name="Path 3564" d="M12.935,30.589s11.245-5.545,9.859-20.85A22.8,22.8,0,0,0,20.5,1.692L19.925.524a.941.941,0,0,0-1.761.207c-1.177,5.122-4.879,15.7-15.5,16.893L0,18Zm0,0" transform="translate(45.619 0)" fill="#bc7d53" />
                      <path id="Path_3565" data-name="Path 3565" d="M0,26.913l3.388,3.3s11.245-5.545,9.86-20.849a22.775,22.775,0,0,0-2.292-8.048L10.376.145A.832.832,0,0,0,10.287,0a26.262,26.262,0,0,1,.427,2.886C12.085,18.035,2.944,25.063,0,26.913Zm0,0" transform="translate(55.166 0.379)" fill="#af663e" />
                      <path id="Path_3566" data-name="Path 3566" d="M0,4.778S5.929-2.049,18.658.618a1.627,1.627,0,0,1,1.257,1.972c-.934,3.81-4.52,13.173-17.34,14.374Zm0,0" transform="translate(62.984 25.834)" fill="#ffc1b8" />
                      <path id="Path_3567" data-name="Path 3567" d="M0,13.16l.7,3.324c12.82-1.2,16.406-10.566,17.34-14.374A1.629,1.629,0,0,0,16.783.136Q16.432.062,16.09,0C14.807,4.147,10.983,11.741,0,13.16Zm0,0" transform="translate(64.858 26.316)" fill="#ffb3a8" />
                      <path id="Path_3568" data-name="Path 3568" d="M25.888.008C18.708-.165,11.461,2.33,6.536,7.358-.74,14.787-.849,25.471,1.011,34.748c.146.728.333,1.446.548,2.156C2.391,39.652,4.7,47.644,4.7,51.651c0,5.311.788,10.813,5.513,14.564a15.91,15.91,0,0,0,6.426,2.945,24.392,24.392,0,0,0,4.617.549c6.068.181,13.025.582,18.147-3.03a14.831,14.831,0,0,0,5.8-9.4,27.686,27.686,0,0,0,.337-5.629c0-4.006,2.308-11.991,3.142-14.743a24.637,24.637,0,0,0,.6-2.444C51.4,23.475,50.4,10.752,39.338,3.883A26.788,26.788,0,0,0,25.888.009Zm0,0" transform="translate(16.382 13.827)" fill="#e7e7e7" />
                      <path id="Path_3569" data-name="Path 3569" d="M25.867,34.464a24.442,24.442,0,0,1-.6,2.443c-.834,2.752-3.142,10.738-3.142,14.744a27.729,27.729,0,0,1-.337,5.629,14.831,14.831,0,0,1-5.8,9.4C11.71,69.694,6.155,69.913.9,69.8c4.129-.121,8.228-.774,11.555-3.12a14.834,14.834,0,0,0,5.8-9.4,27.733,27.733,0,0,0,.336-5.629c0-4,2.308-11.991,3.141-14.744a24.112,24.112,0,0,0,.6-2.443c2.122-10.989,1.114-23.713-9.945-30.581A26.58,26.58,0,0,0,0,.054Q1.234-.021,2.472.009A26.79,26.79,0,0,1,15.923,3.884c11.059,6.868,12.065,19.592,9.944,30.581Zm0,0" transform="translate(39.797 13.827)" fill="#cecece" />
                      <path id="Path_3570" data-name="Path 3570" d="M38.948,4.921A20.394,20.394,0,0,0,31.4,1.306a35.024,35.024,0,0,0-18.952,0A20.4,20.4,0,0,0,4.9,4.921,14.481,14.481,0,0,0,.8,20.427c2.379,6.288,7.945,8.082,13.6,8.156,5.04.065,10.007.066,15.049,0,5.655-.074,11.221-1.868,13.6-8.156A14.48,14.48,0,0,0,38.948,4.921Zm0,0" transform="translate(19.549 55.385)" fill="#ffc1b8" />
                      <path id="Path_3571" data-name="Path 3571" d="M22.708,20.427c-2.379,6.287-7.945,8.082-13.6,8.155-3.053.039-6.079.055-9.108.046,1.976,0,3.951-.02,5.94-.046,5.655-.074,11.222-1.869,13.6-8.155a14.482,14.482,0,0,0-4.1-15.508A20.384,20.384,0,0,0,7.891,1.305,35.767,35.767,0,0,0,0,.037,35.259,35.259,0,0,1,11.06,1.305a20.388,20.388,0,0,1,7.549,3.614A14.487,14.487,0,0,1,22.708,20.427Zm0,0" transform="translate(39.889 55.386)" fill="#ffb3a8" />
                      <g id="Group_9232" data-name="Group 9232" transform="translate(16.383 13.828)">
                        <path id="Path_3572" data-name="Path 3572" d="M24.428,16.886c-2.236,1.845-5.619,1-8.359,1.94-2.327.8-4.006,2.831-5.32,4.911S8.328,28.079,6.57,29.8A9,9,0,0,1,.522,31.95c-1.278-8.567-.541-17.9,6.013-24.592C11.4,2.389,18.536-.106,25.631,0c-.034.125-.065.248-.1.369a15.922,15.922,0,0,0,.22,8.423c.7,2.806.9,6.255-1.328,8.091Zm0,0" fill="#484a4c" />
                        <path id="Path_3573" data-name="Path 3573" d="M3.724,1.862A1.862,1.862,0,1,1,1.861,0,1.862,1.862,0,0,1,3.724,1.862Zm0,0" transform="translate(13.054 31.644)" fill="#484a4c" />
                        <path id="Path_3574" data-name="Path 3574" d="M3.724,1.862A1.862,1.862,0,1,1,1.862,0,1.862,1.862,0,0,1,3.724,1.862Zm0,0" transform="translate(33.402 31.644)" fill="#484a4c" />
                        <path id="Path_3575" data-name="Path 3575" d="M3.066,2.618C3.066,1.172,2.379,0,1.533,0S0,1.172,0,2.618.687,5.238,1.533,5.238,3.066,4.065,3.066,2.618Zm0,0" transform="translate(18.416 54.153)" fill="#484a4c" />
                        <path id="Path_3576" data-name="Path 3576" d="M3.065,2.618C3.065,1.172,2.379,0,1.533,0S0,1.172,0,2.618.686,5.238,1.533,5.238,3.065,4.065,3.065,2.618Zm0,0" transform="translate(28.698 54.153)" fill="#484a4c" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </figure>
            <h5 className="txt-gray fw-sm text-uppercase fs-20">LOST IN SPACE</h5>
            <h6 className="pb-2 pt-3 fw-sm fs-20 montserrat">ERROR 404 - Page not found !</h6>
            <h6 className="px-10 fs-18 fw-r mb-4">You may have mistyped the address or the page you have requested may have been moved.</h6>
            <Link to="/">
            <button className="btn btn-green my-4 d-flex justify-content-center align-items-center m-auto">Return to Homepage</button>
            </Link>
          </div>
          </div>
        )
    }
}

export default Error404;