import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./Sidebar.module.css";
import { Link } from "react-router-dom";

import { SideMenuData } from "./SideMenu.data";

class Sidebar extends Component {
  render() {
    var prop = this.props;
    let { user, sideMenu } = this.props;
    let test = this.props.user.msg_count;
    console.log("test ---> ", test);
    let menu = [];
    if (user && sideMenu != "false") {
      menu = SideMenuData[user.role] ? SideMenuData[user.role] : [];
    }

    return this.props.sideMenu != "false" ? (
      <div className={styles.Sidebar}>
        <label for="drop" class="toggle">
          Menu
        </label>
        <input type="checkbox" id="drop" />
        <div className="side-Dash">
          <ul className="p-0">
            {menu.map((menus) => (
              <Link key={menus.id} to={menus.url}>
                <li
                  className={
                    this.props.activeMenu == menus.name ? "active" : ""
                  }
                >
                  {menus.name}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    ) : (
      <div className="row filter-right pb-2 pt-4">
        <div className="col-md-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb p-0 m-0 bg-transparent dash-hd">
              {/* {user.role=="seller" ? <li className="breadcrumb-item active fw-sm text-uppercase" aria-current="page"> <Link className="nav-link" to ='/sellerdashboard' >Dashboard </Link></li> : user.role=="buyer" ? <li className="breadcrumb-item active fw-sm text-uppercase" aria-current="page"> <Link className="nav-link" to ='/buyerdashboard' >Dashboard </Link></li> : <li className="breadcrumb-item active fw-sm text-uppercase" aria-current="page"> <Link className="nav-link" to ='/processordashboard' >Dashboard </Link></li>} */}
            </ol>
          </nav>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, loggedIn } = state.login;
  return { user, loggedIn };
}

export default connect(mapStateToProps)(Sidebar);
