let user ='';
if(localStorage.getItem('user')!="") {
  user = JSON.parse(localStorage.getItem('user'));
}
const initialState = user!="" && user!=null && user!=undefined ? { loggedIn: true, user } : {loggedIn:false};

export default function login(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        loggedIn: true,
        user: action.payload
      }
    case 'LOGIN_FAILURE':
      return {}; 
    // case 'FORGET_PASSWORD':
    //   return action.payload
    case "LOGOUT":
      console.log("LOGOUT");
      return {
        loggedIn:false
      };  
    case "INNER_HEADER":
      return action.payload
    default:
      return state
  }
}