const initialState = {};

export default function Cart(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_CART':
            return Object.assign({}, state, {
                cartData: action.payload.count,
                cartList: action.payload.data,
            })
        default:
          return state
      }
}