import React from 'react';

function processorAgreement() {
  return (
    <>
      <h3>GRAZR and Custom Processor Agreement</h3>
      <div className='modalBody'>
        <p>
          This agreement is between online sales facilitator GRAZR, and custom
          process
        </p>
        <p>Recitals and definitions</p>
        <ol>
          <li>
            The parties recognize the increased consumer demand for local farm
            products, and challenges of local producers in locating and meeting
            consumer demand.
          </li>
          <li>
            The parties wish to retain their distinct roles, GRAZR as an
            administrative facilitator, and the custom meat processor and the
            slaughter and processing facility engaged for processing of meat
            sold by farm producers with the use of GRAZR platform and services.
          </li>
          <li>
            Farm producers mean producers who raise beef cattle and utilize
            GRAZR&rsquo;s online platform, website and marketing to sell its
            live animal directly to live consumers.
          </li>
          <li>
            GRAZR is an online facilitator of sales. It does not at any time
            take ownership of all or part of the live animals listed on its
            website for sale by the farm producer to an end consumer.
          </li>
          <li>
            Custom slaughter and processing facility is the facility engaged by
            end consumers for slaughter and processing of their live animal or
            portion of live animal purchase from farm producers.
          </li>
        </ol>
        <p>Agreement</p>
        <p>The parties agree as follows:</p>
        <ol>
          <li>
            GRAZR facilitates the sale of entire live animals or shares in a
            live animal of farm producers through listing on its website, and
            online marketing. GRAZR acts as the facilitator of the sale between
            the farm producer and end consumer and never takes legal or physical
            possessions or ownership of animals listed on its site. The end
            consumer pays the facilitation / administration fees of GRARZ Inc.
            It shall add a $7.00 service fee to the Processor&rsquo;s price of
            the animal or animal share for its. When processors select cash for
            payment via the invoice GRAZR will capture this data. At the end of
            each month GRAZR will send the processors an invoice to collect all
            transactions which resulted in a cash payment on the GRAZR
            platform.&nbsp;
          </li>
          <li>
            Upon sale of a live animal or share in a live animal, GRAZR will
            provide direct notification to the customer process by Email, and
            SMS Text message.
          </li>
          <li>
            Upon notification that an entire live animal of a farm producer has
            been sold on the GRAZR platform, GRAZR shall notify the processor of
            the sale by Email, and SMS Text message. Such notification shall
            include identification of the animal through photograph, name and
            address of farm and animal ID tag. GRAZR shall provide the customer
            processor contact information of the end consumer including
            telephone number and /or email address.
          </li>
          <li>
            Upon notification that an entire animal has been sold by the farm
            producer through GRAZR facilitation, the custom process shall
            contact the end consumer. For shares greater than one-quarter,
            includes entire animals, the processor shall discuss potential cuts
            of meat desired with the end consumer, and the pricing for such
            processing. If the end consumer purchases one-quarter of a live
            animal or less, the cuts of meat resulting from the processing of
            the animal are at the processor&rsquo;s discretion.&nbsp;
          </li>
          <li>
            Upon delivery by farm producer of the live animal sold to the end
            consumer, the processor shall confirm the animal&rsquo;s
            identification through the identifiers discussed in paragraph 3. In
            the event that the animal does not match, the processor shall work
            with the farm producer to resolve the situation.&nbsp;
          </li>
          <li>
            The customer process shall notify the consumer directly of the date
            of pick up of processed meat. The end consumer shall have two days
            to pick up or forfeit meat.&nbsp;
          </li>
          <li>
            The custom processor and end consumer shall make arrangements for
            the consumer to compensate the processor directly for slaughter and
            processing of the live animal sold through the GRAZR site.
          </li>
          <li>
            The parties agree and understand that all sales are direct between
            the farm producer and the end consumer for consumer and their
            immediate family. Meat processed by the custom processor shall be
            labeled &ldquo;not for resale.&rdquo;
          </li>
          <li>
            The custom processor shall meet all federal requirements for custom
            exempt slaughter, set forth in 9 C.F.R. &sect; 303.1 and other
            federal regulations relevant to the custom slaughter exemption.
          </li>
          <li>
            The custom process shall at all times in business with GRAZR&rsquo;s
            consumers maintain a current custom exempt slaughter and processing
            license from the appropriate state agency.
          </li>
          <li>
            The custom exempt slaughter facility shall not provide the end
            consumer with any meat or meat products that are adulterated or
            misbranded as defined by federal and state law.&nbsp;
          </li>
          <li>
            GRAZR is not liable for the quality of meat slaughtered and process
            from the custom slaughter processor&rsquo;s facility. The custom
            processor agrees to indemnify and hold harmless GRAZR for any
            liability, loss, damage, expense or penalties that are incurred
            because of the farm producer&rsquo;s production of an adulterated or
            misbranded animal or otherwise responsible for illness or poor meat
            quality. This includes any and all demands, claims, suits, actions,
            causes of action or agency investigation or findings.
          </li>
          <li>
            GRAZR is not liable for any deficiencies in the live animals
            provided by the farm producer, delivered to the custom processor for
            processing for the end consumer. Disputes concerning whether
            deficiencies existing in the animal or resulted from processing
            shall be between the farm producer and the custom processor.
          </li>
          <li>
            All disputes claims and questions between the parties regarding the
            rights and obligations of the parties under this Agreement are
            subject to arbitration. Either party may serve on the other a
            written demand for arbitration within one hundred eighty (180) days
            after the dispute first arises, in accordance with the rules and
            using the services of the American Arbitration Association. Each
            party shall pay its own costs in connection with the arbitration,
            and costs of the arbitrators shall be paid in equal amounts by the
            parties.
          </li>
          <li>
            All processor&rsquo;s shall join and maintain a good membership
            standing with the Farm-to-Consumer Legal Defense Fund as
            farmer-members, information found at{' '}
            <a href='https://www.farmtoconsumer.org/' target='_blank'>
              Farm-to-Consumer Legal Defense Fund - Protecting, defending, and
              broadening the rights and viability of independent farmers,
              artisanal food producers, and their consumers.
            </a>
          </li>
        </ol>
        <p>Miscellaneous.</p>
        <ol>
          <li>
            Effective Date: This Agreement shall be effective upon execution and
            shall continue in effect until terminated by either GRAZR or farm
            producer upon 30 days&rsquo; written notice.
          </li>
          <li>
            Construction. The masculine of any word used in this Agreement shall
            include the feminine and neuter gender; the singular, the plural;
            and vice versa.
          </li>
          <li>
            Governing Law. This Agreement is being executed and delivered in the
            State of Tennessee and shall be construed in accordance with and
            governed by the laws of such state.
          </li>
          <li>
            Severability. If, for any reason, any provision or provisions of
            this Agreement are determined to be invalid and contrary to any
            existing or future law, such invalidity shall not impair the
            operation or affect those portions of this Agreement which are
            valid.
          </li>
          <li>
            Unforeseeable Circumstances. Notwithstanding anything to the
            contrary in this Agreement, neither Agister nor Owner shall be
            responsible for any delay or failure of its performance under this
            Agreement if the delay or failure is caused by any matter beyond the
            control of either party, including, but not limited to, government
            regulations, public emergency or necessity including public health
            necessity; legal restrictions; labor disputes and actions related
            thereto; riot, war, or insurrection; and windstorms, rainstorms,
            snowstorms, floods or other acts of God.
          </li>
          <li>
            Entire Agreement. This Agreement constitutes the entire contract
            between the parties and may not be modified or amended except in
            writing signed by both parties.
          </li>
          <li>
            Assignment. This Agreement and each of its provisions shall inure to
            the benefit of and be binding upon the parties, their successors and
            permitted assigns.
          </li>
        </ol>
        <p>
          <br />
          The parties agree to the above terms and have executed this Agreement
          on the dates shown below to be effective on the date or dates
          described above.
        </p>
      </div>
    </>
  );
}

export default processorAgreement;
