export const LOGIN = payload => ({
    type: 'LOGIN_SUCCESS',
    payload
})

export const LOGIN_FAILURE = payload => ({
    type: 'LOGIN_FAILURE',
    payload
})

export const LOGOUT = payload => ({
    type: 'LOGOUT',
    payload
})

export const NOTIFICATION = payload => ({
    type: 'NOTIFICATION',
    payload
})
export const INNER_HEADER = payload => ({
    type: 'INNER_HEADER',
    payload
})
export function updateCart(payload){
    return {
        type: "UPDATE_CART",
        payload
    }
}
export function addTimer(payload) {
    return {
        type: 'ADD_TIMER',
        payload,
    }
}