import React,{Component} from 'react'
import axios from 'axios';
import './Processors.css';
import { Modal } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';




export class PreferedProcessors extends Component{
    state = {
        processors: [],
        type:'all',
        role:'processor',
        load : false,
        typeArr:[],
        zip: '',
        failure_response: '',
        loader:true,
        newProcessorModal:false,
        sameLocation:false,
        newProcessorId: 0,
        newProcessor: {
            email: '',
            firstName:'',
            lastName:'',
            businessName:'',
            phoneNumber:'',
            address:'',
            zipCode:'',
            sameLocation:false,
            pickuplocation:{
                email: '',
                firstName:'',
                lastName:'',
                businessName:'',
                phoneNumber:'',
                address:'',
                zipCode:'',
            }
        },
        checkValidation: false,
        checkLocationValidation: false,
    }

    
    componentDidMount(){
            this.getUserBasedOnRole()
    }

    componentDidUpdate(prevProps) {
        if (this.props.update && prevProps.newProcessorId == 0 && this.props.newProcessorId != 0 && this.state.newProcessorId == 0) {
            console.log('newProcessor',this.props.newProcessor)
            this.setState({
                newProcessorId : this.props.newProcessorId,
                newProcessor : this.props.newProcessor,
            });
        }
    }
    handleClick=(name)=>e=>{
        this.setState({
            type : name
        },()=>{this.getUserBasedOnRole()})
    }
    
    handleAllChecked = (event) => {
        console.log("checked");
        const { value,checked } = event.target;
        console.log("checked --> " + checked);
       // processors
    }

    // searchZip = (event) => {
    //     console.log("search clicked");
    //     this.setState({
    //         zip : this.state.zip
    //     },()=>{this.getUserBasedOnRole()})
    // }

    // handleChangeZip = (e) => {
    //     console.log("handlechange --> " + e.target.value);
    //     this.setState({zip: e.target.value});
    // }

    getUserBasedOnRole=()=>{
    
        let {type,role} = this.state;
        let {
                json_token,
                product,
                product_details
            } = this.props;
         
        let self = this;
        let payload = {
            processorType:this.props.livestock_type ? this.props.livestock_type.data : type,
            role:role,
            product:product ? product : 0,
            product_id:product_details ? product_details.work_loc : 0,
            zip: this.state.zip ? this.state.zip : this.props.zip
        }
        let url = process.env.REACT_APP_BASE_URL;
        let img_url = process.env.REACT_APP_IMAGE_URL;
        let completeUrl = `${url}profile/getrole`;
        axios.defaults.headers.common['Authorization'] = "Bearer " + json_token;
        axios.post(completeUrl,payload).then(response=>{
    
            if(response.data.status == "yes") {
              
                let {data} = response;
                let {data:processors} = data;
                self.setState({
                    processors : processors,
                    loader:false
                })
            } else if(response.data.status == "no"){
               // alert(response.data.message);
                self.setState({
                    failure_response : "No processors found around your location.",
                    loader:false
                })
            } else if(response.data.status == "No") {
                self.setState({
                    failure_response : "Please add zipcode on your profile.",
                    loader:false
                })
            }
            
        }).catch(error=>{
         console.log(error)   
        })
    }

    handleChange = name => event => {
    
        let value = event.target.value
        if(name === 'sameLocation'){
            let newSameLocation = !this.state.sameLocation
            this.setState(prevState => ({...prevState, sameLocation:newSameLocation}))
            this.setState(prevState => ({...prevState, newProcessor: {...prevState.newProcessor, sameLocation:newSameLocation }}))
           
        }else{
            this.setState((prevState)=>({...prevState, newProcessor: {...prevState.newProcessor, [name]:value }}))
        }
    }

    handleValidation() {
        console.log('handleValidation')
        let self = this;
        let validation = true;
        Object.keys(this.state.newProcessor).map(function (key) {
            
          if (self.state.newProcessor[key] === "") {
            console.log('newProcessor',self.state.newProcessor[key])
            validation = false
          }
          
        });
        console.log('validation1',validation)
        if (!this.state.sameLocation){
            Object.keys(this.state.newProcessor.pickuplocation).map(function (key) {
                
                if (self.state.newProcessor.pickuplocation[key] === "") {
                    console.log('pickuplocation',self.state.newProcessor.pickuplocation[key])
                    validation = false
                }
                
              });
        }
        console.log('validation2',validation)
        return validation;
      }


    addNonGrazrProcessor = () => {
        console.log('addNonGrazrProcessor newProcessor', this.state.newProcessor)
        console.log('addNonGrazrProcessor pickuplocation', this.state.newProcessor.pickuplocation)

        if (this.props.user && this.props.user.card_profileid != "") {
            console.log("grazr pay is required  ----", this.props.user);
        } else {
            window.location.href = "/paymentgateway";
            return false;
        }

        this.setState(prevState => ({...prevState, checkValidation:false}))
        this.setState(prevState => ({...prevState, checkLocationValidation:false}))
        if (this.handleValidation()){
            let self = this;
            let payload = this.state.newProcessor;
            payload.order_type = 'non_grazr_processor';
            payload.order_id = 0;
              axios
                .post(process.env.REACT_APP_BASE_URL + "calendar/createDropoff", payload)
                .then(function (response2) {
                  console.log("createDropoff", response2);
                  if (response2.data.status == 'yes'){
                    self.setState(prevState => ({...prevState, newProcessorId:response2.data.data}))

                    let p_first_name = self.state.newProcessor.firstName;
                    let p_last_name = self.state.newProcessor.lastName;
                    if (!self.state.sameLocation){
                        p_first_name = self.state.newProcessor.pickuplocation.firstName;
                        p_last_name = self.state.newProcessor.pickuplocation.lastName;
                    }
                    self.props.addNewProcessor(response2.data.data, p_first_name, p_last_name);
                  } else {
                    self.setState(prevState => ({...prevState, newProcessorId:0}))
                  }
                  
            });

        } else {

            this.setState(prevState => ({...prevState, checkValidation:true}))
            this.setState(prevState => ({...prevState, checkLocationValidation:true}))
        }

        
    }

    handleLocationChange = name => event => {
        let value = event.target.value
        this.setState((prevState)=>({...prevState, newProcessor: {...prevState.newProcessor, pickuplocation: {...prevState.newProcessor.pickuplocation, [name]:value }}}))
    }
   

    render(){
        let img_url = process.env.REACT_APP_BASE_URL;
        let url = process.env.REACT_APP_BASE_URL;
        let {
            showModal,
            closeModal,
            product,
            fallback,
            work_locc,
            checkToggle,
            saveButtonToggle,
            saveThisProcessor,
            checkedname,
            savebtnstage,
            productpost,
            non_grazr_processor,
            product_details,
        } = this.props;
        let {
            type,
            processors,
            failure_response,
            checkValidation,
            checkLocationValidation,
        } = this.state;
        

        //console.log("this state :",processors);
        return(
            <>
           
            <div className="G-zr">
            <div className={showModal ?"modal fade show d-block": "modal fade"} id="select_proc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{"max-width": "800px"}}>
                    <div className="modal-content G-modal rounded-0">
                        <div className="modal-header modal-hdrecng">
                            <div className="modal-title text-uppercase fs-18 fw-sm">Select processors</div> 
                            <span className="fs-14 fw-sm fa fa-close " style={{"cursor": "pointer"}} data-dismiss="modal" aria-label="Close" onClick={closeModal(false,0,processors && processors.length ?processors:'')}>
                            </span>
                        </div>
                        <div className="modal-body px-5 pb-5">
                        {product && product == 1 && non_grazr_processor && non_grazr_processor != 0 ?
                        <>
                            <label className="con-ner">
                                <div className="approved-user d-flex justify-content-between">
                                    <figure className="m-0 w-10p">
                                        <img
                                            src={url + "uploads/profile_img.jpg"}
                                            res={img => this.img = img}
                                            onError={e => e.target.src = url + "uploads/profile_img.jpg"}
                                            className="img-fluid"
                                            alt="user-img" />
                                    </figure>
                                    <div className="w-90p">
                                        <span className="d-flex processor-hd justify-content-between">
                                            <h4 className="fs-15 fw-m ">{product_details.processor_first_name} {product_details.processor_last_name}, {product_details.processor_business_name}
                                                {' '}{' '}
                                                {type.includes("s1") ?
                                                    <img src="/images/bacon1.svg" /> : ''
                                                }
                                                {' '}{' '}
                                                {type.includes("s2") ?
                                                    <img src="/images/ham1.svg" /> : ''
                                                }



                                            </h4>

                                        </span>
                                        <p className="txt-gray montserrat fs-13 fw-r">
                                            {product_details.processor_address}, {product_details.processor_zip}
                                        </p>
                                    </div>
                                </div>

                            </label>
                        </>
                        :
                        <>
                        {/* {productpost && productpost == 1 ? <button className="btn btn-green" style={{"font-size": "16px"}} onClick={()=>{this.setState({newProcessorModal:!this.state.newProcessorModal})}}>{this.state.newProcessorModal ? "Select Grazr Processor" : "Add Non Grazr Processor"}</button> : null} */}
                         {
                             this.props.non_preferred ? (<div>
                                 <form>
                                 <div className="form-row">
                                 <div className="col-md-8 mb-3">
                                 <p><b>Processor Information:</b></p>
                                 </div>
                              


                                
                                 <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Email Address</label>
                               <input type="text" className="form-control" onChange={this.handleChange('email')} placeholder="Email" value={this.state.newProcessor.email} required/>
                               {checkValidation && this.state.newProcessor.email == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                              <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Business Name</label>
                               <input type="text" className="form-control" placeholder="Business Name" onChange={this.handleChange('businessName')} value={this.state.newProcessor.businessName} required/>
                               {checkValidation && this.state.newProcessor.businessName == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                               
                              <div className="col-md-6 mb-3">
                                 <label for="validationServer02">First Name</label>
                               <input type="text" className="form-control" onChange={this.handleChange('firstName')} placeholder="First Name" value={this.state.newProcessor.firstName} required/>
                               {checkValidation && this.state.newProcessor.firstName == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                              <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Last Name</label>
                               <input type="text" className="form-control" placeholder="Last Name" onChange={this.handleChange('lastName')} value={this.state.newProcessor.lastName} required/>
                               {checkValidation && this.state.newProcessor.lastName == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                             
                                 <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Phone Number</label>
                               <input type="text" className="form-control" placeholder="Phone Number" onChange={this.handleChange('phoneNumber')} value={this.state.newProcessor.phoneNumber}  required/>
                               {checkValidation && this.state.newProcessor.phoneNumber == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                                 <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Address</label>
                               <input type="text" className="form-control" placeholder="Address" onChange={this.handleChange('address')} value={this.state.newProcessor.address}  required/>
                               {checkValidation && this.state.newProcessor.address == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                                     
                              
                               
                                <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Zipcode</label>
                               <input type="text" className="form-control" placeholder="Zipcode" onChange={this.handleChange('zipCode')} value={this.state.newProcessor.zipCode}  required/>
                               {checkValidation && this.state.newProcessor.zipCode == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                               

                                
                              
                           
                              <div className="col-md-12 mb-3">
                              <p ><b>Pick Up Location Info:</b></p>
                              </div>
                              <div className="col-md-12 mb-3">
                              <div class="form-check">
                             <input class="form-check-input" type="checkbox" value={this.state.sameLocation} checked={this.state.sameLocation} onChange={this.handleChange('sameLocation')}   required/>
                             <label class="form-check-label" for="invalidCheck">
                                 Same as Processor
                            </label>
                                </div>
                                </div>
                            
                                {
                                    !this.state.sameLocation &&  (
                                        <>
                                         <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Email Address</label>
                               <input type="text" className="form-control" onChange={this.handleLocationChange('email')} placeholder="Email" value={this.state.newProcessor.pickuplocation.email} required/>
                               {checkLocationValidation && this.state.newProcessor.pickuplocation.email == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                              <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Business name</label>
                               <input type="text" className="form-control" placeholder="Business Name" onChange={this.handleLocationChange('businessName')} value={this.state.newProcessor.pickuplocation.businessName} required/>
                               {checkLocationValidation && this.state.newProcessor.pickuplocation.businessName == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                               
                              <div className="col-md-6 mb-3">
                                 <label for="validationServer02">First Name</label>
                               <input type="text" className="form-control" onChange={this.handleLocationChange('firstName')} placeholder="First Name" value={this.state.newProcessor.pickuplocation.firstName} required/>
                               {checkLocationValidation && this.state.newProcessor.pickuplocation.firstName == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                              <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Last Name</label>
                               <input type="text" className="form-control" placeholder="Last Name" onChange={this.handleLocationChange('lastName')} value={this.state.newProcessor.pickuplocation.lastName} required/>
                               {checkLocationValidation && this.state.newProcessor.pickuplocation.lastName == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>

                             
                                 <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Phone Number</label>
                               <input type="text" className="form-control" placeholder="Phone Number" onChange={this.handleLocationChange('phoneNumber')} value={this.state.newProcessor.pickuplocation.phoneNumber}  required/>
                               {checkLocationValidation && this.state.newProcessor.pickuplocation.phoneNumber == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                                 <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Address</label>
                               <input type="text" className="form-control" placeholder="Address" onChange={this.handleLocationChange('address')} value={this.state.newProcessor.pickuplocation.address}  required/>
                               {checkLocationValidation && this.state.newProcessor.pickuplocation.address == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>
                                     
                              
                               
                                <div className="col-md-6 mb-3">
                                 <label for="validationServer02">Zipcode</label>
                               <input type="text" className="form-control" placeholder="Zipcode" onChange={this.handleLocationChange('zipCode')} value={this.state.newProcessor.pickuplocation.zipCode}  required/>
                               {checkLocationValidation && this.state.newProcessor.pickuplocation.zipCode == '' ? <span style={{ color: "red" }}> Required </span> : null }
                              </div>     </>
                                    )
                                }


                                     </div>

                                     </form>
                                <div className="text-center pl-bt">
                                <button className="btn btn-green" style={{"font-size": "16px"}} onClick={()=>{this.addNonGrazrProcessor()}}> Save</button> </div></div>
                                ): (
                                 <>
                                           {this.state.loader?<div className="loader" style ={{width: "120px",height: "120px"}}></div>:null}
                            <div className="processor-list">
                                {processors && processors.length ? <>
                                    {processors.map((process,index)=>{
                                       
                                        let filteredarr = work_locc && work_locc.filter(ele=>{
                                            if(Number(process.id) === Number(ele))
                                                return ele
                                            })
                                            let type = (process.license_number).split(',');
                                       return <label className="con-ner">
                                            <div className="approved-user d-flex justify-content-between">
                                                <figure className="m-0 w-10p">
                                                    <img 
                                                    src= {url+"uploads/"+`${process.avatar}`}
                                                    res = {img => this.img=img}
                                                    onError = {e => e.target.src= url+"uploads/profile_img.jpg"}
                                                    className="img-fluid" 
                                                    alt="user-img"/>
                                                </figure>
                                                <div className="w-90p">
                                                    <span className="d-flex processor-hd justify-content-between">
                                                        <h4 className="fs-15 fw-m ">{process.first_name} {process.last_name}
                                                        {' '}{' '}
                                                        {type.includes("s1") ?
                                                            <img src="/images/bacon1.svg" />:''
                                                        }
                                                        {' '}{' '}
                                                        {type.includes("s2") ?
                                                            <img src="/images/ham1.svg" />:''
                                                        }
                                                        
                                                        
                                                        
                                                        </h4>

                                                        <h6 className="txt-gray montserrat fs-13 fw-r d-flex align-items-center">
                                                            <embed src="/images/icon/distance.svg" width="55" height="20" type="image/svg+xml" />
                                        {parseFloat(process.distance).toFixed(2)} miles</h6>
                                                    </span>
                                    <p className="txt-gray montserrat fs-13 fw-r"> 
                                    {process.city}, {process.state}, {process.country}, {process.zip}
                                    </p>
                                                </div>
                                            </div>
                                         
                                            {checkToggle ? <>
                                                <input 
                                                type="checkbox" 
                                                className="pl-ip"
                                                name="processor_check"
                                                value={process.id} 
                                                onChange={fallback(process.id, process.first_name, process.last_name)}
                                                checked = {filteredarr && filteredarr.length > 0  ? true : false}
                                                />
                                                <span className="checkmark"></span>
                                                </>
                                             :   ''
                                             }
                                        </label>
                                    })}
                                </>:<p>{failure_response}</p>}
                            </div>
                            {this.props.noprocessor? <span style={{ color: "red" }}>you have to select altease one processor and save </span>:null}
                            
                            {saveButtonToggle ? <div className="text-center pl-bt">
                                <button className="btn btn-green" style={{"font-size": "16px"}} onClick={closeModal(false,0)}>Close</button>
                            </div> : ''}
                            {saveThisProcessor ? <div className="text-center pl-bt">
                                <button className="btn btn-green" style={{"font-size": "16px"}} onClick={closeModal(false,1,processors && processors.length ?processors:'')} disabled={savebtnstage?false:true}>Save</button>
                            </div> : ''}




                                 </>
                             )
                         }
                        </>}
                        </div>
                        
                    </div>
                </div>
            </div>
          
            </div>
            </>
        )
    }
}
export default PreferedProcessors;