import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import './Footer.css'
import { connect } from 'react-redux'

class Footer extends Component{
    render(){
      let { loggedIn} = this.props;
        return (
            <footer>
            <section className ="p-5">
            <div className="fot-sec">
              <figure className="m-0 text-center">
                <Link to='/'><img src="/images/Grazr-logo.svg" width="142" height="50" className="img-fluid" alt="Fot-logo" /></Link>
              </figure>
              <div className="d-flex justify-content-center py-4 py-sm-2 fot-list">

              {loggedIn && this.props.user.role=="processor" ? ''
                  // :<span><a href="/search">Listings</a></span> }
                  :<Link to="/search"><span>Listings</span> </Link> }
                {/* <span><a href="/search">Listings</a></span> */}
                {/* <Link className="nav-link" to="/aboutus"><span><a href="/">About Us</a></span></Link> */}
                {/* <span><a href="/aboutus">About Us</a></span> */}
                <Link to="/aboutus"><span>About Us</span> </Link>
                {/* <span><a href="/contactus">Contact Us</a></span> */}
                <Link to="/contactus"><span>Contact Us</span> </Link>
                {/* <span><a href="/">Privacy Policy</a></span> */}
                {/* <Link to={{ pathname: "/aboutus", state: { page:"privacy_policy" }}}><span>Privacy Policy</span> </Link> */}
                <Link to="/privacypolicy"><span>Privacy Policy</span> </Link>
                {/* <span><a href="/">Privacy Policy</a></span> */}
                {/* <span><a href="/">Terms &amp; Conditions</a></span> */}
                
                <Link to="/termscondition"><span>Terms &amp; Conditions</span> </Link>
              </div>
              <div className="d-flex justify-content-center">
                <figure><a href='https://www.facebook.com/grazrmarket/' target="_blank"><img src="/images/fb.svg" width="32" height="32" className="img-fluid" alt="fb" /></a></figure>
                <figure><a href='https://twitter.com/GrazrMarket' target="_blank"><img src="/images/tw.svg" width="32" height="32" className="img-fluid" alt="tw" /></a></figure>
                <figure><a href='https://www.instagram.com/grazrmarket/' target="_blank"><img src="/images/insta.svg" width="32" height="32" className="img-fluid" alt="insta" /></a></figure>
                {/* <figure><a href='https://www.instagram.com/grazrmarket/' target="_blank"><img src="/images/in.svg" className="img-fluid" alt="in" /></a></figure> */}
              </div>
              <div className="d-flex justify-content-center pt-3 contact">
                <span className="af"><a href="mailto:moocow@grazr.net">Moocow@grazr.net</a> </span>
                <span>+452 474 8724</span>
              </div>
            </div>
            </section>
            <div className = "fot-sec2">
                <h6 className="m-0">ALL RIGHTS RESERVED @ 2020</h6>
            </div>

          </footer>
        );
    }
};

function mapStateToProps(state) {

  const { user,loggedIn,class:classs } = state.login;
   const { msg,msgColor,msgLink } = state.header;

   return { user,loggedIn,msg,msgColor,msgLink,classs };
}

export default connect(mapStateToProps)(Footer);
//export default Footer;