import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
// import './../Login/Login.css';
import './Register.css';
import { NOTIFICATION } from '../../actions';
import { Modal } from '@material-ui/core';
import { Button } from '@material-ui/core';
import ProducerAgreement from '../termscondition/ProducerAgreement';
import ProcessorAgreement from '../termscondition/ProcessorAgreement';
import CustomerAgreement from '../termscondition/CustomerTermsOfService';
import LoginSlider from '../../components/LoginSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}px`,
    left: `${left}%`,
    right: '0',
    margin: 'auto',
    bottom: '0',
  };
}
class Register extends Component {
  state = {
    nameRegex: /^[a-zA-Z]+$/,
    first_name: {
      data: '',
      required: true,
      errorMsg: 'Required',
      role: 'all',
    },
    last_name: {
      data: '',
      role: 'all',
      required: true,
      errorMsg: 'Required',
    },
    email: {
      data: '',
      role: 'all',
      required: true,
      errorMsg: 'Required',
    },
    password: {
      data: '',
      role: 'all',
      required: true,
      errorMsg: 'Required',
    },
    confirmpassword: {
      data: '',
      role: 'all',
      required: true,
      errorMsg: 'Required',
    },
    role: {
      data: '',
      role: 'all',
      required: true,
      errorMsg: 'Please select role.',
    },
    processerType: {
      data: [],
      role: 'all',
      required: false,
      errorMsg: 'Please select your expertise.',
    },
    addedService: {
      data: [],
      role: 'all',
      required: false,
      errorMsg: 'Please Select any one option.',
    },
    processorTypeToggle: false,
    processorAgreement: false,
    producerAgreement: false,
    customerAgreement: false,
    processorAgreementModalOpen: false,
    producerAgreementModalOpen: false,
    customerAgreementModalOpen: false,
    error: '',
    required: {},
    formError: {},
  };

  handleValidation = async () => {
    let self = this;
    let {
      role: { data: roledata },
    } = this.state;
    Object.keys(this.state).map(function (key) {
      if (
        self.state[key] &&
        self.state[key].required &&
        self.state[key].data === ''
      ) {
        self.state.formError[key] = self.state[key].errorMsg
          ? self.state[key].errorMsg
          : 'Required!';
      }
      if (key === 'processerType' && roledata === 'processor') {
        if (self.state[key].data.length === 0)
          self.state.formError[key] = self.state[key].errorMsg
            ? self.state[key].errorMsg
            : 'Required';
      }
      if (key === 'addedService' && roledata === 'processor') {
        if (self.state[key].data.length === 0)
          self.state.formError[key] = self.state[key].errorMsg
            ? self.state[key].errorMsg
            : 'Required';
      }
      if (key === 'password' && self.state[key].data.length < 6) {
        self.state.formError[key] = 'The minimum password length is 6';
      }
      if (
        key === 'customerAgreement' &&
        !self.state[key] &&
        roledata === 'buyer'
      ) {
        self.state.formError[key] = 'Please Accept Customer Terms of Service';
      }
      if (
        key === 'producerAgreement' &&
        !self.state[key] &&
        roledata === 'seller'
      ) {
        self.state.formError[key] = 'Please Accept Producer Agreement';
      }
      if (
        key === 'processorAgreement' &&
        !self.state[key] &&
        roledata === 'processor'
      ) {
        self.state.formError[key] = 'Please Accept Processor Agreement';
      }
      self.setState(
        {
          formError: self.state.formError,
        },
        () => console.log(self.state['formError'])
      );
    });
  };

  formSubmit(e) {
    e.preventDefault();
    this.handleValidation();
    const data = new FormData();
    let self = this;
    let {
      role: { data: roledata },
    } = this.state;
    if (Object.keys(self.state.formError).length > 0) {
      if (
        self.state.formError['first_name'] ||
        self.state.formError['last_name'] ||
        self.state.formError['email']
      )
        window.scrollTo(0, 0);
      // if(self.state.formError["password"] || self.state.formError["confirm_password"] ||self.state.formError["role"])
      else window.scrollTo(0, 500);
      return false;
    }

    Object.keys(self.state).map(function (key) {
      if (key === 'processerType' && roledata === 'processor') {
        return self.state[key]
          ? data.append('nmfs_number', self.state[key].data)
          : '';
      }
      if (key === 'addedService' && roledata === 'processor')
        return self.state[key]
          ? data.append('license_number', self.state[key].data)
          : '';
      if (key === 'customerAgreement' || key === 'producerAgreement' || key === 'processorAgreement')
        return self.state[key] ? data.append(key, self.state[key]) : '';

      return self.state[key] ? data.append(key, self.state[key].data) : '';
    });
    let url = process.env.REACT_APP_BASE_URL;
    // const headers = {
    //   'Origin': process.env.REACT_APP_DOMAIN,
    //   'Content-Type': 'application/json'
    // }
    axios
      .post(url + 'auth/register', data)
      .then(function (response) {
        if (response.data.status === 'no') {
          self.setState({
            error: response.data.message,
          });
          return false;
        }

        self.props.dispatch(NOTIFICATION({ msg: 'Registration Successful' }));
        self.props.history.push('/login');
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: '' }));
        }, 3000);
      })
      .catch(function (error) {
        self.setState({
          error: 'Try later!',
        });
        return false;
      });
  }

  handleChange = (name) => (event) => {
    var regEmail =
      /^([A-Za-z0-9_+\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let preState = this.state[name];
    let {
      target: { value },
      target: { checked },
    } = event;
    let processorTypeToggle = false;
    let { processerType, addedService } = this.state;
    processerType.required = false;
    addedService.required = false;
    if (this.state.formError[name]) delete this.state.formError[name];
    if (this.state.formError['processerType'])
      delete this.state.formError['processerType'];
    if (event.target.type === 'radio') {
      if (value === 'processor' && checked) {
        processerType.required = true;
        addedService.required = true;
        processorTypeToggle = true;
      } else {
        processerType.data = [];
        addedService.data = [];
      }
      event.target.value = event.target.checked ? event.target.value : '';
      preState.data = event.target.value;
      if (event.target.checked) {
        preState.required = true;
      }
      if (name === 'processerType') {
        processorTypeToggle = true;
      } else {
        processerType.data = [];
        addedService.data = [];
      }
    }
    if (
      name === 'first_name' &&
      event.target.value !== '' &&
      !event.target.value.trim().match(this.state.nameRegex)
    ) {
      this.state.formError[name] = 'The first name are alphabetic only!';
    }
    if (
      name === 'last_name' &&
      event.target.value !== '' &&
      !event.target.value.trim().match(this.state.nameRegex)
    ) {
      this.state.formError[name] = 'The last name are alphabetic only!';
    }
    if (
      name === 'confirmpassword' &&
      event.target.value != '' &&
      event.target.value !== this.state.password.data
    ) {
      this.state.formError[name] =
        'Password and Confirmation password do not match!';
    }
    if (
      name === 'password' &&
      event.target.value !== '' &&
      this.state.confirmpassword.data != '' &&
      event.target.value != this.state.confirmpassword.data
    ) {
      this.state.formError['confirmpassword'] =
        'Password and Confirmation password do not match!';
    }
    if (
      name === 'password' &&
      event.target.value !== '' &&
      event.target.value === this.state.confirmpassword.data
    ) {
      delete this.state.formError['confirmpassword'];
    }
    if (
      name === 'email' &&
      event.target.value !== '' &&
      !regEmail.test(event.target.value)
    ) {
      this.state.formError[name] = 'Email format is not correct';
    }

    preState.data = event.target.value;

    this.setState(
      {
        [name]: preState,
        processerType: processerType,
        addedService: addedService,
        processorTypeToggle: processorTypeToggle,
        formError: this.state.formError,
        error: '',
      },
      () => console.log(this.state)
    );
  };
  handleClick = (name) => (e) => {
    let prestate = this.state[name];
    let { formError } = this.state;
    if (formError[name]) delete formError[name];
    let {
      target: { value },
    } = e;
    let { data } = prestate;
    if (data.indexOf(value) === -1) {
      data.push(value);
    } else {
      data.splice(data.indexOf(value), 1);
    }
    prestate.data = data;
    this.setState(
      {
        [name]: prestate,
      },
      () => {
        console.log(this.state);
      }
    );
  };
  handleAgreementClick = (name) => (e) => {
    let preState = this.state[name];
    let { formError } = this.state;
    if (formError[name]) delete formError[name];
    this.setState({
      [name]: !preState,
    });
  };
  render() {
    let { processorTypeToggle, processerType, addedService } = this.state;
    let { data: processerTypeData } = processerType;
    let { data: addedServiceData } = addedService;
    return (
      <div className='G-zr'>
        <div className="row m-auto w-100">
          <div className="col-lg-5 p-0 col-md-6 col-sm-12 order-lg-0 order-md-0 order-sm-1 order-xs-1">
            <div className="form-left">
              <img src="./images/logo-white.png" class="img-fluid mr-2" alt="geo" width="251px"></img>
              <img src="./images/meat-login.png" class="img-fluid mr-2 img-meat" alt="geo" width="450px"></img>
            </div>
          </div>
          <div className="col-lg-7 col-md-6 col-sm-12">
            <div className='form-right'>
              <h1 className="register-title pb-3 py-4">Register</h1>
              <div className='G-form'>
                <form className='form' onSubmit={this.formSubmit.bind(this)}>
                  <div className="row">
                    <div className="form-group col-lg-6 mb-4">
                      <label className="G-label">First Name</label>
                      <div class="input-login">
                        <img src="./images/user-icon.svg" class="input-login-icon img-fluid mr-2" alt="geo" width="22" height="22"></img>
                        <input
                          type='text'
                          className="input-login-form"
                          placeholder='Enter your first name'
                          value={this.state.first_name.data}
                          onChange={this.handleChange('first_name')}
                          autoFocus
                        />
                      </div>
                      <span style={{ color: "red" }}>
                        {this.state.formError["username"]}
                      </span>
                    </div>
                    <div className="form-group col-lg-6 mb-4">
                      <label className="G-label">Last Name</label>
                      <div class="input-login">
                        <img src="./images/user-icon.svg" class="input-login-icon img-fluid mr-2" alt="geo" width="22" height="22"></img>
                        <input
                          type='text'
                          className="input-login-form"
                          placeholder='Enter your last name'
                          value={this.state.last_name.data}
                          onChange={this.handleChange('last_name')}
                        />
                      </div>
                      <span style={{ color: 'red' }}>
                        {this.state.formError['last_name']}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-6 mb-4">
                      <label className="G-label">Email Address</label>
                      <div class="input-login">
                        <FontAwesomeIcon style={{ color: '#BBBEC7' }} className="input-login-icon" icon={faEnvelope} />
                        <input
                          type='email'
                          className="input-login-form"
                          placeholder='Enter your email address'
                          value={this.state.email.data}
                          onChange={this.handleChange('email')}
                        />
                      </div>
                      <span style={{ color: 'red' }}>
                        {this.state.formError['email']}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-6 mb-4">
                      <label className="G-label">Password</label>
                      <div class="input-login">
                        <img src="./images/password-icon.svg" class="input-login-icon img-fluid mr-2" alt="geo" width="22" height="22"></img>
                        <input
                          type='password'
                          className="input-login-form"
                          placeholder='Enter your password'
                          value={this.state.password.data}
                          onChange={this.handleChange('password')}
                        />
                      </div>
                      <span style={{ color: 'red' }}>
                        {this.state.formError['password']}
                      </span>
                    </div>
                    <div className="form-group col-lg-6 mb-4">
                      <label className="G-label">Confirm Password</label>
                      <div class="input-login">
                        <img src="./images/password-icon.svg" class="input-login-icon img-fluid mr-2" alt="geo" width="22" height="22"></img>
                        <input
                          type='password'
                          className="input-login-form"
                          placeholder='Re type your password'
                          value={this.state.confirmpassword.data}
                          onChange={this.handleChange('confirmpassword')}
                        />
                      </div>
                      <span style={{ color: 'red' }}>
                        {this.state.formError['confirmpassword']}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className='d-flex align-items-start flex-column col-md-12 mb-4'>
                      <span className='fs-16 pb-3'>
                        Are you creating this account to buy or to sell{' '}
                      </span>
                      <span className='G-radio d-flex justify-content-between align-items-center-register'>
                        <label className='conta'>
                          Buy
                          <input
                            type='radio'
                            name='radio'
                            value='buyer'
                            onChange={this.handleChange('role')}
                          />
                          <span className='checkmark' />
                        </label>
                        <label className='conta'>
                          Sell
                          <input
                            type='radio'
                            name='radio'
                            value='seller'
                            onChange={this.handleChange('role')}
                          />
                          <span className='checkmark' />
                        </label>
                        <label className='conta text-capitalize'>
                          processor
                          <input
                            type='radio'
                            name='radio'
                            value='processor'
                            onChange={this.handleChange('role')}
                          />
                          <span className='checkmark' />
                        </label>
                      </span>
                      <span style={{ color: 'red' }}>
                        {this.state.formError['role']}
                      </span>
                    </div>
                  </div>
                  {processorTypeToggle ? (
                    <>
                      <div className=' animal-selection d-flex align-items-start flex-column mb-4'>
                        <span className='fs-16 pb-3'>
                          Select your area of expertise :
                        </span>
                        <span className='checkbox-area d-flex flex-wrap checkbox-new w-100'>
                          <div className="checkbox-wrap">
                            <input
                              type='checkbox'
                              id='beef'
                              name='radio'
                              value='beef'
                              onChange={this.handleClick('processerType')}
                              checked={
                                processerTypeData.indexOf('beef') > -1
                                  ? true
                                  : false
                              }
                            />
                            <label for='beef' >
                              {' '}
                              Beef
                            </label>
                          </div>
                          <div className="checkbox-wrap">
                            <input
                              id='lamb'
                              type='checkbox'
                              name='radio'
                              value='lamb'
                              onChange={this.handleClick('processerType')}
                              checked={
                                processerTypeData.indexOf('lamb') > -1
                                  ? true
                                  : false
                              }
                            />
                            <label for='lamb' >
                              Lamb
                            </label>
                          </div>
                          <div className="checkbox-wrap">
                            <input
                              id='goat'
                              type='checkbox'
                              name='radio'
                              value='goat'
                              onChange={this.handleClick('processerType')}
                              checked={
                                processerTypeData.indexOf('goat') > -1
                                  ? true
                                  : false
                              }
                            />
                            <label for='goat' >
                              Goat
                            </label>
                          </div>

                          {/* 
                          <div className="checkbox-wrap">
                          <input
                            id='pork'
                            type='checkbox'
                            name='radio'
                            value='pork'
                            onChange={this.handleClick('processerType')}
                            checked={
                              processerTypeData.indexOf('pork') > -1
                                ? true
                                : false
                            }
                          />
                          <label for='pork' >
                            Pork
                          </label> </div> */}

                          <div className="checkbox-wrap">
                            <input
                              id='hog'
                              type='checkbox'
                              name='radio'
                              value='hog'
                              onChange={this.handleClick('processerType')}
                              checked={
                                processerTypeData.indexOf('hog') > -1
                                  ? true
                                  : false
                              }
                            />
                            <label for='hog' >
                              Hog
                            </label>
                          </div>
                          {/* <div className="checkbox-wrap"> <input
                            id='yak'
                            type='checkbox'
                            name='radio'
                            value='yak'
                            onChange={this.handleClick('processerType')}
                            checked={
                              processerTypeData.indexOf('yak') > -1
                                ? true
                                : false
                            }
                          />
                          <label for='yak' >
                            Yak
                          </label> </div> */}
                          <div className="checkbox-wrap">
                            <input
                              id='poultry'
                              type='checkbox'
                              name='radio'
                              value='poultry'
                              onChange={this.handleClick('processerType')}
                              checked={
                                processerTypeData.indexOf('poultry') > -1
                                  ? true
                                  : false
                              }
                            />
                            <label for='poultry' >
                              Poultry
                            </label>
                          </div>
                          <div className="checkbox-wrap">
                            <input
                              id='bison'
                              type='checkbox'
                              name='radio'
                              value='bison'
                              onChange={this.handleClick('processerType')}
                              checked={
                                processerTypeData.indexOf('bison') > -1
                                  ? true
                                  : false
                              }
                            />
                            <label for='bison' >
                              Bison
                            </label>
                          </div>
                          <div className="checkbox-wrap">
                            <input
                              id='elk'
                              type='checkbox'
                              name='radio'
                              value='elk'
                              onChange={this.handleClick('processerType')}
                              checked={
                                processerTypeData.indexOf('elk') > -1
                                  ? true
                                  : false
                              }
                            />
                            <label for='elk' >
                              Elk
                            </label>
                          </div>
                          <div className="checkbox-wrap">
                            <input
                              id='deer'
                              type='checkbox'
                              name='radio'
                              value='deer'
                              onChange={this.handleClick('processerType')}
                              checked={
                                processerTypeData.indexOf('deer') > -1
                                  ? true
                                  : false
                              }
                            />
                            <label for='deer' >
                              Deer
                            </label>
                          </div>
                          <div className="checkbox-wrap">
                            <input
                              id='wild_game'
                              type='checkbox'
                              name='radio'
                              value='wild_game'
                              onChange={this.handleClick('processerType')}
                              checked={
                                processerTypeData.indexOf('wild_game') > -1
                                  ? true
                                  : false
                              }
                            />
                            <label for='wild_game' >
                              Wild Game
                            </label>
                          </div>
                        </span>
                        <span style={{ color: 'red' }}>
                          {this.state.formError['processerType']}
                        </span>
                      </div>
                      <div className='d-flex  align-items-start flex-column'>
                        <span className='fs-16 pb-3'>
                          Value Added Service :
                        </span>
                        <span className='checkbox-area d-flex flex-wrap checkbox-new w-100'>
                          <div className="checkbox-wrap">
                            <input
                              type='checkbox'
                              id='s1'
                              name='radio'
                              value='s1'
                              onChange={this.handleClick('addedService')}
                              checked={
                                addedServiceData.indexOf('s1') > -1 ? true : false
                              }
                            />
                            <label for='s1' >
                              {' '}
                              Bacon{' '}
                            </label>
                          </div>
                          <div className="checkbox-wrap">
                            <input
                              id='s2'
                              type='checkbox'
                              name='radio'
                              value='s2'
                              onChange={this.handleClick('addedService')}
                              checked={
                                addedServiceData.indexOf('s2') > -1 ? true : false
                              }
                            />
                            <label for='s2'>
                              Smoked Ham
                            </label>
                          </div>
                        </span>
                        <span style={{ color: 'red' }}>
                          {this.state.formError['addedService']}
                        </span>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {this.state.role.data === 'buyer' ? (
                    <div className="row">
                      <div className='checkbox-new col-12'>
                        <div className="mb-2">
                          <input
                            type='checkbox'
                            id='customerAgreement'
                            value='customerAgreement'
                            onChange={this.handleAgreementClick(
                              'customerAgreement'
                            )}
                            checked={this.state.customerAgreement}
                          />
                          <label for='customerAgreement'>
                            I have read and agree to{' '}
                          </label>
                        </div>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ customerAgreementModalOpen: true });
                          }}
                        >
                          Customer Terms of Service
                        </Link>
                      </div>
                      <span style={{ color: 'red' }}>
                        {this.state.formError['customerAgreement']}
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                  {this.state.role.data === 'seller' ? (
                    <div className="row">
                      <div className='checkbox-new col-md-12'>

                        <div className="mb-2">
                          <input
                            type='checkbox'
                            id='producerAgreement'
                            value='producerAgreement'
                            onChange={this.handleAgreementClick(
                              'producerAgreement'
                            )}
                            checked={this.state.producerAgreement}
                          />
                          <label for='producerAgreement'>
                            I have read and agree to{' '}
                          </label>
                        </div>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ producerAgreementModalOpen: true });
                          }}
                        >
                          Producer Agreement
                        </Link>
                      </div>
                      <span style={{ color: 'red' }}>
                        {this.state.formError['producerAgreement']}
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                  {this.state.role.data === 'processor' ? (
                    <div className="row">
                      <div className='checkbox-new col-md-12 mt-3'>
                        <div className="mb-2">
                          <input
                            type='checkbox'
                            id='processorAgreement'
                            value='processorAgreement'
                            onChange={this.handleAgreementClick(
                              'processorAgreement'
                            )}
                            checked={this.state.processorAgreement}
                          />
                          <label for='processorAgreement'>
                            I have read and agree to{' '}
                          </label>
                        </div>

                        <Link
                          className='mt-2 d-block'
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              processorAgreementModalOpen: true,
                            });
                          }}
                        >
                          Processor Agreement
                        </Link><br></br>
                        <span style={{ color: 'red' }}>
                          {this.state.formError['processorAgreement']}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {this.state.error ? (
                    <div className='alert alert-danger' role='alert'>
                      {this.state.error}
                    </div>
                  ) : (
                    ''
                  )}
                  <button
                    className="btn-login"
                    type="submit"
                  >
                    Register
                  </button>
                </form>
              </div>
              {/* <div className='d-block d-md-none text-center mt-2'>
                <h6>Already have an account ?</h6>
                <Link to='/login'>Login now </Link>
              </div> */}
            </div>
          </div>
        </div>

        <Modal
          open={this.state.producerAgreementModalOpen}
          onClose={() => this.setState({ producerAgreementModalOpen: false })}
        >
          <div className='registerModalStyle' style={getModalStyle()}>
            <ProducerAgreement />
            <div className='text-center'>
              <Button
                onClick={() =>
                  this.setState({ producerAgreementModalOpen: false })
                }
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.processorAgreementModalOpen}
          onClose={() => this.setState({ processorAgreementModalOpen: false })}
        >
          <div className='registerModalStyle' style={getModalStyle()}>
            <ProcessorAgreement />
            <div className='text-center'>
              <Button
                onClick={() =>
                  this.setState({ processorAgreementModalOpen: false })
                }
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.customerAgreementModalOpen}
          onClose={() => this.setState({ customerAgreementModalOpen: false })}
        >
          <div className='registerModalStyle' style={getModalStyle()}>
            <CustomerAgreement />
            <div className='text-center'>
              <Button
                onClick={() =>
                  this.setState({ customerAgreementModalOpen: false })
                }
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { user, loggedIn } = state.login;
  return { user, loggedIn };
}
export default connect(mapStateToProps)(Register);
