import React from 'react';

const customerTermsOfService = () => {
  return (
    <>
      <h3>CUSTOMER TERMS OF SERVICE</h3>
      <div className='modalBody'>
        <p>
          I Grazr customer agrees to purchase one quarter of a live beef cow.
        </p>
        <p>
          I have read the sample Bill of Sale and agree to the terms set forth
          in the document.&nbsp;
        </p>
        <p>
          I have read, understand and agree to the terms and conditions of
          purchase and sale of a&nbsp;
        </p>
        <p>one-quarter of a live beef cow.</p>
        <ol>
          <li>
            On ordering I understand that I will be purchasing a share in or an
            entire identified live beef cattle from the farm identified through
            the GRAZR website. I understand that Grazr is merely the facilitator
            of this sale and never holds ownership of the live animal / share in
            a live animal that I purchase. I understand that I will be l charged
            for my portion in the identified live animal from the designated
            farm and that my payment is sent directly to the farm that produced
            the identified animal. I likewise understand that payment includes a
            7.5% and 3.1% +30 cents administrative fee to handle transaction
            processing fees and the coordination of the delivery of the live
            animal to processor to consumer by GRAZR.
          </li>
          <li>
            I understand that on purchase, I shall receive a Bill of Sale
            indicating my partial ownership in the identified animal, identified
            by name, breed and tag number.
          </li>
          <li>
            Payment for my share in an identified live animal can be made by
            credit card using the Grazr chosen payment platform. I understand
            that I will take legal possession of the live animal on the payment
            being processed and I will receive a bill of sale for such purchase.
          </li>
        </ol>
        <br />
        <h3>BILL OF SALE</h3>
        <p>
          <strong>of an Undivided/divided Interest in Beef Cow</strong>
        </p>
        <p>
          <strong>From Farm Producer to End Consumer</strong>
        </p>
        <p>
          Seller warrants that at time of sale, Seller has full legal and
          equitable ownership of the animal interest and that the animal is not
          subject to any liens or security interests (recorded or unrecorded) of
          any lender or other third party.&nbsp;
        </p>
        <p>
          The processor will determine the date and time for processing and the
          Buyer shall be notified of the best day and time to pick processed
          meat cuts from the processor. Meat not picked up within two days of
          this date shall be forfeited.
        </p>
        <p>
          (If) Ownership in a share of a live animal necessarily means that the
          animal is not available for slaughter and processing until the entire
          animal has been purchased by other end consumers. The processor will
          determine the date and time for processing and the Buyer shall be
          notified of the best day and time to pick processed meat cuts from the
          processor. Meat not picked up within two days of this date shall be
          forfeited.
        </p>
        <p>
          Risk of loss concerning share in the cow sold under this Agreement
          will pass from Seller to Buyer upon the signing of this Agreement.
        </p>
        <p>
          This Agreement shall be construed exclusively in accordance with the
          laws of the State of TN.&nbsp;
        </p>
        <p>
          The receipt and sufficiency of this Agreement is hereby acknowledged.
        </p>
      </div>
    </>
  );
};

export default customerTermsOfService;
