import React, { Component, useEffect, useState } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Link } from "react-router-dom";
import "./Header.css";
import { connect } from "react-redux";
import NotesIcon from "@material-ui/icons/Notes";
import {
  NOTIFICATION,
  LOGIN,
  LOGIN_FAILURE,
  LOGOUT,
  updateCart,
} from "../../actions";
// import { StickyContainer, Sticky } from 'react-sticky';
import { log } from "util";
import Service from "../../Service/Service";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import NewOrder from "../Dashboard/Processor/NewOrder"
import DialogPopup from '../../components/Popup/DialogPopup';
import Notification from "../../components/Notification/Notification";


const pStyle = {
  textAlign: "center",
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profiledrop: "profiledrop",
      profiledropToggle: false,
      menulist: "",
      pwaToast: false,
      dateTime:'sadas',
      dropdown: false,
      isMobile: false,
      isMenuOpen: false,
      isOpenVersion: false,
      isOpen: false,
      currentDate: ""
    };
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  state = { showSideNav: false };

  myAccountNav = () => {
    this.setState({
      profiledropToggle: !this.state.profiledropToggle,
    });
  };
  clickToRedirect = (event) => {
    this.onresclick();
    let prop = this.props;
    let link =
      // event.target.name === "logoutLink" ?
      "/login";
    //  : '';
    this.setState(
      {
        profiledropToggle: false,
      },
      () => {
        localStorage.setItem("user", "");
        // localStorage.setItem('username', '');
        // localStorage.setItem('password', '');
        localStorage.setItem("draft", 0);
        prop.dispatch(LOGOUT({}));
        // this.props.history.push(link);
      }
    );
  };
  closeToggle = (path) => (event) => {
    const { name } = event.target;
    // if(path=="/search") {
    //   console.log("search page");
    //   window.location.href="/search";
    // }
    // this.setState(path);
    this.setState(() => {
      if (
        path != "/processordashboard/message" &&
        path != "/buyerdashboard/message" &&
        path != "/sellerdashboard/message"
      ) {
        path && this.props.history.push(path);
      } else {
        this.props.history.push({
          pathname: path,
          state: { msgUserid: "admin" },
        });
      }
    });
  };
  handleBlur = () => {
    this.setState({
      profiledropToggle: false,
    });
  };

  // addDefaultSrc(ev){
  //   console.log("addd");
  //   let url = process.env.REACT_APP_BASE_URL;
  //   ev.target.src = url+"uploads/No_Image_Available.jpg"
  // }  // onscroll

  getCart = async () => {
    //setLoader(true);
    try {
      let {
        user: { user_token },
        dispatch,
      } = this.props;
      const res = await new Service(user_token).get(
        "cart/getUserCart",
        {}
      );
      if (res.data.status) {
        let cart_data = res.data.data.filter((val) => parseInt(val.r_id) == 0);
        this.props.dispatch(
          updateCart({
            count: cart_data.length,
            data: cart_data,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  getDatetime =  () => {
    const date = new Date(); // You can replace this with your desired date

    const options = {
      month: 'short', // Display month as abbreviated string (e.g., "Mar")
      day: 'numeric', // Display day as numeric value (e.g., "23")
      year: 'numeric', // Display year as numeric value (e.g., "2023")
      hour: '2-digit', // Display hour in 12-hour format (e.g., "02")
      minute: '2-digit', // Display minute (e.g., "30")
      hour12: true, // Use 12-hour format (e.g., "AM/PM")
    };

    const formattedDateTime = date.toLocaleString('en-US', options);

    this.setState({dateTime:formattedDateTime});

  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let activeClass = "normal";
      if (window.scrollY === 0) {
        activeClass = "top";
      }
      this.setState({ activeClass });
    });
    this.getCart();
    this.updateCurrentDate();
    
  }

  updateCurrentDate = () => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };

    const currentDateRaw = new Date().toLocaleDateString('en-US', options).replaceAll('/', ' ');

    this.setState({ currentDate: currentDateRaw })
  }

  onresclick = (e) => {
    // if (this.state.menulist == "add") {
    //   this.setState({ menulist: "" });
    // } else {
    //   this.setState({ menulist: "add" });
    // }
  };

  componentWillMount() {
    // this.unlisten = this.props.history.listen((location, action) => {
    //   this.setState({ menulist: '' });
    // });
    this.getDatetime();
  }
  componentWillUnmount() {
    // this.unlisten();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    this.setState({ isMobile: window.innerWidth < 768 });
  }

  pwaFunction = () => {
    console.log("entered pwa function inititated");
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(userAgent)) {
        if (/chrome|crios/.test(userAgent)) {
          return false;
        } else if ("brave" in window.navigator) {
          return false;
        } else {
          return /safari/.test(userAgent);
        }
      }
    };

    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.setState({ pwaToast: true });
      this.setState({ showInstallMessage: true });
      // SetPwaToast(true)
    }
  };

  componentDidMount() {
    console.log("pwa function inititated");
    this.pwaFunction();
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  }

  // leftmenu end
  render() {
    let { user, loggedIn, msg, msgLink, msgColor, classs, cartData } =
      this.props;
    let img_url = process.env.REACT_APP_IMAGE_URL;
    let url = process.env.REACT_APP_BASE_URL;

    return (
      <div className="G-zr">
        {/* <StickyCompenet {...this.props} /> */}
        {
          !this.state.isMobile ?
            <header
              className={`fixed-top Af-log ${this.state.activeClass} ${classs}`}
            >
              <nav className="navbar navbar-expand-lg p-0">
                <Link className="navbar-brand" to="/">
                  <img
                    src="/images/logo-white.png"
                    className="img-thumnail logo1"
                    alt="LOGO"
                  />
                </Link>
                <a href="#" className="header-toggler"><FontAwesomeIcon className="mr-3" style={{color:'black'}} icon={faBars} /></a>
                <a href="/processordashboard" className="header-toggler"><FontAwesomeIcon className="mr-3" style={{color:'black'}} icon={faHome} /></a>
                {/* <button
                  className="header-toggler collapsed"
                  type="button"
                  aria-label="nav menu"
                  onClick={this.onresclick}
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button> */}
                {/* <MaterialToggle /> */}
                <div
                  className="collapse navbar-collapse mr-0"
                  id="navbarSupportedContent"
                >
                  {/* <ul className={`navbar-nav ${this.state.lefttog}onres ml-auto`}> */}
                  <ul
                    className={
                      `navbar-nav onres` +
                      " " +
                      `${this.state.menulist}` +
                      " " +
                      `ml-auto`
                    }
                  >
                    {/* {loggedIn && this.props.user.role == "processor" ? (
                      ""
                    ) : (
                      <li className="nav-item" onClick={this.onresclick}>
                        <Link
                          className="nav-link"
                          to="/search"
                          name="listings"
                          onClick={this.closeToggle("/search")}
                        >
                          Listings{" "}
                        </Link>
                      </li>
                    )} */}
                    <div className="header-datetime">
                      <span>{this.state.dateTime}</span>
                    </div>
                    <FontAwesomeIcon className="mr-3 ml-3" style={{color:'#78909C'}} icon={faSearch} />
                    <FontAwesomeIcon className="mr-3 ml-3" style={{color:'#78909C'}} icon={faBell} />
                    <FontAwesomeIcon onClick={() => this.setState({dropdown:!this.state.dropdown})} className="mr-3 ml-3 cursor-pointer" style={{color:'#78909C'}} icon={faEllipsisV} />

                    {/* <li className="nav-item " onClick={this.onresclick}>
                      <Link
                        className="nav-link"
                        to="/aboutus"
                        onClick={this.closeToggle("/aboutus")}
                      >
                        ABOUT US{" "}
                      </Link>
                    </li>
                    <li className="nav-item " onClick={this.onresclick}>
                      <Link
                        className="nav-link"
                        to="/contactus"
                        onClick={this.closeToggle("/contactus")}
                      >
                        CONTACT US{" "}
                      </Link>
                    </li> */}

                    
                  </ul>
                </div>
                
                {
                  this.state.dropdown ? 
                    <a
                      href="/login"
                      name="logoutLink"
                      onClick={() => this.clickToRedirect()}
                    >
                      <div className="header-dropdown">Logout</div> 
                    </a>
                    : 
                    ''
                }
                <Notification />
                {this.state.pwaToast && 
                <div className="installPWA">
                  <div className="inrFlx">
                    <img src="./images/iosadd.svg" alt="add_icon" className="addIconImg"/>
                    <p>Install the webapp on your iPhone: tap <img src="./images/iosupload.svg" alt="menu_icon" className="mnuIconImg"/> and then Add to homescreen</p>
                  </div>
                  <Button onClick={() => this.setState({pwaToast:false})}>Okay</Button>
                </div>
                } 
              </nav>
            </header>
            :
            <div className="header">
              <div className="menu-icon" onClick={() => {this.setState({isMenuOpen:!this.state.isMenuOpen})}}>
                &#9776; 
              </div>
              <div className="centered-logo">
                <img
                    src="/images/logo-white.png"
                    className="logo-mobile"
                    alt="LOGO"
                  />
              </div>
              {this.state.isMenuOpen && (
                <div className="mobile-menu">
                  <div className="main-dash seller-D sdNavWrpr">
                  {['processor','admin','manager'].indexOf(user.role) !== -1  &&
                  <div
                    // to="/neworder"
                    className={`d-flex mb-4`}
                  >
                    <a className="left-menu-item-create-new" onClick={() => this.setState({isOpen: true, isMenuOpen: false})}>
                      <FontAwesomeIcon className="mr-3" style={{ color: '#367BF5', fontSize: '22px' }} icon={faPlusCircle} />
                      <h1>Create new Order</h1>
                    </a>
                  </div>}
                  <Link
                    to="/processordashboard/pendingOrders"
                    className={`d-flex ${window.location.pathname === "/processordashboard/pendingOrders" &&
                      "active"
                      }`}
                  >
                    <div className="left-menu-item">
                      <img
                        src="/images/icon-pending-order.svg"
                        className="left-menu-item-icon"
                        width="22" height="22"
                      />
                      <div className="left-menu-item-text">
                        <h1 className="">Pending Orders</h1>
                        <span>View all scheduled orders in one place</span>
                      </div>

                    </div>
                    {/* {data.tile_count_loader && data.tile_count_loader ? (
                      <div className="cp-spinner cp-bubble"></div>
                    ) : (
                      <h3 className=" mb-0 cntNumber fw-sm montserrat">
                        {data.procOrdersCount}
                      </h3>
                    )} */}
                  </Link>
                  <Link
                    to="/processordashboard/openOrders"
                    className={`d-flex ${window.location.pathname === "/processordashboard/openOrders" && "active"
                      }`}
                  >
                    <div className="left-menu-item">
                      <img
                        src="/images/icon-open-order.svg"
                        className="left-menu-item-icon"
                        width="22" height="22"
                      />
                      <div className="left-menu-item-text">
                        <h1 className="">Open Orders</h1>
                        <span>View all open orders in one place</span>
                      </div>

                    </div>
                  </Link>
                  {['processor','admin','manager'].indexOf(user.role) !== -1 && <Link
                    to="/allCustomer"
                    className={`d-flex ${window.location.pathname === "/allCustomer" && "active"
                      }`}
                  >
                    <div className="left-menu-item">
                      <img
                        src="/images/icon-all-customer.svg"
                        className="left-menu-item-icon"
                        width="22" height="22"
                      />
                      <div className="left-menu-item-text">
                        <h1 className="">All Customers</h1>
                        <span>View all customers information</span>
                      </div>

                    </div>
                  </Link>}
                  <Link
                    to="/processorCutSheet"
                    className={`d-flex ${window.location.pathname === "/processorCutSheet" && "active"
                      }`}
                  >
                    <div className="left-menu-item">
                      <img
                        src="/images/icon-cut-sheet.svg"
                        className="left-menu-item-icon"
                        width="22" height="22"
                      />
                      <div className="left-menu-item-text">
                        <h1 className="">Cut Sheets</h1>
                        <span>View all cut sheets at one place</span>
                      </div>

                    </div>
                  </Link>
                  {['processor','admin','manager'].indexOf(user.role) !== -1 && <Link
                    to="/reports"
                    className={`d-flex ${window.location.pathname === "/reports" && "active"
                      }`}
                  >
                    <div className="left-menu-item">
                      <img
                        src="/images/icon-report.svg"
                        className="left-menu-item-icon"
                        width="22" height="22"
                      />
                      <div className="left-menu-item-text">
                        <h1 className="">Reports</h1>
                        <span>Get insights into the big data</span>
                      </div>

                    </div>
                  </Link>}
                  <div className="left-menu-profile">
                    <img
                      src={user.avatar != null && user.avatar ? process.env.REACT_APP_ROOT_URL + 'uploads/' + user.avatar : "/images/No_Image_Available.jpg"}
                      className="left-menu-item-icon-img"
                      alt="profile"
                      width='20' height='20'
                    />
                    <h1>{user.first_name}</h1>
                  </div>
                  <hr style={{ width: '80%', marginTop: '0', marginBottom: '0' }}></hr>
                  <Link to='/processorprofile'>
                    <div
                      className={`d-flex left-menu-items ${window.location.pathname === "/processorprofile" && "left-menu-items-active"
                        }`}
                    >
                      <FontAwesomeIcon className="mr-4" style={{ color: '#78909C' }} icon={faGear} width='20' height='20' />
                      <h1>Settings</h1>
                    </div>
                  </Link>
                  <hr style={{ width: '80%', marginTop: '0', marginBottom: '0' }}></hr>
                  {['processor','admin','manager'].indexOf(user.role) !== -1 && <Link to='/processor/users-and-roles'>
                    <div
                      className={`d-flex left-menu-items ${window.location.pathname === "/processor/users-and-roles" && "left-menu-items-active"
                        }`}
                    >
                      <FontAwesomeIcon className="mr-4" style={{ color: '#78909C' }} icon={faUserCircle} width='20' height='20' />
                      <h1>Users and Roles</h1>
                    </div>
                  </Link>}
                  <hr style={{ width: '80%', marginTop: '0', marginBottom: '0' }}></hr>
                  <div className="left-menu-items" 
                    onClick={() => {this.setState({isOpenVersion:true, isMenuOpen: false})}}
                  >
                    <FontAwesomeIcon className="mr-4" style={{ color: '#78909C' }} icon={faRefresh} width='20' height='20' />
                    <h1>Version</h1>
                  </div>
                  <hr style={{ width: '80%', marginTop: '0', marginBottom: '0' }}></hr>
                  <div className="left-menu-items" hidden>
                    <FontAwesomeIcon className="mr-4" style={{ color: '#78909C' }} icon={faInfo} width='20' height='20' />
                    <h1>What's New?</h1>
                  </div>
                  <hr style={{ width: '80%', marginTop: '0', marginBottom: '0' }}></hr>
                  <a href="mailto:support@grazr.net">
                    <div className="left-menu-items" >
                      <FontAwesomeIcon className="mr-4" style={{ color: '#78909C' }} icon={faQuestionCircle} width='20' height='20' />
                      <h1>Customers Support</h1>
                    </div>
                  </a>
                  <hr style={{ width: '80%', marginTop: '0', marginBottom: '0' }}></hr>
                  <div className="left-menu-items-button">
                    <a className="btn-mini" href="#" hidden>Help</a>
                    <Link to='/privacypolicy'>
                      <a className="btn-mini" href="#">Privacy</a>
                    </Link>
                    <Link to='/termscondition'>
                      <a className="btn-mini" href="#">Terms</a>
                    </Link>
                  </div>
                </div>
                </div>)
              }
            </div>
        }
        {this.state.isOpen && (
          <DialogPopup
            title="New Order"
            subtitle={this.state.currentDate}
            maxWidth="lg"
            open={this.state.isOpen}
            onClose={(e) => () => this.setState({isOpen: false})}
            style={{ marginTop: '5rem' }}
          >
            <NewOrder onClose={() => this.setState({isOpen:false})}></NewOrder>
          </DialogPopup>
        )}
        {this.state.isOpenVersion && (
          <DialogPopup
            title="Version"
            subtitle={"v. 2.0"}
            maxWidth="xs"
            open={this.state.isOpenVersion}
            onClose={(e) => () => this.setState({isOpenVersion: false})} // Close the dialog when requested

            style={{ marginTop: '5rem' }}
          >
            {/* <span>ggwp</span> */}
          </DialogPopup>
        )}
        
      </div>
    );
  }
}
function MaterialToggle(props) {
  const size = useWindowSize();
  const [material, setMaterial] = useState();
  useEffect(() => {
    if (size.width >= 992) {
      setMaterial();
    }
  });
  return (
    <>
      <button
        className="navbar-toggler"
        type="button"
        onClick={(e) => setMaterial(true)}
      >
        <span className="navbar-toggler-icon" />
        <NotesIcon style={pStyle} />
      </button>
      <SwipeableDrawer open={material}>
        <ul>
          <li>first lement</li>
          <li>second lement</li>
          <li>thrid lement</li>
        </ul>
      </SwipeableDrawer>
    </>
  );
}
function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
function mapStateToProps(state) {
  const { user, loggedIn, class: classs } = state.login;
  const { msg, msgColor, msgLink } = state.header;
  const { cartData } = state.Cart;

  return { user, loggedIn, msg, msgColor, msgLink, cartData, classs };
}

export default connect(mapStateToProps)(Header);
