import React, { Component } from 'react';
import '../SellerProfile/Sellerprofile.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Processors from '../../../components/Processors/Processors'
import { NOTIFICATION, LOGIN } from '../../../actions';
import SideNav from '../../Sidebar/Sidebar';
import Service from '../../../Service/Service'
import US_Cities from '../util/cities.js';
import US_states from '../util/states';

//import { connect } from '../../../Header.js';
class Buyerprofile extends Component {
  
  state = {
    role: {
      data: "",
      required: true,
      errorMsg: "Required"
    },
    first_name: {
      data: "",
      required: true,
      errorMsg: "Required"
    },
    last_name: {
      data: "",
      required: true,
      errorMsg: "Required"
    },
    email: {
      data: "",
      required: false,
      errorMsg: "Required"
    },
    phone: {
      data: "",
      required: true,
      errorMsg: "Required"
    },
    country: {
      data: '',
      //required: true,
      errorMsg: "Country is Required"
    },
    state: {
      data: [],
      required: true,
      errorMsg: "State is Required"
    },

    city: {
      data: [],
      selected_city:'',
      required: true,
      errorMsg: "City is Required"
    },

    zip: {
      data: [],
      required: true,
      errorMsg: "ZIP is Required"
    },
    current_password: {
      data: "",
      role: "all",
      required: false,
      errorMsg: "Required"
    },
    new_password: {
      data: "",
      role: "all",
      required: false,
      errorMsg: "Required"
    },
    confirm_new_password: {
      data: "",
      role: "all",
      required: false,
      errorMsg: "Required"
    },
    work_loc: {
      data: [],
      role: "all",
      required: false,
      errorMsg: "Required"
    },
    passwordflag: false,
    avatar: "",
    error: "",
    required: {},
    formError: {},
    showModal:false,
    imgSrc:"",
    states_drop: [],
    cities_drop: [],
    other_city:true,
    user_update_loader:false,
    noprocessor:false,
    nosave:false,
    savebtnstage:false
  }

  // selectCountry(val) {
  //   if (this.state.formError["country"]) { delete this.state.formError["country"] }
  //   var prestate = this.state.country;
  //   prestate.data = val;
  //   this.state.state.data = ""
  //   this.setState({
  //     country: prestate,
  //     formError: this.state.formError
  //   });
  // }

  selectRegion(val) {
    if (this.state.formError["state"]) { delete this.state.formError["state"] }
    var prestate = this.state.state;
    prestate.data = val;

    this.setState({
      state: prestate,
      formError: this.state.formError
    });
  }
  handleValidation() {
    let self = this;
    Object.keys(this.state).map(function (key) {
     
      if (self.state[key] && self.state[key].required && (self.state[key].data === "" || self.state[key].data == [])) {
        self.state.formError[key] = self.state[key].errorMsg ? self.state[key].errorMsg : "Required!";
      }
      if (self.state.passwordflag === true && self.state[key].data === "") {
        self.state.formError[key] = self.state[key].errorMsg ? self.state[key].errorMsg : "Required!";
      }
      if (key == "new_password" && self.state[key].data.length > 0 && self.state[key].data.length < 6) {
        self.state.formError[key] = "The minimum password length is 6"
      }

      
      
      self.setState({
        formError: self.state.formError
      })
    });
  }
  passwordOnChange() {
    if (this.state.passwordflag === true) {
      this.state.current_password.data = "";
      this.state.new_password.data = "";
      this.state.confirm_new_password.data = "";
      this.state.formError["new_password"] = "";
      this.state.formError["current_password"] = "";
      this.state.formError["confirm_new_password"] = "";
      this.setState({
        passwordflag: !this.state.passwordflag,
        current_password: this.state.current_password,
        new_password: this.state.new_password,
        confirm_new_password: this.state.confirm_new_password
      })
    }
    else
      this.setState({ passwordflag: !this.state.passwordflag })
  }

  checkValidation = () => event => {
    
    this.handleValidation();
  }

  handleChange = name => event => {
    let phoneregex = /^([0-9]{10})$/;

    if (this.state.formError[name]) { delete this.state.formError[name] }
    let preState = this.state[name];
    if (name == "new_password" && event.target.value != "" && event.target.value == this.state.current_password.data) {
      this.state.formError[name] = "New password should be different with current passord!";
    }
    if (name == "current_password" && event.target.value != "" && event.target.value == this.state.new_password.data) {
      this.state.formError["new_password"] = "New password should be different with current passord!";
    }
    if (name == "confirm_new_password" && event.target.value != "" && event.target.value != this.state.new_password.data) {
      this.state.formError[name] = "Password and Confirmation password do not match!";
    }
    if (name === "phone" && event.target.value !== "" && !phoneregex.test(event.target.value)) {
      this.state.formError[name] = "Phone number is not correct";
    }

    if(name == "state") {
      let state_selected = event.target.value;
      var clearcity = {};
      clearcity.data ='';
      clearcity.required = true
      var clearzip ={};
      clearzip.data = ''
      clearzip.required = true
      this.setState({ region: state_selected,
      zip:clearzip,
      city : clearcity});
      let cities_drop = [];
      US_Cities.filter(function(elem){
        // console.log("elem --> " + JSON.stringify(elem));
        // if(Object.keys(elem)[0] == state_selected) {
        //   cities_drop.push(Object.values(elem)[0]);
        // };
         if(Object.keys(elem)[0] == state_selected) {
              let s = Object.values(elem)[0];
              let test = [];
              s.map(function(v){
                return test.push(v.charAt(0).toUpperCase() + v.slice(1).toLowerCase());
              });
          //cities_drop.push(Object.values(elem)[0]);
          cities_drop.push(test);
        };
      });

      if (this.state.formError["state"]) { delete this.state.formError["state"] }
      var prestate = this.state.state;
      prestate.data = state_selected;

      this.setState({
        state: prestate,
        cities_drop: cities_drop[0],
        formError: this.state.formError,
        //other_city:false
      });

    }

    if(name =='city1'){
      let state_selected = event.target.value;
      let{city} = this.state;
      //let text_array =[];
      console.log("ciyt cale  fetchd ---",city)
      //if (state_selected=='Others'){
        //console.log("oters reached --",typeof city.data);
        //text_array.push("enter your city name here")
        //city.data="enter your city name here";
        city.data=event.target.value;
        
        
        this.setState({
          city:city,
          //other_city:false
        },()=>{console.log("ciyt cale city value --- ",this.state.city)})
      //}else{
        //this.setState({
          //city:city,
         // other_city:true
     //   })
      //}
      this.otherCity.focus();
    
    }

    if(name =='city'){
      let state_selected = event.target.value;
      let{city} = this.state;
      //let text_array =[];
      console.log("ciyt cale  fetchd ---",city)
      if (state_selected=='Others'){
        console.log("oters reached --",typeof city.data);
        //text_array.push("enter your city name here")
        city.data="enter your city name here";
        //this.otherCity.focus();
        var clearzip ={};
        clearzip.data = '';
        clearzip.required = true;
        this.setState({
          city:city,
          other_city:false,
          zip : clearzip
        },()=>{console.log("ciyt cale city value --- ",this.state.city)})
      }else{
        var clearzip ={};
        clearzip.data = '';
        clearzip.required = true
        this.setState({
          city:city,
          other_city:true,
          zip : clearzip
        })
      }
    
    }

    if(name == "zip") {
      const zipcodes = require('zipcodes');
      var dist = {};
      dist = zipcodes.lookup(event.target.value);
      var result=[];
      if(dist){
        result = Object.values(dist);
        console.log("restu l",result[3])
      }
      
      console.log("dist -----------------> ", JSON.stringify(dist));
      var dist1 = JSON.stringify(dist);
      //console.log("dist 1 ",dist.test.zip)
      //console.log("dist  ",dist[0]['zip'])
      console.log("city ",this.state.city.data);
      if(dist==undefined || this.state.zip.data=='') {
        this.state.formError[name] = "Please enter valid US Zipcode."
      }else if(dist && ((this.state.city.data).toString().toLowerCase().replace(" ","") != (result[3]).toString().toLowerCase().replace(" ",""))){
        console.log("err")
        this.state.formError[name] = "City name & pincode is not match"
      }
      // if(this.state.city != dist.city){
      //   this.state.formError[name] = "City name & you enter pincode is not match"
      // }

    }
    if(name!="city1"){
    this.state[name].data = event.target.value
    this.setState({
      [name]: preState,
      formError: this.state.formError 
    });
    }
    // else{
    //   this.state['city'].data = event.target.value
    // this.setState({
    //   ['city']: preState,
    //   formError: this.state.formError 
    // });
    // }

  };

  componentDidMount(){
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;
    let img_url = process.env.REACT_APP_IMAGE_URL;
    let self = this;
    const data = new FormData();
    data.append('user_id',this.props.user.id);
      axios.post(url+'dashboard/getUserDetails',data).then(function(response){
      if (response.data.status === "no") {
        //console.log("response no for get usr details");
        self.setState({
            error: response.data.message
        })
        self.props.dispatch(NOTIFICATION({
            error: response.data.message
        }))
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }))
        }, 3000);
        return false;
    }
    let user_profile_data = response && response.data && response.data.data ? JSON.parse(atob(response.data.data.split('.')[1])) : '';
    response.data.data = user_profile_data;
        if(response.data.status === 'yes'){
          //console.log("response no for get usr details");
            Object.keys(response.data.data).map((ele,ind)=>{
              //console.log("ele value ",ele);
                 if(self.state[ele]){
                    self.state[ele].data = (response.data.data[ele]) ? response.data.data[ele] : "";
                    self.setState(
                        {[ele] : self.state[ele]}
                        ) 
                 }else if(ele==="avatar"){
                    self.setState(
                        {
                          imgSrc: process.env.REACT_APP_ROOT_URL + "uploads/" + response.data.data[ele],
                          avatar : response.data.data[ele], resp:true 
                        } ,
                       
                        ) 
                 }
                 if(ele === 'li_id'){
                  self.state['work_loc'].data = (response.data.data['li_id']) ? response.data.data['li_id'].split(',') : [];
                }
                
            })
        }
        self.setState({
          error: ""
      })
        if (response.data.status === "no")  {
          self.setState({
              error: response.data.message || ""
          })
          return false;
      }  
    })

    let states_drop = [];
    US_states.filter(function(elem){
      // if(Object.keys(elem)[0] == val) {
         states_drop.push(elem.name);
         // };
    });

    this.setState({
      states_drop: states_drop
    });


  }

  onChange(type){
    
    if (type === "img") {
      let files = this.refs.file.files;

        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onloadend = function (e) {
            files[0]['imgsrc'] = reader.result;
           
            this.state.imgSrc = reader.result;
            this.state.avatar = files[0];
            this.setState({
                imgSrc: this.state.imgSrc,
                avatar: this.state.avatar
            })
            
        }.bind(this);
   
  }
}

  formSubmit(e) {
    e.preventDefault();
    
    let self = this;
    this.handleValidation();
    if ((Object.keys(this.state.formError)).length > 0) {
      if(self.state.formError["first_name"] || self.state.formError["last_name"]||self.state.formError["email"]||
      self.state.formError["phone"] || self.state.formError["state"]||self.state.formError["zip"]||self.state.formError["city"])
      window.scrollTo(0, 80);
      else
      window.scrollTo(0, 400);
      return false;
    }
    self.setState({
      user_update_loader:true
    })
    const data = new FormData()
    data.append('user_id', this.props.user.id);
    if ((Object.keys(self.state.formError)).length > 0) {
      return false;
    }
    Object.keys(self.state).map(function (key) {
      if(key === 'country'){
        return (self.state[key]) ? data.append('country', "United States") : data.append('country', "United States");
      }

      if (key === 'passwordflag')
        return (self.state[key]) ? data.append(key, self.state.passwordflag) : false;
      if(key === 'work_loc')
        return (self.state[key]) ? data.append('li_id[]', self.state.work_loc.data) : [];
      if(key ==='phone')
        return (self.state[key])?data.append('phone',(self.state.phone.data).indexOf("+1")==-1?"+1"+self.state.phone.data:self.state.phone.data):'';

      return (self.state[key]) ? data.append(key, self.state[key].data) : '';
    });
    if(self.state.avatar)
    data.append('user_profile_image', self.state.avatar);

    // else
    // data.append('user_profile_image', self.state.imgSrc.substring(self.state.imgSrc.lastIndexOf('/')+1))

    let url = process.env.REACT_APP_BASE_URL;
    if (self.state.passwordflag) {
      axios.post(url + 'profile/updatepassword_in_profile', data)
        .then(function (resp) {
          if (resp.data.status === "no") {
            self.setState({
              user_update_loader:false
            })
            self.props.dispatch(NOTIFICATION({ msg: resp.data.message, msgColor: "#FAD5BD" }))
            setTimeout(() => {
              self.props.dispatch(NOTIFICATION({ msg: "" }))
            }, 2000);
          }
          if (resp.data.status === "yes") {
            axios.post(url + 'profile/updateUserProfile', data)
              .then(function (response) {
                if (response.data.status === "no") {
                  self.setState({
                    user_update_loader:false
                  })
                  self.props.dispatch(NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" }))
                 
                  setTimeout(() => {
                    self.props.dispatch(NOTIFICATION({ msg: "" }))
                  }, 2000);
                  return false;
                }

                if (response.data.status === "yes") {
                  if (response.data.data && response.data.data.token){
                    let userdata = self.props.user;
                    userdata.user_token = response.data.data.token;
                    self.props.dispatch(LOGIN(userdata))
                    axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.data.token;
                  }
                  self.setState({
                    user_update_loader:false
                  })
                  self.props.dispatch(NOTIFICATION({ msg: 'Password and ' + response.data.message }))
                  window.location.reload(false)
                  window.scrollTo(0, 80);
                  setTimeout(() => {
                    self.props.dispatch(NOTIFICATION({ msg: "" }))
                  }, 2000);
                }
              })
              .catch(function (error) {
                self.setState({
                  error: "Try later!"
                })
                return false;
              });
          }
        })
    }
    else {
      axios.post(url + 'profile/updateUserProfile', data)
        .then(function (response) {
          if (response.data.status === "no") {
            self.setState({
              user_update_loader:false
            })
            self.props.dispatch(NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" }))
            setTimeout(() => {
              self.props.dispatch(NOTIFICATION({ msg: "" }))
            }, 2000);
            return false;
          }

          if (response.data.status === "yes") {
            self.setState({
              user_update_loader:false
            })
            let user_det = self.props.user;
            user_det.avatar = (response.data.data && response.data.data.profile_image) ? response.data.data.profile_image : '';
            user_det.first_name = self.state.first_name ? self.state.first_name.data : '';
            user_det.last_name = self.state.last_name ? self.state.last_name.data : '';
           // user_det.avatar = self.state.avatar.data;
            user_det.aboutme = self.state.aboutme ? self.state.aboutme.data : '';
            user_det.country = self.state.country ? self.state.country.data : '';
            user_det.state = self.state.state ? self.state.state.data : '';
            user_det.city = self.state.city ? self.state.city.data : '';
            user_det.zip = self.state.zip ? self.state.zip.data : '';
            user_det.phone = self.state.phone ? self.state.phone.data : '';
            if (response.data.data && response.data.data.token){
              user_det.user_token = response.data.data.token;
              axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.data.token;
            }
            self.props.dispatch(LOGIN(user_det))
            localStorage.setItem('user', '');
            localStorage.setItem('user', JSON.stringify(user_det));
            self.props.dispatch(NOTIFICATION({ msg: response.data.message }))
            setTimeout(() => {
              self.props.dispatch(NOTIFICATION({ msg: "" }))
            }, 2000);
          }
        })
        .catch(function (error) {
          console.log(error)
          self.setState({
            error: "Try later!"
          })
          return false;
        });
    }
  }
  openModal = (event,save,processors) => e=> {
    e.preventDefault();
    console.log("processors list form getrole",processors)
    let processorsFilter=[];
    if(processors&&processors.length>0){
      processorsFilter=processors.map(itemY => { return String(itemY.id); });
    }
    console.log("processors list form getrole",processorsFilter)
    let {user:{user_token},dispatch} = this.props;
    let {work_loc:{data:work_locc}} = this.state;
    console.log("processors list form getrole",work_locc);
    let work_loccFilter=[];
    if(processors&&processors.length>0){
      work_loccFilter= work_locc.filter(f => {return processorsFilter.indexOf(f) >= 0;});
    }
    // let work_loccFilter = work_locc.filter(f => processorsFilter.includes(String(f)));
    
    // var arr = [1,2,3,4],
    // brr = [2,4],
    // res = arr.filter(f => !brr.includes(f));
    console.log("res fileter ",work_loccFilter);
    
    if(event){
      if(this.state.zip.data!="" && this.state.zip.data!=undefined){
        document.body.classList.add('modal-open');
      } else {
        this.props.dispatch(NOTIFICATION({ msg: "Please update your zipcode and choose preferred processor", msgColor: "#FAD5BD" }))
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }))
        }, 4000);
        return false;
      }
    }
    else 
        if (work_loccFilter.length==0&&processors!=''){
          console.log("filer log ")
            this.setState({
            //noprocessor:true
            })
            //return false
        }
        if(this.state.nosave&&!save){
          console.log("nod ave if")
          this.setState({
            //noprocessor:true
            })
          //return false
        }
        document.body.classList.remove('modal-open');

        if(save) {
          new Service(user_token).post('/profile/saveProcessor',{data:work_loccFilter}).then(({status,message,data})=>{
            this.setState({
              nosave:false
            })
            let userdata = this.props.user;
            userdata.li_id = work_loccFilter.join(',');
            localStorage.setItem('user', JSON.stringify(userdata));
            dispatch(LOGIN(userdata))
            dispatch(NOTIFICATION({msg:message}))
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }))
            }, 3000);
          }).catch(err=>{console.log(err)})
        }
        
      
    this.setState({ showModal: event });
  }
  changeChecked = (checkname) => e => {
    e.preventDefault();
    let checked = e.target.checked;
    let value = e.target.value;
    let { work_loc, work_loc: { data: worklocArr }, formError } = this.state;
    //console.log("checked, vlaue,work_loc",checked,value,work_loc['data'])
    if (formError['work_loc'])
        delete formError['work_loc']
    if (checked) {
        if (worklocArr.indexOf(value) < 0)
            worklocArr.push(value)
    } else {
        if (worklocArr.indexOf(value) > -1)
            worklocArr.splice(worklocArr.indexOf(value), 1)
    }
    work_loc['data'] = worklocArr;
    console.log("work locc data ",work_loc['data'])
    this.setState({
        work_loc: work_loc,
        noprocessor:false,
        //nosave:true
    });
    if(work_loc['data'].length>0){
      this.setState({
        savebtnstage:true
      },()=>{console.log("this.state.save bt",this.state.savebtnstage)})
    }else{
      this.setState({
        savebtnstage:false
      },()=>{console.log("this.state.save bt",this.state.savebtnstage)})
    }

    


  }
  saveTheProcessor = () =>{
   // console.log(this.state)
  }
  render() {
    
    let user_id = 0
    let role = 'buyer'
    let from_profile = "active";
    let {
          showModal,
          work_loc:{data:work_locc},
        } = this.state
    let {user} = this.props;
    let json_token = ''
    if(user){
      json_token = user.user_token
      user_id = user.id
      role = user.role
    }
    //console.log("work_locc list ",work_locc)
    return (
      <div className="G-zr top-h">
        <section className="px-5 pb-5">
        <SideNav sideMenu='false'/>
          <div className="row">
          <div className="col-md-3">
          
            <SideNav activeMenu='My Profile'/>
          </div>
            <div className="col-md-8">
              <div className="Dash-right">


                <div className="tab-content" id="nav-tabContent">

                  <div className="row">
                    <div className="col-md-3">
                      <div className="pro-file d-flex justify-content-center align-items-center flex-wrap">
                        <figure className="mb-3">
                          {/* <img src="/images/profile.svg" className="img-fluid" alt="profile" /> */}
                          <img src={Object.keys(this.state.avatar).length === 0 ? "/images/profile.svg" : `${this.state.imgSrc}`} alt="profile" />
                        </figure>
                        <div className='up-load'>
                        <button className="btn btn-bwn fs-14 text-white text-uppercase">Upload</button>
                        <input
                          ref="file"
                          type="file"
                          accept="application/image" 
                          onChange={this.onChange.bind(this, "img")} /></div>                        
                      </div>
                    </div>
                    <div className="col-md-9">
                      <form className="progress-form form-col" onSubmit={this.formSubmit.bind(this)}>
                      <div className="row">
                            <div className="col pr-0">
                            <div className="b_pro-proc d-flex justify-content-between align-items-center">
                                <h3>Personal Details</h3>
                                <a href="#" className="btn btn-bwn cursor" data-toggle="modal" data-target="#select_proc" onClick={this.openModal(true)}>Preferred processors</a>
                            </div>
                        </div>
                        </div>
                      <input type hidden name="user_id" value={user_id}></input>
                      <input type hidden name="role" value={role}></input>
                        <div className="row ">
                          <div className="form-group position-relative mb-5 col pr-0">
                            <input type="text" className="form-control D-input" placeholder="Enter first name"
                              value={this.state.first_name.data}
                              onChange={this.handleChange('first_name')}
                              onfocusout={this.checkValidation()} />
                            <p className="position-absolute t-t m-0">First Name</p>
                            <span style={{ color: "red" }}>{this.state.formError["first_name"]}</span>
                          </div>
                         
                          <div className="form-group position-relative mb-5 col pr-0">
                            <input type="text" className="form-control D-input" placeholder="Enter last name"
                              value={this.state.last_name.data}
                              onChange={this.handleChange('last_name')} />
                            <p className="position-absolute t-t m-0">Last Name</p>
                            <span style={{ color: "red" }}>{this.state.formError["last_name"]}</span>
                          </div>
                         
                        </div>
                        <div className="row">
                          <div className="form-group position-relative mb-5 col pr-0">
                            <input disabled type="text" className="form-control D-input" placeholder="Enter email address"
                              value={this.state.email.data} />
                            <p className="position-absolute t-t m-0">Email address</p>
                          </div>
                          <div className="form-group position-relative mb-5 col pr-0">
                            <input type="tel" className="form-control D-input" maxlength="10" placeholder="Enter mobile number"
                              value={(this.state.phone.data).replace('+1','')}
                              //value={this.state.phone.data}
                              onChange={this.handleChange('phone')} />
                            <p className="position-absolute t-t m-0">Mobile</p>
                            <span style={{ color: "red" }}>{this.state.formError["phone"]}</span>
                          </div>
                         
                        </div>
                        <div className="row">
                          <div className="form-group position-relative mb-5 col select-wrapper pr-0">
                            <select name="" className="form-control D-input">
                              <option value>United States</option>
                            </select>
                            {/* <CountryDropdown classes="form-control D-input"
                              value={this.state.country.data}
                              onChange={(val) => this.selectCountry(val)} /> */}
                            <p className="position-absolute t-t m-0">Country</p>
                            {/* <span style={{ color: "red" }}>{this.state.formError["country"]}</span> */}
                          </div>
                         
                          <div className="form-group position-relative mb-5 col select-wrapper pr-0">
                            {/* <select name="" className="form-control D-input">
                              <option value>Virginia</option>
                            </select> */}
                            {/* <RegionDropdown classes="form-control D-input"
                              country={this.state.country.data}
                              value={this.state.state.data}
                              onChange={(val) => this.selectRegion(val)} /> */}
                              <select type="text" className="form-control D-input " value={this.state.state.data} onChange={this.handleChange('state')}>
                                  <option>Select State</option>
                                  {this.state.states_drop.length > 0 ?this.state.states_drop.map((state,i) => {
                                    return <option value={state}>{state}</option>
                                  }):  <option value={this.state.state.data}>{this.state.state.data}</option>

                                  }
                                  
                                  </select> 
                            <p className="position-absolute t-t m-0">State</p>
                            <span style={{ color: "red" }}>{this.state.formError["state"]}</span>
                          </div>
                          
                        </div>
                        <div className="row">
                          <div className="form-group position-relative coll mb-5 col-md-6 pr-0">
                            {/* <select name="" className="form-control D-input">
                              <option value>Farmville</option>
                            </select> */}
                            {/* <input type="text" className="form-control D-input" placeholder="Enter City" value={this.state.city.data}
                              onChange={this.handleChange('city')} /> */}
                            <p className="position-absolute t-t m-0">City</p>
                            {/* {this.state.other_city==false && (this.state.cities_drop && this.state.cities_drop.length > 0) ?  */}
                            {/* {(this.state.cities_drop && this.state.cities_drop.length > 0)   */}
                            {/* <select type="text" className="form-control D-input " value={this.state.city.data} onChange={this.handleChange('city')}>
                                <option>Select City</option>
                                  {this.state.cities_drop && this.state.cities_drop.length > 0 ?this.state.cities_drop.map((city,i) => {
                                    return <option value={city}>{city}</option>
                                  }): <><option value={this.state.city.data}>{this.state.city.data}</option></>}
                                  
                              </select>  */}
                              <input type="text" className="form-control D-input" placeholder="Enter City" value={this.state.city.data}
                                  onChange={this.handleChange('city')} />
                              
                                <span style={{ color: "red" }}>{this.state.formError["city"]}</span>
                          </div>
                          {/* <input type="text" className="form-control D-input" placeholder="Enter City" value={this.state.city.data} 
                          onChange={this.handleChange('city')} />}*/}

                          <div className="row" hidden={this.state.other_city}>
                            <div className="form-group position-relative mb-5 col pr-0">
                              <input  type="text" className="form-control D-input" placeholder="Enter your city"
                                value={this.state.city.data=='Others'?"":this.state.city.data} 
                                //value={this.state.city.data}
                                onChange={this.handleChange('city1')}  ref={elem => (this.otherCity = elem)} />
                              <p className="position-absolute t-t m-0">Your City</p>
                            </div>
                          </div>
                          
                          
                          {/* <div className="form-group position-relative coll mb-5 col-md-6 pr-0"> */}
                              <div className="form-group position-relative mb-5 col-md-4 coll pr-0">
                              <p className="position-absolute t-t m-0">Zip Code</p>
                              <input type="tel" className="form-control D-input numberfield" placeholder="Enter zip Code"
                                value={this.state.zip.data}
                                onChange={this.handleChange('zip')} />
                              <p className="position-absolute t-t m-0">Zip Code</p>
                              <span style={{ color: "red" }}>{this.state.formError["zip"]}</span>
                            </div>
                          

                          

                          
                          
                        

                        </div>
                        <div className="change-password">
                          <div className="custom-control custom-switch  mb-5 pr-0">
                            <input type="checkbox" className="custom-control-input" value={this.state.passwordflag} id="customSwitch1" />
                            <label className="custom-control-label fs-18 fw-sm" onClick={this.passwordOnChange.bind(this)} htmlFor="customSwitch1">Change Password</label>
                          </div>
                          <div className="row">
                            <div className="form-group position-relative coll mb-5 col-md-6 pr-0">
                              <input type="password" className="form-control D-input" placeholder="Enter current password" disabled={(this.state.passwordflag) ? "" : "disabled"}
                                value={this.state.current_password.data}
                                onChange={this.handleChange('current_password')} />
                              <p className="position-absolute t-t m-0">Current Password</p>
                              <span style={{ color: "red" }}>{this.state.formError["current_password"]}</span>

                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group position-relative mb-5 col pr-0">
                              <input type="password" className="form-control D-input" placeholder="Enter new password" disabled={(this.state.passwordflag) ? "" : "disabled"}
                                value={this.state.new_password.data}
                                onChange={this.handleChange('new_password')} />
                              <p className="position-absolute t-t m-0">New Password</p>
                              <span style={{ color: "red" }}>{this.state.formError["new_password"]}</span>
                            </div>
                            <div className="form-group position-relative coll mb-5 col-md-6 pr-0">
                              <input type="password" className="form-control D-input" placeholder="Enter confirm password" disabled={(this.state.passwordflag) ? "" : "disabled"}
                                value={this.state.confirm_new_password.data}
                                onChange={this.handleChange('confirm_new_password')} />
                              <p className="position-absolute t-t m-0">Confirm Password</p>
                              <span style={{ color: "red" }}>{this.state.formError["confirm_new_password"]}</span>
                            </div>
                          </div>
                        </div>
                        {this.state.error ?
                          <div className="alert alert-success" role="alert">
                            {this.state.error}
                          </div>
                          : ''}
                        <div className="text-center">
                          {this.state.user_update_loader?<div className="loader"></div>:
                          <button type="submit" className="btn btn-green ">Save</button>}
                        </div>
                      </form>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </section>
        {showModal ? <Processors 
                        showModal={showModal}
                        closeModal = {this.openModal}
                        json_token = {json_token}
                        product={0}
                        fallback={this.setProcessor}
                        saveButtonToggle = {false}
                        checkToggle = {true}
                        fallback = {this.changeChecked}
                        work_locc = {work_locc}
                        saveThisProcessor = {true}
                        noprocessor={this.state.noprocessor}
                        zip={this.state.zip}
                        savebtnstage={true}
                        />:''}
      </div>
      
      
    )
  }
}

function mapStateToProps(state) {
  const { user, loggedIn } = state.login;
  return { user, loggedIn };
}

export default connect(mapStateToProps)(Buyerprofile);
