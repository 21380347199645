import openSocket from "socket.io-client";
//let url = process.env.REACT_APP_BASE_URL ? "https://forwardapi.auctionsoftware.com/" : "http://localhost:2001/";
let url = process.env.REACT_APP_ROOT_URL;
const socket = openSocket(url, { transports: ["websocket"] });
function InsertChatMessage(data) {
  console.log("chat msge data at socket", data);
  socket.emit("insertMessages", data);
  console.log("test for emit fucntion");
}


function insertMessageListen(cb) {
  console.log("insertg -------------");
  socket.on("insertMessages_result", (result_data) => {
    console.log("log from socket jhs");
    cb(result_data);
  });
}

function login_useridListen(cb) {
  socket.on("login_userid", (result_data) => {
    console.log("login user id");
    cb(result_data);
  });
}

function currentServerTime(cb) {
  socket.on("sliservertime", (result_time) => {
    cb(result_time);
  });
}

let EmitQtyClicked = (name) => {
  console.log("Emitted click");
  socket.emit("qtyclicked", name);
};
function CaptureQtyClicked(cb) {
  console.log("lisining captured click");
  socket.on("qtyclickedl", (name) => {
    cb(name);
  });
}
function AvailabelQuntity(cb){
  socket.on("available_qty",(data)=>{
    console.log("socket call",data)
    cb(data);
  });
}
function removefromcart(cb){
  socket.on("remove_item_from_cart",(data)=>{
    console.log("socet data++",data)
    cb(data);
  });
}

export {
  socket,
  AvailabelQuntity,
  removefromcart,
  InsertChatMessage,
  insertMessageListen,
  login_useridListen,
  currentServerTime,
  EmitQtyClicked,
  CaptureQtyClicked,
};
