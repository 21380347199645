import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import PushNotification from "../../components/PushNotification/PushNotification";
// import { StickyContainer, Sticky } from 'react-sticky';
import Service from "../../Service/Service";
import { NOTIFICATION } from "../../actions";


class Notification extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    snackBarClose = (e) => {
        this.props.dispatch(NOTIFICATION({ msg: "" }));
    };

    // leftmenu end
    render() {
        let { user, loggedIn, msg, msgLink, msgColor, classs, cartData } =
            this.props;

        return (
            <>
                {msg && (
                    <PushNotification
                        msgColor={msgColor}
                        msgLink={msgLink}
                        msg={msg}
                        snackBarClose={this.snackBarClose}
                    />
                )} </>);
    }
}

function mapStateToProps(state) {
    const { user, loggedIn, class: classs } = state.login;
    const { msg, msgColor, msgLink } = state.header;

    return { user, loggedIn, msg, msgColor, msgLink, classs };
}

export default connect(mapStateToProps)(Notification);
