import React from "react";
import Slider from "react-slick";

const LoginSlider = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 7000,
    cssEase: "linear",
  };

  return (
    <div>
      <Slider {...settings}>
        <div>
          <figure className="m-0 d-none d-md-block">
            <img
              src="./images/slider/image_1.jpg"
              className="img-fluid"
              alt="banner"
            />
          </figure>
        </div>
        <div>
          <figure className="m-0 d-none d-md-block">
            <img
              src="./images/slider/image_2.jpg"
              className="img-fluid"
              alt="banner"
            />
          </figure>
        </div>
        <div>
          <figure className="m-0 d-none d-md-block">
            <img
              src="./images/slider/image_3.jpg"
              className="img-fluid"
              alt="banner"
            />
          </figure>
        </div>
        <div>
          <figure className="m-0 d-none d-md-block">
            <img
              src="./images/slider/image_4.jpg"
              className="img-fluid"
              alt="banner"
            />
          </figure>
        </div>
      </Slider>
    </div>
  );
};

export default LoginSlider;
