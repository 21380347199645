// Core Imports
import React from "react";
// Reduc Imports
import { connect } from "react-redux";
// Third Parties imports
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { currentServerTime } from "../../../util/socket";
import axios from "axios";

import moment from "moment";

// Component Imports
import "./CalenderProcessor.styles.css";
import "./CalendarSetup.css";
import Service from "../../../Service/Service";
import AddEvent from "../../../components/AddEventsPopup/Add-Events-Popup.component";

class MyAgendaMini extends React.Component {
  calref = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      dropOff: null,
      load: false,
      eventsArray1: [],
      eventsArray: [],
      selectedDate: "",
      addEventPopup: false,
      isDateClicked: true,
      title: "",
      cid: 0,
      uid: this.props.user.id,
      categoryWiseEvents: [],
      extendedprops: {},
      chkprops: "",
      availablecategory: [],
      hiddendays: [],
      availabledays: [0, 1, 2, 3, 4, 5, 6],
      eventClickedCAtegory: "",
      currenteventdate: Date,
      needarg: {},
      currentServerTime: props.currentServerTime,
      cal_loader: true,
      rerender: false,
      eventData: [],
      slotData: [],
      pastSlotData: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searching !== this.props.searching) {
      this.setState(
        {
          rerender: true,
        },
        () => {
          this.setState({
            rerender: false,
          });
        }
      );
    }
  }

  handleDateClick = (info) => {

    // this.props.setDropOff(moment(info.dateStr).format("YYYY-MM-DD"))
    // this.props.setKillDate(moment(info.dateStr).add(2, 'days').format("YYYY-MM-DD"))
  };

  viewRender = (view, element) => {};

  handleEvent = (arg) => {
    this.setState({
      dropOff: moment(arg.event.start).format("YYYY-MM-DD"),
    })
    this.props.setDropOff(moment(arg.event.start).format("YYYY-MM-DD"))
    this.props.setKillDate(moment(arg.event.start).add(2, 'days').format("YYYY-MM-DD"))
   
  };

  assign_refetchEvents = (refresh_status) => {
    if (refresh_status) {
      window.location.reload();
    }
  };

  assign_events = async (date) => {
      if(!date){
        var date = new Date();
      }
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var event = []

      const data = {
        month: month,
        year: year
      };

      await new Promise((promised) => {
        axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.user.user_token;
        let url = process.env.REACT_APP_BASE_URL;

        axios.post(url+"calendar/get_all_date_slot", data)
        .then(async ({ status, message, data }) => {
          event = await Promise.all(data.data.map((e, index) => {
            let rest = e.available - e.used;
            return {
              title: e.title,
              date: moment(e.date).format("YYYY-MM-DD"),
              backgroundColor: rest > 0 ? "#3C923F" : "red",
              textColor:"#ffffff !important",
              pointer: "cursor"
            }
          }));
          this.setState({cal_loader:false})
          promised();
        });
    })
    this.setState({
      eventData: event,
    });
  };
  
  dayrender = (dayRenderInfo) => {
    //  let {availablecategory} = this.state;
    //  console.log(availablecategory,'dar rener',this.state)
    // //  console.log(availablecategory)
    //   if(availablecategory && availablecategory.length === 0)
    //       dayRenderInfo.el.style.background='grey'
  };

  showSlot = (data) => {
    this.setState({
      currenteventdate: moment(data.date_added.replace(/\T.+/g, "")),
      addEventPopup: true,
      needarg: {
        category: data.solution,
        acount: 0,
        process_option: data.process_option,
      },
    });
  };

  handleDateChanges = (payload) => {
    let days = 10;
    let date = payload.start;
    date.setDate(date.getDate() + days);
    this.assign_events(date);
    // console.log('month changed', payload.start )  
    // console.log('month changed', date)
  }

  componentDidMount() {
    currentServerTime((data) => {
      this.setState({
        currentServerTime: data,
      });
    });
    this.assign_events()
  }
  render() {
    let {
      rerender,
      addEventPopup,
      selectedDate,
      eventsArray,
      isDateClicked,
      title,
      cid,
      categoryWiseEvents,
      extendedProps,
      availablecategory,
      hiddendays,
      eventClickedCAtegory,
    } = this.state;
    let {
      user: { user_token },
      user: { id: uid },
      user: { role: urole },
      currentPopupData,
    } = this.props;

    return (
      <>
        {this.state.cal_loader ? (
          <div className="loader"></div>
        ) : (
          !rerender && (
            <div className="agendaCnt dropoff-calendar">
              <FullCalendar
                dateClick={this.handleDateClick}
                hiddenDays={hiddendays}
                plugins={[dayGridPlugin, interactionPlugin]}
                eventClick={this.handleEvent}
                viewRender={this.viewRender}
                events={this.state.eventData}
                rerenderEvents={this.assign_refetchEvents}
                ref={this.calref}
                dayRender={this.dayrender}
                dayCellClassNames={(args) => {
                  if(moment(args.date).format("YYYY-MM-DD") == this.state.dropOff){
                    return ['selected-dropoff']
                  }else return [];
                }}
                header={{
                  left: "",
                  center: "",
                  right: "today prev,next  ",
                }}
                // ** DO NOT REMOVE **
                //  - TODO -> Set Default event color and event limit
                // eventColor= '#378006'
                // eventLimit={ true} // for all non-TimeGrid views
                timezone={"UTC"}
                eventOrder={"start"}
                datesSet={this.handleDateChanges}
              />
            
            </div>
          )
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    login: { loggedIn },
    login: { user },
  } = state;
  return { loggedIn, user };
};
export default connect(mapStateToProps)(MyAgendaMini);
