import React,{Component} from 'react'
import axios from 'axios';
import './Processors.css';
export class SellerPopup extends Component{
    state = {
        sellers: [],
        type:'all',
        role:'seller',
        load : false,
        typeArr:[],
        zip: '',
        failure_response: '',
        loader:true
    }
    componentDidMount(){
            this.getUserBasedOnRole()
    }
    handleClick=(name)=>e=>{
        this.setState({
            type : name
        },()=>{this.getUserBasedOnRole()})
    }
    
    handleAllChecked = (event) => {
        console.log("checked");
        const { value,checked } = event.target;
        console.log("checked --> " + checked);
       // processors
    }

    searchZip = (event) => {
        console.log("search clicked");
        this.setState({
            zip : this.state.zip
        },()=>{this.getUserBasedOnRole()})
    }

    handleChangeZip = (e) => {
        console.log("handlechange --> " + e.target.value);
        this.setState({zip: e.target.value});
    }

    getUserBasedOnRole=()=>{
        console.log(this.props,'pppppppppppppppppp')
        let {type,role} = this.state;
        let {
                json_token,
                product,
                product_details
            } = this.props;
           console.log("product_details -->"+ product_details); 
        let self = this;
        let payload = {
            processorType: type,
            role: role,
            product: product ? product : 0,
            product_id: 0,
            zip: this.state.zip ? this.state.zip : this.props.zip
        }
        let url = process.env.REACT_APP_BASE_URL;
        let completeUrl = `${url}profile/getrole`;
        axios.defaults.headers.common['Authorization'] = "Bearer " + json_token;
        axios.post(completeUrl,payload).then(response=>{
            console.log("response --> " + JSON.stringify(response));
            if(response.data.status == "yes") {
                console.log("status yes");
                let {data} = response;
                let {data:processors} = data;
                self.setState({
                    sellers : processors,
                    loader:false
                })
            } else if(response.data.status == "no"){
               // alert(response.data.message);
                self.setState({
                    failure_response : "No seller found around your location.",
                    loader:false
                })
            } else if(response.data.status == "No") {
                self.setState({
                    failure_response : "Please add zipcode on your profile.",
                    loader:false
                })
            }
            
        }).catch(error=>{
         console.log(error)   
        })
    }

   

    render(){
        let img_url = process.env.REACT_APP_BASE_URL;
        let url = process.env.REACT_APP_BASE_URL;
        let {
            showModal,
            closeModal,
            product,
            fallback,
            work_locc,
            checkToggle,
            saveButtonToggle,
            saveThisProcessor,
            checkedname,
            savebtnstage
        } = this.props;
        let {
            type,
            sellers,
            failure_response
        } = this.state;
        
        console.log("this props   ",this.props)
        //console.log("this state :",processors);
        return(
            <>
            <div className="G-zr">
            <div className={showModal ?"modal fade show d-block": "modal fade"} id="select_proc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{"max-width": "800px"}}>
                    <div className="modal-content G-modal rounded-0">
                        <div className="modal-header modal-hdrecng">
                            <div className="modal-title text-uppercase fs-18 fw-sm">Select seller</div> 
                            <span className="fs-14 fw-sm fa fa-close " style={{"cursor": "pointer"}} data-dismiss="modal" aria-label="Close" onClick={closeModal(false,0,sellers && sellers.length ?sellers:'')}>
                            </span>
                        </div>
                        <div className="modal-body px-5 pb-5">
                        {this.state.loader?<div className="loader" style ={{width: "120px",height: "120px"}}></div>:null}
                            <div className="processor-list">
                                {sellers && sellers.length ? <>
                                    {sellers.map((process,index)=>{
                                       
                                        let filteredarr = work_locc && work_locc.filter(ele=>{
                                            if(Number(process.id) === Number(ele))
                                                return ele
                                            })
                                       return <label className="con-ner">
                                            <div className="approved-user d-flex justify-content-between">
                                                <figure className="m-0 w-10p">
                                                    <img 
                                                    src= {url+"uploads/"+`${process.avatar}`}
                                                    res = {img => this.img=img}
                                                    onError = {e => e.target.src= url+"uploads/profile_img.jpg"}
                                                    className="img-fluid" 
                                                    alt="user-img"/>
                                                </figure>
                                                <div className="w-90p">
                                                    <span className="d-flex processor-hd justify-content-between">
                                                        <h4 className="fs-15 fw-m ">{process.first_name} {process.last_name}
                                                        </h4>

                                                        <h6 className="txt-gray montserrat fs-13 fw-r d-flex align-items-center">
                                                            <embed src="/images/icon/distance.svg" width="55" height="20" type="image/svg+xml" />
                                        {parseFloat(process.distance).toFixed(2)} miles</h6>
                                                    </span>
                                    <p className="txt-gray montserrat fs-13 fw-r"> 
                                    {process.city}, {process.state}, {process.country}, {process.zip}
                                    </p>
                                                </div>
                                            </div>
                                         
                                            {checkToggle ? <>
                                                <input 
                                                type="checkbox" 
                                                className="pl-ip"
                                                name="processor_check"
                                                value={process.id} 
                                                onChange={fallback(process.id, process.first_name, process.last_name)}
                                                checked = {filteredarr && filteredarr.length > 0  ? true : false}
                                                />
                                                <span className="checkmark"></span>
                                                </>
                                             :   ''
                                             }
                                        </label>
                                    })}
                                </>:<p>{failure_response}</p>}
                            </div>
                            {this.props.noprocessor? <span style={{ color: "red" }}>you have to select one seller and save </span>:null}
                            
                            {saveButtonToggle ? <div className="text-center pl-bt">
                                <button className="btn btn-green" style={{"font-size": "16px"}} onClick={closeModal(false,0)}>Close</button>
                            </div> : ''}
                            {saveThisProcessor ? <div className="text-center pl-bt">
                                <button className="btn btn-green" style={{"font-size": "16px"}} onClick={closeModal(false,1,sellers && sellers.length ?sellers:'')} disabled={savebtnstage?false:true}>Save</button>
                            </div> : ''}
    
                        </div>
                        
                    </div>
                </div>
            </div>
            </div>
            </>
        )
    }
}
export default SellerPopup;