import React from 'react'
import "../../views/Dashboard/Dashboard.css"
import {connect} from 'react-redux';
import "../AddEventsPopup/Add-Event.styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import Dialog from "@material-ui/core/Dialog";


 class DialogPopup extends React.Component{
     constructor(props){
         super(props)
         this.state={
            formError:{},
         }
     }

    componentDidMount(){
        //let self = this;
        // console.log("componetn deis mount");
        // console.log("token @ submit button in componen ---",this.props);
        //console.log("props befor set state -----",this.props.needarg.acount)
    }

    render(){
        let {
            open,
            title,
            subtitle,
            maxWidth,
            children,
            icon
        } = this.props;

        if (!icon) {
            icon = <img src={process.env.REACT_APP_URL + "images/check.svg"} width="30" height="30"/>;
        }

        let content = children;
        // console.log("render prop s on reason popou --",this.props)
        return(
            <>
               
                {open && 
                <Dialog
                    open={open}
                    onClose={this.props.onClose()}
                    disableEnforceFocus
                    maxWidth={maxWidth}
                >
                    <div className="modal-content trck-lfpop itmDtlPopup">
                        <div className="">
                            <div></div>
                            <div className="item-detail-x">
                                <a className="item-detail-x-item" onClick={this.props.onClose()}>
                                <FontAwesomeIcon className="p-1" style={{color:'#78909C'}} icon={faX} />
                                </a>
                            </div>
                      
                        </div>
                        <div className="modal-body dialog-popup-body d-flex justify-content-center align-items-start itmDetailsWrpr">
                            
                            <div className="popup-header-icon">
                                {icon}
                            </div>
                            
                            <div className="form-row item-detail">
                                <div className='popup-header-wrap'>
                                    <h1 className="popup-title">{title}</h1>
                                    <span>{subtitle}</span>
                                </div>
                                {content}
                            </div>
                        </div>
                    </div>
                </Dialog>}
            </>
        )
    }
 }
 const mapStateToProps = (state)=>{
    const {login:{loggedIn},login:{user}} = state;
    return {loggedIn,user};
  }
 export default  connect(mapStateToProps)(DialogPopup);